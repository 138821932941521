import en from './en';
import de from './de';
import bg from './bg';
import fr from './fr';
import ro from './ro';
import pl from './pl';
import ru from './ru';
import es from './es';
import lv from './lv';
import it from './it';
import ua from './ua';
import bs from './bs';
import sr from './sr';
import gr from './gr';
import cs from './cs';
import sk from './sk';
import tr from './tr';
import keys from "./keys";
//import lang from './lang';
import DbTasks from '../../db/DbTasks';

export const updateDictList = async (la) => {

    Object.keys(la).forEach((key, val) => {
        dictionaryList[key] = la[key];
        //lang[key]= la[key];
        if(!languageOptions.some(e => e.id == key)) 
            languageOptions.push({id: key, text: ""});
        //console.log(key, lang[key]);
      })
    //console.log(dictionaryList);

    
}

export const dictionaryList = {
    de,
    en,
    bg,
    fr,
    ro,
    pl,
    ru,
    es,
    lv,
    it,
    ua,
    bs,
    sr,
    gr,
    cs,
    sk,
    tr,
    keys
};


export const languageOptions = [
    { id: 'de', text: 'Deutsch' },
    { id: 'en', text: 'English' },
    { id: 'bg', text: 'Bulgarian'},
    { id: 'fr', text: 'French'},
    { id: 'ro', text: 'Romanian'},
    { id: 'pl', text: 'Polish'},
    { id: 'ru', text: 'Russian'},
    { id: 'es', text: 'Spanish'},
    { id: 'lv', text: 'Lithuanian'},
    { id: 'it', text: 'Italian'},
    { id: 'ua', text: 'Ukrainian'},
    { id: 'bs', text: 'Bosnian'},
    { id: 'sr', text: 'Serbian'},
    { id: 'gr', text: 'Greek'},
    { id: 'cs', text: 'Czech'},
    { id: 'sk', text: 'Slovak'},
    { id: 'tr', text: 'Turkish'},
    { id: 'keys', text:'Keys'},
];