const es = {
    "1": "Sí",
    "2": "No",
    "3": "Versión de prueba de 30 días",
    "4": "Se ha notificado una queja a través de la aplicación",
    "5": "Aceptación",
    "6": "Fecha de aceptación",
    "7": "Acción",
    "8": "Activo",
    "9": "Actividades",
    "10": "Añadir",
    "11": "Añadir instalador",
    "12": "Añadir empresa",
    "13": "Añadir distribuidor/instalador",
    "14": "Añadir fabricante",
    "15": "Añadir gestor de la propiedad",
    "16": "Añadir usuario",
    "17": "Campo adicional",
    "18": "Edición del nombre del campo adicional",
    "19": "Comentarios",
    "20": "Información adicional",
    "21": "Productos adicionales",
    "22": "Sustituir herraje",
    "23": "Admin",
    "24": "Vista administrativa",
    "25": "¿Se han entregado todos los elementos en el lugar?",
    "26": "Todas las posiciones están instaladas",
    "27": "Todos los derechos reservados.",
    "28": "¿Está seguro de que quiere eliminar este fabricante?",
    "29": "¿Estás seguro de que quieres eliminar este usuario?",
    "30": "Instalado",
    "31": "Instalador",
    "32": "Instaladores",
    "33": "Montaje",
    "34": "Instalación completada",
    "35": "Fecha de instalación",
    "36": "Asignaciones",
    "37": "Instrucciones de mantenimiento",
    "38": "Detalles del certificado",
    "39": "Certificado(s)",
    "40": "Limpieza",
    "41": "Borrar",
    "42": "Cerrar",
    "43": "Color",
    "44": "Color/acabado",
    "45": "Comisión",
    "46": "Empresa",
    "47": "Datos de la empresa",
    "48": "Información de la empresa",
    "49": "Dirección de la empresa",
    "50": "Reclamaciones",
    "51": "Proyecto completado",
    "52": "Completado",
    "53": "Ventana conectada",
    "54": "Obra limpia",
    "55": "Fecha de creación",
    "56": "Usuario final",
    "57": "Número de cliente",
    "58": "Referencia del elemento",
    "59": "Fecha",
    "60": "Rango de fechas",
    "61": "Distribuidor/Instalador",
    "62": "Borrar empresa",
    "63": "Borrar fabricante",
    "64": "Borrar usuario",
    "65": "Fecha de entrega",
    "66": "Entregado sin daños",
    "67": "Descripción",
    "68": "Lugar de instalación designado",
    "69": "Mostrar _MENU_ registros por página",
    "70": "Distribución",
    "71": "Documentos",
    "72": "Este",
    "73": "Editar el instalador",
    "74": "Editar empresa",
    "75": "Editar distribuidor",
    "76": "Editar gestor de la propiedad",
    "77": "Editar usuario",
    "78": "Elemento ancho x alto",
    "79": "El elemento con este id no existe",
    "80": "Elementos",
    "81": "Datos de correo electrónico guardados.",
    "82": "Introduzca el color",
    "83": "Introduzca la comisión",
    "84": "Introduzca el nombre de la empresa",
    "85": "Introduzca el correo electrónico",
    "86": "Introduzca la extensión",
    "87": "Introduzca la altura",
    "88": "Introduzca la dirección IP",
    "89": "Introduzca el nombre",
    "90": "Introduzca el número",
    "91": "Introduzca el número de posiciones",
    "92": "Introduzca la contraseña",
    "93": "Introduzca el teléfono",
    "94": "Introduzca el número de teléfono",
    "95": "Introduzca el municipio",
    "96": "Introduzca la cantidad",
    "97": "Introduzca la calle",
    "98": "Introduzca el apellido",
    "99": "Introduzca el nombre de usuario",
    "100": "Introducir ID de la cuenta",
    "101": "Introduzca la página web",
    "102": "Introduzca la anchura",
    "103": "Error al guardar los datos en el servidor.",
    "104": "Error al guardar los datos del correo electrónico.",
    "105": "Reparar accesorios",
    "106": "expiró en",
    "107": "expira a las",
    "108": "Extensión",
    "109": "Fabricante",
    "110": "Fábrica",
    "111": "Filtro",
    "112": "filtrado a partir de _MAX_ registros totales",
    "113": "Primero",
    "114": "Cancelar",
    "115": "Información sobre el herraje",
    "116": "Mosquiteras",
    "117": "Huella exológica",
    "118": "Comentarios",
    "119": "Desde la aplicación",
    "120": "Desde la vista de gestión",
    "121": "Limpiador de cristales",
    "122": "Sustitución de cristales",
    "123": "Composición del vidrio",
    "124": "Ancho x alto del vidrio (mm)",
    "125": "Mantenimiento de los herrajes",
    "126": "Sustitución de herrajes",
    "127": "Altura",
    "128": "Cómo se limpia",
    "129": "Imagen",
    "130": "Información sobre el elemento",
    "131": "La información está restringida",
    "132": "Documentos de la posición",
    "134": "Lugar de instalación",
    "135": "Introducir Código de barras",
    "136": "Dirección IP",
    "137": "es necesaria",
    "138": "Idioma",
    "139": "Última",
    "140": "Último mantenimiento",
    "141": "Última queja",
    "142": "Último servicio",
    "143": "Enlace al artículo",
    "144": "Cargando",
    "145": "¿Todos los elementos según la especificación?",
    "146": "Cierre de sesión",
    "147": "Datos de acceso al correo electrónico",
    "148": "Mantenimiento",
    "149": "Cambiado por",
    "150": "Fabricado",
    "151": "Diseño del controlador maestro",
    "152": "Número de material del Master Controller",
    "153": "Número de serie del Master Controller",
    "154": "Fecha de escaneado",
    "155": "Visibilidad del menú",
    "156": "Autoconfirmar código de barras",
    "157": "Documentos de la página web",
    "158": "Nuevo periodo de pruebas",
    "159": "Siguiente",
    "160": "No hay daños en el elemento",
    "161": "No hay daños en el cristal",
    "162": "No se han encontrado registros coincidentes",
    "163": "No se ha instalado ningún elemento",
    "164": "Norte",
    "165": "No está montado",
    "166": "No se ha encontrado nada - lo siento",
    "167": "Número",
    "168": "Número de elementos",
    "169": "Resumen del proyecto",
    "170": "Número de posiciones",
    "171": "en",
    "172": "Fecha de pedido",
    "173": "Número de pedido",
    "174": "Progreso del pedido",
    "175": "Salida",
    "176": "Contraseña",
    "177": "Teléfono",
    "178": "Municipio",
    "179": "Limpiador de PVC",
    "180": "Elementos Documento",
    "181": "Detalles de la posición del elemento",
    "182": "La posición con este id no existe",
    "183": "Posiciones",
    "184": "Anteriormente",
    "185": "Credenciales del usuario principal",
    "186": "Imprimir datos de acceso",
    "187": "Procesando",
    "188": "Fabricante",
    "189": "Sistema de perfiles",
    "190": "Proyecto",
    "191": "Número de proyecto",
    "192": "Gestor de la propiedad",
    "193": "Cantidad",
    "194": "Reclamación",
    "195": "Por favor seleccione la estación",
    "196": "Contenido reciclado",
    "197": "Reparación",
    "198": "Accesorios de reparación",
    "199": "Reparación de la superficie",
    "200": "Reportado desde la App",
    "201": "Informado desde la vista de gestión",
    "202": "Restablecer período de prueba",
    "203": "Resultado",
    "204": "Rol",
    "205": "Guardar",
    "206": "Guardado con éxito.",
    "207": "Limpiar la superficie",
    "208": "Buscar en",
    "209": "Nivel de seguridad",
    "210": "Enviar datos a REHAU",
    "211": "Servicio",
    "212": "Servicio y ventas",
    "213": "Proveedor de servicios",
    "214": "Ajustes",
    "215": "Seleccionar la aceptación manual de los pedidos",
    "216": "Mostrar de _INICIO_ a _FIN_ de _TOTAL_ registros",
    "217": "Mostrando de 0 a 0 de 0 registros",
    "218": "Persianas",
    "219": "Registro",
    "220": "Regístrese para iniciar su sesión",
    "221": "Información sobre Smart Glass",
    "222": "Ventana inteligente",
    "223": "Algunos elementos están instalados",
    "224": "Algunos elementos no están emparejados",
    "225": "Alguna(s) posición(es) necesita(n) ser instalada(s)",
    "226": "Sur",
    "227": "Estadística",
    "228": "Calle",
    "229": "Apellido",
    "230": "Sostenibilidad y Energía",
    "231": "Teléfono",
    "232": "Período de prueba restablecido con éxito",
    "233": "periodo(s) de prueba",
    "234": "Reparación completada",
    "235": "Hay quejas existentes",
    "236": "No hay ninguna empresa con este id",
    "237": "No hay información sobre este elemento",
    "238": "Esta empresa ya existe",
    "239": "Título",
    "240": "a",
    "241": "Aceptación total",
    "242": "Total",
    "243": "Desconocido",
    "244": "Subir el logo",
    "245": "Administración de usuarios",
    "246": "Nombre de usuario",
    "247": "El nombre de usuario ya está ocupado.",
    "248": "Usuarios",
    "249": "Valor U",
    "250": "Valor U (marco)",
    "251": "Valor U (vidrio)",
    "252": "W.ID asignado",
    "253": "Advertencia de no dañar el Window ID",
    "254": "Bienvenido",
    "255": "Oeste",
    "256": "Anchura",
    "257": "Tamaño del elemento (mm)",
    "258": "Área de la ventana desde",
    "259": "Área de la ventana en",
    "260": "Ventana Número de material electrónico",
    "261": "Ventana Número de serie electrónico",
    "262": "Lugar de instalación de la ventana",
    "263": "Sistema de la ventana",
    "264": "Tipo de ventana",
    "265": "Sustitución de la hoja",
    "266": "¿Estás seguro?",
    "267": "Limpiar el filtro",
    "268": "Editar",
    "269": "Exportar CSV",
    "270": "Leer el código de barras",
    "271": "Identificación del vidrio (ID)",
    "272": "Información",
    "273": "Enlace",
    "274": "Usuarios vinculados",
    "275": "Correo electrónico",
    "276": "Configuración del correo electrónico",
    "277": "Período de prueba del correo electrónico",
    "278": "Título de servicio",
    "279": "Empresa de servicios",
    "280": "Teléfono del servicio",
    "281": "Correo electrónico del servicio",
    "282": "Estado",
    "283": "Ajustes de la estación",
    "284": "Asunto",
    "285": "Tel.",
    "286": "Usuario",
    "287": "Página web",
    "288": "Identificador Window.ID",
    "289": "Sistema de ventana",
    "290": "Datos del usuario final",
    "291": "Nombre",
    "292": "Tipo de vidrio",
    "293": "Detalles del herraje",
    "294": "Título del instalador",
    "295": "Empresa instaladora",
    "296": "Teléfono del instalador",
    "297": "Correo electrónico del instalador",
    "298": "URL",
    "299": "País",
    "300": "Por favor, añada un usuario para esta empresa",
    "301": "Menú adicional",
    "302": "Panel de control",
    "303": "Aplicación",
    "304": "Diseño de la página web",
    "305": "Gestión de usuarios",
    "306": "Confirmar",
    "307": "Cuerpo",
    "308": "Anexo",
    "309": "Id",
    "310": "Posición",
    "311": "Advertencia",
    "312": "W.ID",
    "314": "Distribuidores",
    "315": "Activar",
    "316": "Desactivar",
    "317": "empresa",
    "318": "Lo sentimos",
    "319": "Administrador",
    "320": "usuario",
    "321": "Código postal",
    "322": "Logotipo",
    "323": "Fondo de pantalla",
    "324": "ID de la cuenta",
    "325": "Documentos CE",
    "326": "Ajustes de la aplicación",
    "327": "Automático",
    "328": "Manual",
    "329": "Navegar",
    "330": "Cargar",
    "331": "Carga automática de documentos",
    "332": "Carpeta de origen",
    "333": "Elija la ruta de la carpeta para cargar automáticamente los documentos",
    "334": "Seleccionar archivo.zip que incluye archivo PPS XLM",
    "335": "Estación",
    "336": "Escaneado manual",
    "337": "Estación de vidrio inteligente",
    "338": "Información de la transferencia",
    "339": "Número máximo de cintas en línea de tiempo",
    "340": "Código QR desde",
    "341": "Código QR hasta",
    "342": "Código QR prefijado",
    "343": "Longitud código QR",
    "344": "Datos del código de barras no válidos",
    "345": "Api Key",
    "346": "Introducir código QR",
    "347": "Nombre de la estación",
    "348": "Archivo no seleccionado",
    "349": "Archivo cargado",
    "350": "Error de carga",
    "351": "Escaneado manual activado",
    "352": "Por favor introduzca el código QR",
    "353": "Código de barras no válido",
    "354": "Código de barras importado",
    "356": "Sin datos",
    "357": "Fallo de conexión",
    "358": "Historial",
    "359": "Código de barras en uso",
    "360": "Error al cotejar el código de barras",
    "361": "Manual",
    "362": "Desde",
    "363": "Hasta",
    "364": "La longitud prefijada del código QR debe ser inferior a la longitud del código QR",
    "365": "Rango del código de barras no válido",
    "366": "Números no válidos",
    "367": "Usado previamente",
    "368": "Usado hoy",
    "369": "Libre",
    "370": "Usado hasta hoy",
    "371": "Utilización del número QR-Code para el rango de estaciones actual",
    "372": "Traducciones",
    "373": "Editar traducción",
    "374": "Llave",
    "375": "Inglés",
    "376": "Valor",
    "377": "Asignar W.ID",
    "378": "Posiciones de las ventanas y puertas en el proyecto",
    "379": "Servicio de reparación / profundidad de la reparación",
    "380": "Mantenimiento e Historial",
    "381": "Informe de la instalación",
    "382": "Modificación",
    "383": "Sustituir el vidrio",
    "384": "Sustituir la hoja",
    "386": "Comentarios",
    "387": "Reparación finalizada",
    "388": "Nuevas funciones",
    "389": "Registro de mantenimiento",
    "390": "Mantenimiento del herraje",
    "391": "Fijar una cita para el mantenimiento",
    "392": "Lista completa de proyectos",
    "393": "Información de la ventana",
    "394": "Detalles de la instalación",
    "395": "Color",
    "396": "Anchura x altura del elemento en mm",
    "397": "Anchura y altura de vidrio en mm",
    "398": "Información de los accesorios",
    "399": "Nombre del instalador",
    "400": "Fecha / tiempo de instalación",
    "401": "Lugar de la instalación (GPS)",
    "402": "ID del dispositivo",
    "403": "enviar",
    "404": "Posición del filtro",
    "405": "Ninguno",
    "406": "Puertas balconeras",
    "407": "Cocina",
    "408": "Baño",
    "409": "Habitación",
    "410": "Tejado",
    "411": "Instalación",
    "412": "Posición",
    "413": "Anexo de imagen",
    "414": "Firma",
    "415": "Dibujos",
    "416": "Ventana sin daños",
    "417": "Vidrio sin daños",
    "418": "Abrir la cámara",
    "419": "Cargar",
    "420": "Instalar & asignar ventana",
    "421": "Aceptar el informe",
    "422": "Enviar",
    "423": "Proyecto de construcción completo",
    "424": "Tipo de instalación",
    "425": "Explícito para el elemento",
    "426": "Trabajo completado",
    "427": "Informe de instalación por elemento individual",
    "428": "Problema existente",
    "429": "Se requiere ayuda",
    "430": "Mensaje",
    "431": "Enviar",
    "432": "Próximo elemento",
    "433": "Lista de todos los elementos",
    "434": "Agenda de construcción",
    "435": "a través del número de pedido",
    "436": "a través des escanero del código QR",
    "437": "Visualización individual",
    "438": "o",
    "439": "Número del código de barras",
    "440": "Mostrar información",
    "441": "Reparación/reclamación",
    "442": "Mostrar la persona de contacto",
    "443": "Comentarios",
    "444": "Escanear otro código QR",
    "445": "Próximo mantenimiento",
    "446": "Suministrador de la ventana",
    "447": "Características",
    "448": "Valor de aislamiento acústico",
    "449": "Uf",
    "450": "Soluciones de adaptación",
    "451": "Contacto",
    "452": "Su ID de la ventana",
    "453": "¿Qué le gustaría hacer?",
    "454": "El mantenimiento regular de las ventanas/puertas prolonga la vida útil y el confort",
    "455": "Solicitar cita de mantenimiento a través del formulario de contacto",
    "456": "Solicitar cita de mantenimiento por teléfono",
    "457": "con su instalador de ventanas",
    "458": "Confirmo los términos y condiciones y el reglamento GDPRn",
    "459": "Su inquietud",
    "461": "Contactar vía ...",
    "462": "Vidrio roto",
    "463": "La ventana no cierra correctamente",
    "464": "La ventana está atascada",
    "465": "Ventana dañada",
    "466": "Otros accesorios",
    "467": "Sus ventanas y puertas están",
    "468": "montades por esta cempresa",
    "469": "Fabricante de ventanas",
    "470": "Evalúe ahora sus ventanas y su instalación",
    "471": "Cómo está de satisfecho con el servicio de la compañía",
    "472": "¿Son correctos los productos suministrados ?",
    "473": "¿Qué le parecen sus ventanas?",
    "474": "¿Ha tenido algún problema durante el proceso?",
    "475": "¿Cómo está de satisfecho?",
    "476": "¿Qué mejoraría?",
    "477": "Instrucciones de montaje",
    "478": "Posiciones de las ventanas y puertas",
    "479": "Abrir el informe de instalación",
    "480": "Completar la lista de elementos del proyecto",
    "481": "Lista de elementos vií número de pedido / Código QR",
    "482": "Enviar registro del proyecto",
    "483": "Detalles de la ventana",
    "484": "Detalles de la ventana",
    "485": "Número del proyecto de construcción",
    "486": "Ventana",
    "487": "Posición de la ventana",
    "488": "Servicio de reparación",
    "489": "Detalles del mantenimiento",
    "490": "Cliente",
    "491": "Formulario de contacto",
    "492": "Encuentra un instalador REHAU cerca de ti",
    "493": "Categoría",
    "494": "Escanear código QR",
    "495": "Persona de contacto",
    "496": "actualización",
    "497": "filtros",
    "498": "Instalación completa",
    "499": "¿instalación completada?",
    "500": "Suministrado sin daños",
    "501": "Salvar como PDF",
    "502": "Hacer una fotografía",
    "503": "Dirección de correo electrónico no válida",
    "504": "Instalar App",
    "505": "Lugar",
    "506": "Lista de proyecto",
    "507": "¿Cargar el pedido seleccionado?",
    "508": "Número de pedido/código de barras incorrecto",
    "509": "Ventana individual",
    "510": "Introducir cambios",
    "511": "Enviar el informe por correo electrónico",
    "512": "Por favor elija 4 opciones",
    "513": "Ahora escanee el código QR para averiguar dónde debe instalarse el elemento",
    "514": "Grabar archivo",
    "515": "Mostrar",
    "516": "resultados",
    "517": "página",
    "518": "de",
    "519": "Fecha desde",
    "520": "Fecha hasta",
    "521": "abrir",
    "522": "Tamaño",
    "523": "Instalado en",
    "524": "Con éxito",
    "525": "Información",
    "526": "Nuevo pedido cargado",
    "527": "ID del instalador",
    "528": "Iniciar sesión",
    "529": "Confirmo las condiciones generales y el reglamento GDPR",
    "530": "Finalizar sesión",
    "531": "escaneado",
    "532": "Validar la instalación",
    "533": "¿Debe enviarse al panel de control el informe de aceptación de todos los artículos de este pedido?",
    "534": "Registro de reparaciones",
    "535": "opción 5",
    "536": "opción 6",
    "537": "opción 7",
    "538": "opción 8",
    "539": "opción 9",
    "540": "opción 10",
    "541": "Correo electrónico enviado",
    "542": "¡Error mientas se enviaba el correo electrónico!",
    "543": "Mantenimiento del equipo",
    "544": "Ajustado",
    "545": "Limpiado",
    "546": "Sellado",
    "547": "Herraje sustituído",
    "548": "Comentarios",
    "549": "Todos los elementos se han suministrado al lugar de instalación",
    "550": "Ubicación de los elementos dentro del proyecto de construcción",
    "551": "¡Error al enviar el informe!",
    "552": "Grabar el informe",
    "553": "Información grabada",
    "554": "Error al grabar los datos",
    "555": "Información sobre Smart window",
    "556": "Aceptación de Smart window",
    "557": "Número de material electrónico de la ventana",
    "558": "Número de serie electrónico de la ventana",
    "559": "Escanee el código QR (DMC) del controlador maestro o introdúzcalo manualmente",
    "560": "Smart Window totalmente configurada y funcional",
    "561": "Tosos los elementos visibles sin daños / defectos",
    "562": "¿Se ha conectado un sistema de conmutación a 0-10V (0-100%)?",
    "563": "Lugar de la instalación",
    "564": "Iniciar escaneado",
    "565": "Detener escaneado",
    "566": "Elemento seleccionado",
    "567": "Sólo 4 atributos pueden estar seleccionados a la vez.",
    "568": "Posición del Customer",
    "569": "Fecha de producción",
    "570": "Cambiar el Window.ID",
    "571": "Window.ID previo",
    "572": "Nuevo Window.ID",
    "573": "El número de pedido no existe, o el número nuevo ya está en uso.",
    "574": "Window.ID",
    "575": "Posición ID",
    "576": "Tipo",
    "577": "ID del elemento",
    "578": "Información del elemento",
    "579": "Window.ID",
    "580": "Firme en el espacio previsto a tal efecto",
    "581": "Finalizado",
    "582": "Firmar",
    "583": "Nuevo idioma",
    "584": "Idioma original",
    "585": "Nombre del idioma",
    "586": "Crear un nuevo idioma",
    "587": "Añadir idioma",
    "588": "Actualización",
    "589": "actualización en progreso",
    "590": "Desactivar empresa",
    "591": "Activar empresa",
    "592": "¿Está usted seguro de borrar esta empresa?",
    "593": "¿Está usted seguro de activar esta empresa?",
    "594": "Desactivar usuario",
    "595": "Activar usuario",
    "596": "¿Está seguro de desactivar este usuario?",
    "597": "¿Está seguro de activar este usuario?",
    "598": "Posición ID",
    "599": "Todos",
    "600": "Importar/Exportar",
    "601": "Seleccionar lenguaje .csv para importar el archivo",
    "602": "Importar",
    "603": "Exportar",
    "604": "Archivo",
    "605": "Traductor",
    "606": "Código de barras",
    "607": "Seleccionar ruta del directorio para el código de barras",
    "608": "Window_ID",
    "609": "Sistema de perfiles",
    "610": "Posición del cliente",
    "611": "Color",
    "612": "Tamaño del elemento",
    "613": "Tamaño del vidrio",
    "614": "Composición del vidrio",
    "615": "Información del herraje",
    "616": "Fecha de escaneado",
    "617": "Fecha de instalación/tiempo",
    "618": "Datos GPS del lugar de instalación",
    "619": "Nº",
    "620": "Montaje:",
    "621": "Planta:",
    "622": "Pos:",
    "623": "Ignorar carateristicas iniciales",
    "624": "Ignorar caraterísticas finales",
    "625": "Email - Usuario activado",
    "626": "Activar empresas",
    "627": "El período de prueba ha expirado",
    "628": "Volver a enviar el correo electrónico de activación",
    "629": "Distribuidor/fabricante ya existe",
    "630": "El usuario está activado",
    "631": "Ir a la página de acceso",
    "632": "Detalles del usuario",
    "633": "Añadir enlace al usuario",
    "634": "Borrar el enlace del usuario",
    "635": "El usuario ya está enlazado",
    "636": "Usuario o contraseña vacío",
    "637": "Usuario no encontrado",
    "638": "Enviar el informe de la instalación",
    "639": "Encuesta de montaje completada",
    "640": "Opción 5",
    "641": "Opción 6",
    "642": "Opción 7",
    "643": "Opción 8",
    "644": "Opciónn 9",
    "645": "Opción 10",
    "646": "Instalador",
    "647": "Tipo",
    "648": "Fecha",
    "649": "Descripción",
    "650": "Mantenimiento del herraje",
    "651": "Establecer",
    "652": "Limpiar",
    "653": "Junta",
    "654": "Sustituir accesorios",
    "655": "Comentario",
    "656": "Conexión",
    "657": "Sistema de ventana",
    "658": "Número de piezas",
    "659": "Número de proyecto",
    "660": "Número de pedido",
    "661": "altura",
    "662": "anchura",
    "663": "Información del herraje",
    "664": "Anchura x altura del vidrio",
    "665": "Tipo de vidrio",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "ID del visrio",
    "669": "Condiciones",
    "670": "Añadir/Borrar",
    "671": "y/o",
    "672": "Grupo",
    "673": "Columna",
    "674": "Condición",
    "675": "Introducir el número de cliente",
    "676": "Traductor",
    "678": "Versión de la aplicación de control",
    "679": "La actualización puede tardar unos minutos",
    "680": "Awaiting new version",
    "681": "Hay una nueva versión de la aplicación, ¿Está seguro de que quiere actualizarla?",
    "682": "La aplicación está actualizada, ¿desea restaurar la versión antigua?",
    "683": "Restaurar la versión antigua",
    "684": "Insete el número de la estación",
    "685": "Número de pedido Char completado",
    "686": "Por favor, introduzca el número de pedido Char",
    "687": "Longitud max número de pedido",
    "688": "Por favor, introduzca la longitud max del número de pedido",
    "689": "Ruta de acceso a la carpeta MDB",
    "690": "Insertar la ruta del directorio .mdb (para importar el escaneado de archivos .mdb)",
    "691": "Ruta de acceso a la carpeta XML",
    "692": "Insertar la ruta del directorio .xlm (para importar el escaneado de archivos .xlm)",
    "693": "Ignorar carácter(es) inicial(es) del código QR",
    "694": "Ignorar carácter(es) final(es) del código QR",
    "695": "Designación",
    "696": "Longitud",
    "697": "Posición adicional",
    "698": "Smart window sin daños",
    "699": "Smart window conectada",
    "700": "Orientación de la ventana",
    "701": "Posición del número de elemento",
    "702": "Smart window configurada",
    "703": "Elemento sin daños",
    "704": "Número de material",
    "705": "Número de seria",
    "706": "Imagen no disponible",
    "707": "Lugar de construcción limpio",
    "708": "Arrastrar y soltar aquí  archivos de imgen/pdf",
    "709": "Carga de archivos",
    "710": "Tipo archivo",
    "711": "Pedido (todas las posiciones)",
    "712": "Boorrado",
    "713": "Notas de la versión",
    "714": "Altura(mm)",
    "715": "Anchura (mm)",
    "716": "No hay panel para esta posición",
    "717": "Artículo",
    "718": "Estación de carga",
    "719": "Rango de carga",
    "720": "Último Window ID",
    "721": "El vidrio fue sustituido",
    "722": "Los accesorios fueron reparados",
    "723": "La hoja fue reemplazada",
    "724": "La superficie fue reparada",
    "725": "Los accesorios fueron reparados",
    "726": "La reparación está completada",
    "727": "Confirmar contraseña",
    "728": "Contraseña errónea",
    "729": "La contraseña no coincide",
    "730": "La contraseña no puede estar vacía",
    "731": "Localización",
    "732": "Cronología",
    "733": "Valor UW",
    "734": "Vidrio especial",
    "735": "Sin posición",
    "736": "Producción en progreso",
    "737": "Producción completada",
    "738": "Intalación en progreso",
    "739": "Instalación completada",
    "740": "Estación borrada",
    "741": "¿Está usted seguro de borrar esta estación?",
    "742": "Añadir una nueva estación",
    "743": "Borrar pedido",
    "744": "Borrado de pedido",
    "745": "¿Está usted seguro de borrar el pedido?",
    "746": "Usuario añadido",
    "747": "Usuario editado",
    "748": "¿Olvidó su contraseña?",
    "749": "Se requiere el campo contraseña",
    "750": "Se requiere el campo de nombre de usuario",
    "751": "Este rol de usuario no está autorizado para activarlo en el panel de control",
    "752": "El usuario no está activo",
    "753": "La empresa usuaria no está activa o el periodo de prueba ha finalizado",
    "754": "Usuario o contraseña incorrecto",
    "755": "Código Postal",
    "756": "Utilice la rueda de desplazamiento para acercar o alejar la imagen",
    "757": "Restablecer contraseña",
    "758": "Se ha enviado un correo electrónico para restablecer la contraseña",
    "759": "Se ha restablecido la contraseña",
    "760": "Correo electrónico - Restablecer contraseña",
    "761": "Número de elemento",
    "762": "Número de pedido de",
    "763": "Introduzca el número de pedido desde",
    "764": "Longitud del número de pedido",
    "765": "Introduzca la longitud del número de pedido",
    "766": "Rango de pedido desde",
    "767": "Por favor, introduzca el rango de pedido desde",
    "768": "Longitud del pedido",
    "769": "Por favor, introduzca la longitud del pedido",
    "770": "Please insert order piece from",
    "772": "Longitud de la pieza de pedido",
    "773": "Por favor, introduzca la longitud de la pieza",
    "774": "Número de posición desde",
    "775": "Por favor, introduzca el número de posición desde",
    "776": "Longitud del número de posición",
    "777": "Por favor, introduzca la longitud del número de posición",
    "778": "Posición pieza desde",
    "779": "Por favor, introduzca la posición de la pieza",
    "780": "Longitud de la pieza de la posición",
    "781": "Introduzca la longitud de la pieza de la posición",
    "782": "Nivel de seguridad",
    "783": "Carácter de relleno del código de barras",
    "784": "Introduzca el carácter de relleno del código de barras",
    "785": "Volver a proyectos",
    "786": "(solo lectura)",
    "787": "Acceso limitado",
    "788": "Acceso de solo lectura",
    "789": "Página web propia",
    "790": "Introduzca la cadena de conexión",
    "791": "Cadena de conexión DB",
    "792": "Cadena de conexión DB STD",
    "793": "Cadena de conexión DB BWG",
    "794": "Conexión a base de datos",
    "795": "Chat",
    "796": "Mensajes",
    "797": "Documento CE",
    "798": "Documento de pedido",
    "799": "Última reparación",
    "800": "Aceptación completada",
    "801": "Nueva actualización disponible, por favor reinicie la aplicación",
    "802": "Enlace externo",
    "803": "Prefijo enlace externo",
    "804": "Referencia del proyecto",
    "805": "Intervalo de tareas",
    "806": "Usuarios del panel de control",
    "807": "Usuarios de la aplicación",
    "808": "Última carga",
    "809": "minutos",
    "810": "Versión emparejada (Matcher)",
    "811": "Versión del importador",
    "812": "Usuario desconectado",
    "813": "Usuario desconectado correctamente",
    "814": "Código de barras modificado",
    "815": "Información actualizada",
    "816": "Direcciones",
    "817": "Instalar APP",
    "818": "Instale la APP Window.ID escaneando el código QR",
    "819": "emparejado",
    "820": "no emparejado",
    "821": "Planificador",
    "822": "Número de días",
    "823": "Ajustes para el recordatorio de mantenimiento.",
    "824": "días",
    "825": "Próximo mantenimiento",
    "826": "Editar imagen",
    "827": "Sin filtro",
    "828": "Añadir pedido",
    "829": "Nuevo pedido",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Cambiar de cámara",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
};

export default es;