const it = {
    "1": "Sì",
    "2": "No",
    "3": "Versione di prova di 30 giorni",
    "4": "È stato segnalato un reclamo tramite l'app",
    "5": "Accettazione",
    "6": "Data di accettazione",
    "7": "Azione",
    "8": "Attivo",
    "9": "Attività",
    "10": "Aggiungi",
    "11": "Aggiungere posatore",
    "12": "Aggiungi azienda",
    "13": "Aggiungi rivenditore",
    "14": "Aggiungi fabbricatore di serramenti",
    "15": "Aggiungere la gestione della proprietà",
    "16": "Aggiungi utente",
    "17": "Campo aggiuntivo",
    "18": "Modifica del nome del campo aggiuntivo",
    "19": "Informazioni aggiuntive",
    "20": "Informazioni aggiuntive",
    "21": "Prodotti aggiuntivi",
    "22": "Regolazione",
    "23": "Amministrazione",
    "24": "Visualizzazione amministrativa",
    "25": "Tutti gli elementi sono stati consegnati in cantiere?",
    "26": "Tutte le posizioni sono posate",
    "27": "Tutti i diritti riservati.",
    "28": "Sei sicuro di voler eliminare questo fabbricatore di serramenti?",
    "29": "Siete sicuri di voler eliminare questo utente?",
    "30": "Posato",
    "31": "Posatore",
    "32": "Posatori",
    "33": "Montaggio",
    "34": "Posa in opera completata",
    "35": "Data di posa",
    "36": "Compiti",
    "37": "Istruzioni per la manutenzione",
    "38": "Dettagli del certificato",
    "39": "Certificato/i",
    "40": "Pulizia",
    "41": "Libero",
    "42": "Chiudere",
    "43": "Colore",
    "44": "Colore/Finitura",
    "45": "Commissione",
    "46": "Azienda",
    "47": "Dettagli sull'azienda",
    "48": "Informazioni sull'azienda",
    "49": "Direzione bussola",
    "50": "Contestazioni",
    "51": "Progetto completato",
    "52": "Completato",
    "53": "Finestra collegata",
    "54": "Cantiere pulito",
    "55": "Data di creazione",
    "56": "Utente finale",
    "57": "Numero cliente",
    "58": "Riferimento dell'elemento",
    "59": "Data",
    "60": "Intervallo di data",
    "61": "Rivenditore",
    "62": "Cancellare l'azienda",
    "63": "Elimina fabbricatore di serramenti",
    "64": "Cancellare l'utente",
    "65": "Data di consegna",
    "66": "Consegnato senza danni",
    "67": "Descrizione",
    "68": "Luogo di posa in opera designato",
    "69": "Visualizza_MENU_ registrazioni per pagina",
    "70": "Distribuzione",
    "71": "Documenti",
    "72": "Est",
    "73": "Modifica posatore",
    "74": "Modifica azienda",
    "75": "Modifica fabbricatore di serramenti",
    "76": "Modifica della gestione degli immobili",
    "77": "Modifica utente",
    "78": "Larghezza x altezza del serramento",
    "79": "Il serramento con questo id non esiste",
    "80": "Serramenti",
    "81": "Dati e-mail salvati.",
    "82": "Inserire il colore",
    "83": "Inserire commissione",
    "84": "Inserire il nome dell'azienda",
    "85": "Inserire e-mail",
    "86": "Inserire l'estensione",
    "87": "Inserire l'altezza",
    "88": "Inserire l'indirizzo IP",
    "89": "Inserire il nome",
    "90": "Inserire il numero",
    "91": "Inserire il numero di posizioni",
    "92": "Inserire la password",
    "93": "Inserire il telefono",
    "94": "Inserire il numero di telefono",
    "95": "Inserire la città",
    "96": "Inserire la quantità",
    "97": "Inserisci la via",
    "98": "Inserire il cognome",
    "99": "Inserire il nome utente",
    "100": "Inserire UStIdNr",
    "101": "Inserisci sito web",
    "102": "Inserisci larghezza",
    "103": "Errore durante il salvataggio dei dati sul server.",
    "104": "Errore durante il salvataggio dei dati dell'e-mail.",
    "105": "Sostituire l'hardware",
    "106": "scaduto il",
    "107": "scadrà il",
    "108": "Estensione",
    "109": "Fabbricatore di serramenti",
    "110": "Fabbrica",
    "111": "Filtro",
    "112": "filtrati da _MAX_ record totali",
    "113": "Primo",
    "114": "Annulla",
    "115": "Informazioni sulla ferramenta",
    "116": "Zanzariere",
    "117": "Impronta di piede",
    "118": "Commenti",
    "119": "Da App",
    "120": "Da vista gestione",
    "121": "Pulizia vetri",
    "122": "Sostituzione del vetro",
    "123": "Struttura del vetro",
    "124": "Larghezza x altezza del vetro",
    "125": "Manutenzione della ferramenta",
    "126": "Sostituzione della ferramenta",
    "127": "Altezza",
    "128": "Come pulire",
    "129": "Immagine",
    "130": "Informazioni sull'elemento",
    "131": "Le informazioni sono limitate",
    "132": "Input",
    "134": "Luogo di posa in opera",
    "135": "Inserire il codice a barre",
    "136": "Indirizzo IP",
    "137": "è richiesto",
    "138": "Lingua",
    "139": "Ultimo",
    "140": "Ultima manutenzione",
    "141": "Ultimo reclamo",
    "142": "Ultimo servizio",
    "143": "Link all'articolo",
    "144": "Caricamento",
    "145": "Tutti gli elementi sono conformi alle specifiche?",
    "146": "Disconnessione",
    "147": "Dati di accesso alle e-mail",
    "148": "Manutenzione",
    "149": "Modificato da",
    "150": "Prodotto",
    "151": "Design del controller master",
    "152": "Numero di materiale del controllore master",
    "153": "Numero di serie del controllore master",
    "154": "Data di abbinamento",
    "155": "Visibilità del menu",
    "156": "Conferma automatica del codice a barre",
    "157": "Ulteriori informazioni su REHAU Sustainability",
    "158": "Nuovo periodo di prova",
    "159": "Avanti",
    "160": "Nessun danno all'elemento",
    "161": "Nessun danno al vetro",
    "162": "Non sono stati trovati record corrispondenti",
    "163": "Nessuno degli elementi è stato posato",
    "164": "Nord",
    "165": "Non montato",
    "166": "Non è stato trovato - mi dispiace",
    "167": "Numero",
    "168": "Numero di elementi",
    "169": "Panoramica del progetto",
    "170": "Numero di posizioni",
    "171": "su",
    "172": "Data dell'ordine",
    "173": "Numero d'ordine",
    "174": "Stato dell'ordine",
    "175": "Uscita",
    "176": "Password",
    "177": "Telefono",
    "178": "Città",
    "179": "Detergente per PVC",
    "180": "Pos. Documento",
    "181": "Dettagli dell'elemento Posizione",
    "182": "La posizione con questo id non esiste",
    "183": "Posizioni",
    "184": "Precedente",
    "185": "Credenziali utente primario",
    "186": "Stampa i dati di accesso",
    "187": "Elaborazione",
    "188": "Fabbricatore di serramenti",
    "189": "Sistema di profili",
    "190": "Progetto",
    "191": "Numero del progetto",
    "192": "Gestione della proprietà",
    "193": "Quantità",
    "194": "Reclamo",
    "195": "Selezionare la stazione",
    "196": "Contenuto riciclato",
    "197": "Riparazione",
    "198": "Accessori per la riparazione",
    "199": "Superficie di riparazione",
    "200": "Segnalato da App",
    "201": "Segnalato dalla vista di gestione",
    "202": "Azzeramento del periodo di prova",
    "203": "Risultato",
    "204": "Ruolo",
    "205": "Salvare",
    "206": "Salvato con successo.",
    "207": "Sostituire le guarnizioni",
    "208": "Ricerca",
    "209": "Livello di sicurezza",
    "210": "Invio di dati a REHAU",
    "211": "Assistenza",
    "212": "Assistenza e vendita",
    "213": "Servicer",
    "214": "Impostazioni",
    "215": "Si deve inserire ora un collaudo generale manuale (9,o una manutenzione, una riparazione)?",
    "216": "Visualizzazione da _inizio_ a _fine_ dei record _totali",
    "217": "Mostra da 0 a 0 di 0 record",
    "218": "Tapparelle/persiane",
    "219": "Accedi",
    "220": "Accedi per iniziare la tua sessione",
    "221": "Informazioni su Smart Glass",
    "222": "Smart Window",
    "223": "Alcuni elementi sono posati",
    "224": "Alcuni elementi non sono abbinati",
    "225": "Alcune posizioni devono essere posate in opera",
    "226": "Sud",
    "227": "Statistica",
    "228": "Via",
    "229": "Cognome",
    "230": "Sostenibilità e dati energetici",
    "231": "Telefono",
    "232": "Periodo di prova resettato con successo",
    "233": "periodo/i di prova",
    "234": "Le proprietà sono state mantenute",
    "235": "Esistono dei reclami",
    "236": "Non c'è nessuna azienda con questo id",
    "237": "Non ci sono informazioni su questo elemento",
    "238": "Questa azienda è già stata utilizzata",
    "239": "Titolo o qualifica?",
    "240": "a",
    "241": "Accettazione totale",
    "242": "Totale",
    "243": "Sconosciuto",
    "244": "Carica il logo",
    "245": "Amministrazione utente",
    "246": "Nome utente",
    "247": "Nome utente già preso.",
    "248": "Utenti",
    "249": "U value",
    "250": "Uf",
    "251": "Ug",
    "252": "W.ID assegnato",
    "253": "Attenzione a non danneggiare il Window.ID",
    "254": "Benvenuto",
    "255": "Ovest",
    "256": "Larghezza",
    "257": "Dimensione complessiva dell'elemento (mm)",
    "258": "Area della finestra da",
    "259": "Area della finestra in",
    "260": "Finestra Numero materiale elettronico",
    "261": "Numero di serie della finestra elettronica",
    "262": "Posizione di installazione della finestra",
    "263": "Sistema della finestra",
    "264": "Tipo di finestra",
    "265": "Sostituzione delle ante",
    "266": "Sei sicuro?",
    "267": "Cancella il filtro",
    "268": "Modifica",
    "269": "Esportazione CSV",
    "270": "Lettore di codici a barre",
    "271": "ID vetro",
    "272": "Informazioni",
    "273": "Link",
    "274": "Utenti collegati",
    "275": "Email",
    "276": "Impostazioni email",
    "277": "Periodo di prova dell'email",
    "278": "Titolo del servizio / qualifica",
    "279": "Società di servizi",
    "280": "Telefono del servizio",
    "281": "Email di servizio",
    "282": "Stato del servizio",
    "283": "Impostazioni della stazione",
    "284": "Oggetto",
    "285": "Tel.",
    "286": "Utente",
    "287": "Sito web",
    "288": "Window ID",
    "289": "Sistema della finestra",
    "290": "Dettagli dell'utente finale",
    "291": "Nome",
    "292": "Trucco in vetro",
    "293": "Dettagli hardware o ferramenta?",
    "294": "Titolo del posatore /qualifica",
    "295": "Azienda del posatore",
    "296": "Telefono del posatore",
    "297": "Email del posatore",
    "298": "URL",
    "299": "Paese",
    "300": "Si prega di aggiungere un utente per questa azienda",
    "301": "Menu aggiuntivo",
    "302": "Dashboard",
    "303": "App",
    "304": "Design della pagina principale",
    "305": "Gestione utenti",
    "306": "Conferma",
    "307": "Corpo",
    "308": "Allegato",
    "309": "Id",
    "310": "Posizione",
    "311": "Avviso",
    "312": "W.ID",
    "314": "Rivenditori",
    "315": "Attivare",
    "316": "Disattivare",
    "317": "azienda",
    "318": "Mi dispiace",
    "319": "Amministratore",
    "320": "utente",
    "321": "Codice postale",
    "322": "Logo",
    "323": "Sfondo",
    "324": "Partita IVA",
    "325": "CE Documents ? Marcatura CE?",
    "326": "Impostazioni dell'applicazione",
    "327": "Automatico",
    "328": "Manuale",
    "329": "Sfogliare",
    "330": "Caricamento",
    "331": "Caricamento automatico dei documenti",
    "332": "Cartella di origine",
    "333": "Scegliere il percorso della cartella per il caricamento automatico dei documenti.",
    "334": "Scegliere il file .zip contenente i file PPS XML",
    "335": "Stazione",
    "336": "Scansione manuale",
    "337": "Stazione Smart Glass",
    "338": "Trasferimento di informazioni",
    "339": "Numero massimo di nastri nella timeline",
    "340": "QR-Code da",
    "341": "fino a QR-Code",
    "342": "Prefisso del QR-Code",
    "343": "Lunghezza del QR-Code",
    "344": "I dati del codice a barre non sono validi.",
    "345": "Chiave Api",
    "346": "inserire QR-code",
    "347": "Nome della stazione",
    "348": "File non selezionato",
    "349": "File caricato",
    "350": "Errore di caricamento del file",
    "351": "La scansione manuale è abilitata",
    "352": "Inserire il codice a barre",
    "353": "Il codice a barre non è valido",
    "354": "Questo codice a barre è stato importato",
    "356": "Nessun dato",
    "357": "Connessione fallita",
    "358": "Cronologia",
    "359": "Il codice a barre è in uso.",
    "360": "Errore durante l'abbinamento del codice a barre",
    "361": "Manuale",
    "362": "Da",
    "363": "A",
    "364": "La lunghezza del prefisso del QR-Code deve essere inferiore alla lunghezza del QR-Code",
    "365": "L'intervallo del codice a barre non è valido",
    "366": "Numeri non validi",
    "367": "Utilizzato in precedenza",
    "368": "Utilizzato oggi",
    "369": "Libero",
    "370": "Utilizzato fino ad oggi",
    "371": "Utilizzo del numero di QR-Code per l'attuale gamma di stazioni",
    "372": "Traduzioni",
    "373": "Modifica traduzione",
    "374": "Chiave",
    "375": "Inglese",
    "376": "Valore",
    "377": "ID da assegnare",
    "378": "Posizione delle finestre e delle porte nel progetto",
    "379": "Servizio di riparazione / prova di riparazione",
    "380": "Manutenzione e cronologia interventi",
    "381": "Protocollo di montaggio",
    "382": "Modifica",
    "383": "Sostituzione del vetro",
    "384": "Sostituzione dell'anta",
    "386": "Inserimento del testo",
    "387": "Le selezioni vengono mantenute",
    "388": "Nuove funzioni",
    "389": "Registro delle manutenzioni effettuate",
    "390": "Demisting del vetro",
    "391": "Fissare un appuntamento per la manutenzione",
    "392": "All'elenco completo BV",
    "393": "Informazioni sulla finestra",
    "394": "Dettagli sulla posa in opera",
    "395": "Colore",
    "396": "Larghezza x altezza dell'elemento in mm",
    "397": "Larghezza x altezza del vetro in mm",
    "398": "Informazioni sul montaggio",
    "399": "Nome del posatore",
    "400": "Data/ora della posa in opera",
    "401": "Luogo di installazione (GPS)",
    "402": "ID dispositivo JustSmart",
    "403": "inviare",
    "404": "Filtro posizione",
    "405": "Nessuna",
    "406": "Porte finestra",
    "407": "Cucina",
    "408": "Bagno",
    "409": "Camera",
    "410": "Tetto",
    "411": "Installazione",
    "412": "Pavimento",
    "413": "Allegare foto",
    "414": "Firma",
    "415": "pulire il disegno",
    "416": "Finestra senza danni",
    "417": "Vetro senza danni",
    "418": "Aprire la fotocamera",
    "419": "Caricare un'immagine esistente",
    "420": "Posare in opera e assegnare la finestra",
    "421": "Verbale di accettazione",
    "422": "Inviare",
    "423": "Intero progetto di costruzione",
    "424": "Tipo di montaggio",
    "425": "Esplicito per elemento",
    "426": "Lavoro completato",
    "427": "Protocolli di montaggio di singole finestre",
    "428": "Esiste un problema",
    "429": "Richiesta di aiuto",
    "430": "Messaggio",
    "431": "Invia",
    "432": "Elemento successivo",
    "433": "Elenco con tutti gli elementi",
    "434": "Programma dei lavori di costruzione",
    "435": "tramite numero d'ordine",
    "436": "tramite scanner di codice QR",
    "437": "mostra singolo",
    "438": "o",
    "439": "Numero di codice a barre",
    "440": "Mostra informazioni",
    "441": "Riparazione/reclamo",
    "442": "Mostra il contatto di riferimento",
    "443": "Feedback",
    "444": "Scansione di un altro QR-code",
    "445": "Manutenzione successiva",
    "446": "Fornitore della finestra",
    "447": "Proprietà",
    "448": "Valore di isolamento acustico",
    "449": "Uf",
    "450": "Soluzioni di retrofit / opzioni di espansione",
    "451": "Contatto",
    "452": "Il tuo Window.ID",
    "453": "Cosa volete fare?",
    "454": "La manutenzione regolare delle finestre ne prolunga la durata e il comfort.",
    "455": "Richiedere un appuntamento per la manutenzione tramite il modulo",
    "456": "Richiedere un appuntamento per la manutenzione per telefono",
    "457": "al proprio artigiano delle finestre",
    "458": "Con la presente confermo i termini e le condizioni e il regolamento GDPR",
    "459": "Il vostro interesse",
    "461": "Contatto via ...",
    "462": "Vetro rotto",
    "463": "La finestra non si chiude bene",
    "464": "La finestra è bloccata",
    "465": "Finestra danneggiata",
    "466": "Altri accessori",
    "467": "Le vostre finestre e porte sono state",
    "468": "montate da questa azienda",
    "469": "Fabbricatore di finestre",
    "470": "Valutate ora le vostre finestre e l'azienda produttrice",
    "471": "Quanto siete soddisfatti del servizio offerto dall'azienda?",
    "472": "I prodotti consegnati vanno bene?",
    "473": "Come vi sembrano le vostre finestre?",
    "474": "Ci sono stati problemi con la lavorazione",
    "475": "Positivo",
    "476": "Cosa migliorereste?",
    "477": "Istruzioni di montaggio in opera",
    "478": "Posizioni delle finestre e delle porte",
    "479": "Aprire il rapporto di montaggio",
    "480": "Elenco completo degli elementi legati ad un numero d'ordine",
    "481": "Elenco degli elementi tramite numero d'ordine / QR",
    "482": "Inviare il registro di progetto",
    "483": "Dettagli finestra",
    "484": "Dettagli singoli",
    "485": "Numero del progetto di costruzione",
    "486": "Finestra",
    "487": "Posizione della finestra",
    "488": "Servizio di riparazione",
    "489": "Dettagli sulla manutenzione",
    "490": "Cliente",
    "491": "Modulo di contatto",
    "492": "Trova subito l'artigiano REHAU più vicino a te",
    "493": "Categoria",
    "494": "Scanner QR",
    "495": "Contatto",
    "496": "aggiornamento",
    "497": "filtri",
    "498": "Montaggio completato",
    "499": "Montaggio completato?",
    "500": "Consegna senza danni",
    "501": "Salva come PDF",
    "502": "Scattare una foto",
    "503": "Indirizzo e-mail non valido",
    "504": "Installare l'app",
    "505": "Luogo",
    "506": "Elenco progetti",
    "507": "Caricare l'ordine selezionato?",
    "508": "Numero d'ordine/codice a barre errato",
    "509": "Finestra singola",
    "510": "Inserire le modifiche",
    "511": "Invia il rapporto Rehau via e-mail",
    "512": "Scegliere 4 opzioni",
    "513": "Ora scansionate il codice QR della finestra per scoprire in quale posizione dell'edificio deve essere installato questo elemento.",
    "514": "Salvare il file",
    "515": "Mostra",
    "516": "risultati",
    "517": "pagina",
    "518": "di",
    "519": "Data da",
    "520": "Data a",
    "521": "aprire",
    "522": "Dimensione",
    "523": "Installato su",
    "524": "Successo",
    "525": "Info",
    "526": "Nuovo ordine caricato",
    "527": "ID assemblatore",
    "528": "Accesso",
    "529": "Confermo i termini e le condizioni generali e il regolamento GDPR.",
    "530": "Disconnessione",
    "531": "scanner",
    "532": "Invia il protocollo",
    "533": "Inviare il protocollo all'amministratore?",
    "534": "Riparare il protocollo",
    "535": "opzione 5",
    "536": "opzione 6",
    "537": "opzione 7",
    "538": "opzione 8",
    "539": "opzione 9",
    "540": "opzione 10",
    "541": "Email inviata.",
    "542": "L'invio dell'e-mail non è riuscito.",
    "543": "Appannamento del lubrificante",
    "544": "Regolato",
    "545": "Pulito",
    "546": "Sigillato",
    "547": "Accessori sostituiti",
    "548": "Commenti",
    "549": "Tutti gli elementi vengono consegnati in cantiere",
    "550": "Posizione degli elementi all'interno del progetto di costruzione",
    "551": "Invio del protocollo fallito!",
    "552": "Salvare le informazioni del protocollo",
    "553": "Informazioni salvate.",
    "554": "Impossibile salvare i dati.",
    "555": "Informazioni sulla finestra intelligente",
    "556": "Accettazione finestra intelligente",
    "557": "Numero di materiale dell'elettronica della finestra",
    "558": "Numero di serie dell'elettronica della finestra",
    "559": "Scansionare il QR-code (DMC) dal controllore master o inserirlo manualmente",
    "560": "Smart Window completamente configurata e funzionante",
    "561": "Tutti gli elementi visibili non presentano danni/difetti",
    "562": "È stato collegato un sistema di commutazione a 0-10V (0-100%)?",
    "563": "Luogo di installazione edificio",
    "564": "Avvio della scansione",
    "565": "Arresto della scansione",
    "566": "Elemento di posizione attualmente selezionato",
    "567": "È possibile selezionare al massimo 4 attributi contemporaneamente.",
    "568": "Pos del cliente",
    "569": "Data di produzione",
    "570": "Modifica ID finestra",
    "571": "ID finestra precedente",
    "572": "Nuovo ID finestra",
    "573": "Il numero Auftrag non esiste o il nuovo numero Auftrag è già in uso.",
    "574": "Codice a barre",
    "575": "Posizione ID",
    "576": "Tipo",
    "577": "ID elemento",
    "578": "Informazioni sull'elemento",
    "579": "Window.ID",
    "580": "Firmare nell'apposito spazio",
    "581": "Terminato",
    "582": "Firma",
    "583": "Nuova lingua",
    "584": "Lingua originale",
    "585": "Nome della lingua",
    "586": "Creare una nuova lingua",
    "587": "Aggiungi lingua",
    "588": "Aggiornamento",
    "589": "aggiornamento in corso",
    "590": "Disattivazione dell'azienda",
    "591": "Attivazione dell'azienda",
    "592": "Siete sicuri di voler disattivare questa azienda?",
    "593": "Siete sicuri di voler attivare questa azienda?",
    "594": "Disattivazione utente",
    "595": "Attivazione dell'utente",
    "596": "Siete sicuri di voler disattivare questo utente?",
    "597": "Siete sicuri di voler attivare questo utente?",
    "598": "ID Posizione",
    "599": "Tutti",
    "600": "Importazione/Esportazione",
    "601": "Scegliere un file in lingua .csv per l'importazione",
    "602": "Importazione",
    "603": "Esportazione",
    "604": "File",
    "605": "Traduttore",
    "606": "File dei codici a barre",
    "607": "Scegliere il percorso della cartella per i file dei codici a barre.",
    "608": "Window_ID",
    "609": "Fenstersystem",
    "610": "clientPos",
    "611": "Farbe",
    "612": "BreBreite_Höhe_Element",
    "613": "Breite_Höhe_Glas",
    "614": "Aufbau_Glas",
    "615": "Beschlags_Info",
    "616": "matched_date",
    "617": "Datum_Uhrzeit",
    "618": "GPS_Koordinaten_LinkMaps",
    "619": "Nr",
    "620": "Montage",
    "621": "Stockwerk",
    "622": "Pos",
    "623": "Ignore starting character(s)",
    "624": "Ignore ending character(s)",
    "625": "Email - User activation",
    "626": "Activate companies",
    "627": "Trial expiration",
    "628": "Resend activation email",
    "629": "Dealer/Factory already exists",
    "630": "User is activated",
    "631": "Go to login page",
    "632": "User details",
    "633": "Add linked user",
    "634": "Delete user link",
    "635": "User is already linked",
    "636": "Username or password is empty",
    "637": "User not found",
    "638": "Send protokol to management",
    "639": "Assembly completed question",
    "640": "Option 5",
    "641": "Option 6",
    "642": "Option 7",
    "643": "Option 8",
    "644": "Option 9",
    "645": "Option 10",
    "646": "Assembler",
    "647": "Type",
    "648": "Date",
    "649": "Description",
    "650": "SchmierenBeschlag",
    "651": "Set",
    "652": "Clean",
    "653": "Seal",
    "654": "Replacement accessories",
    "655": "Comment",
    "656": "Connector",
    "657": "FSystem",
    "658": "StkNr",
    "659": "AuNummer",
    "660": "AuAlpha",
    "661": "Height",
    "662": "Width",
    "663": "Beschlagdaten",
    "664": "Glasbreite x Höhe",
    "665": "Glasaufbau",
    "666": "QR-CodeNr",
    "667": "Window Electric ID",
    "668": "Glass ID",
    "669": "Conditions",
    "670": "Add/Remove",
    "671": "And/Or",
    "672": "Group",
    "673": "Column",
    "674": "Condition",
    "675": "Enter Customer number",
    "676": "Translator",
    "678": "Application version control",
    "679": "Update could take a few minutes to finish",
    "680": "Awaiting new version",
    "681": "There is new application version, are you sure you want to update?",
    "682": "Application is up to date, do you want to restore old version?",
    "683": "Restore old version",
    "684": "Insert station name",
    "685": "Order number char filler",
    "686": "Please insert order number char filler",
    "687": "Order number max length",
    "688": "Please insert order number max length",
    "689": "MDB folder path",
    "690": "Please insert .mdb file path (used by importer when scanning for .mdb files)",
    "691": "XML folder path",
    "692": "Please insert .xml file path (used by importer when scanning for .xml files)",
    "693": "Ignore starting QR-Code character(s)",
    "694": "Ignore ending QR-Code character(s)",
    "695": "Designation",
    "696": "Length",
    "697": "Additional position",
    "698": "Smart window without damage",
    "699": "Smart window connected",
    "700": "Window orientation",
    "701": "Position element number",
    "702": "Smart window configured",
    "703": "Element without damage",
    "704": "Material number",
    "705": "Serial number",
    "706": "No image available",
    "707": "Construction site clean",
    "708": "Drag and drop image/pdf files here",
    "709": "File upload",
    "710": "File type",
    "711": "Order (all positions)",
    "712": "Deleted",
    "713": "Release notes",
    "714": "Height (mm)",
    "715": "Width (mm)",
    "716": "There is no panel for this position",
    "717": "Item",
    "718": "Bulk station",
    "719": "Bulk range",
    "720": "Window ID Last",
    "721": "Glass was replaced",
    "722": "Accessories were repaired",
    "723": "Sash was replaced",
    "724": "Surface was repaired",
    "725": "Accessories were repaired",
    "726": "Repair is completed",
    "727": "Confirm password",
    "728": "Wrong password",
    "729": "Passwords do not match",
    "730": "Password can not be empty",
    "731": "locatton",
    "732": "Timeline",
    "733": "UW_Wert",
    "734": "Special glass",
    "735": "There is no field for this position",
    "736": "Matched elements",
    "737": "Last matched element",
    "738": "Mounted elements",
    "739": "Last mounted element",
    "740": "Station deleted",
    "741": "Are you sure you want to delete station",
    "742": "Add new station",
    "743": "Delete order",
    "744": "Order deletion",
    "745": "Are you sure you want to delete order?",
    "746": "User added",
    "747": "User edited",
    "748": "Forgot password?",
    "749": "The Password field is required",
    "750": "The User name field is required",
    "751": "This user role is not authorized for dashboard login",
    "752": "User is not active",
    "753": "User company is not active or test period is over",
    "754": "Username or password is incorrect",
    "755": "PLZ",
    "756": "Use scroll wheel to zoom picture in and out",
    "757": "Reset password",
    "758": "Email for password reset has been sent",
    "759": "Password has been reset",
    "760": "Email - Reset password",
    "761": "Element Nr",
    "762": "Order number from",
    "763": "Please insert order number from",
    "764": "Order number length",
    "765": "Please insert order number length",
    "766": "Order range from",
    "767": "Please insert order range from",
    "768": "Order range length",
    "769": "Please insert order range length",
    "770": "Please insert order piece from",
    "772": "Order piece length",
    "773": "Please insert order piece length",
    "774": "Position number from",
    "775": "Please insert position number from",
    "776": "Position number length",
    "777": "Please insert position number length",
    "778": "Position piece from",
    "779": "Please insert position piece from",
    "780": "Position piece length",
    "781": "Please insert position piece length",
    "782": "Security level",
    "783": "Barcode filler character",
    "784": "Please insert barcode filler character",
    "785": "back to projects",
    "786": "(read only)",
    "787": "limited access",
    "788": "read only access",
    "789": "own landingpage",
    "790": "Please insert connection string",
    "791": "DB Connection string",
    "792": "DB STD Connection string",
    "793": "DB  BWG Connection string",
    "794": "Database connection",
    "795": "Chat",
    "796": "Messagess",
    "797": "CE Document",
    "798": "Order Document",
    "799": "Last repair",
    "800": "Acceptance completed",
    "801": "New update vailable, please restart application",
    "802": "External link",
    "803": "External link prefix",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder.",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
};

export default it;