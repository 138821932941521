import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, LOGGED_IN_AS } from '../actions/types';

let user = localStorage.getItem('token');
const initialState = user ? { loggedIn: true, user, loggingIn: false, loggedInAs: "" } : { loggingIn: false, loggedIn: false, loggedInAs: "" };

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.payload
      };
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.payload
      };
    case LOGIN_FAILURE:
      return { ...state, loggingIn: false };
    case LOGOUT:
      return { ...state, loggingIn: false };
    case LOGGED_IN_AS:
      return { ...state, loggedInAs: action.payload }
    default:
      return state
  }
}