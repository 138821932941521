const sk = {
    "1": "Áno",
    "2": "Nie",
    "3": "30-dňová testovacia verzia",
    "4": "Prostredníctvom aplikácie bola nahlásená sťažnosť",
    "5": "Prijatie",
    "6": "Dátum prijatia",
    "7": "Akcia",
    "8": "Aktívne",
    "9": "Činnosti",
    "10": "Pridať",
    "11": "Pridať inštalačný program",
    "12": "Pridať spoločnosť",
    "13": "Pridať predajcu",
    "14": "Pridať výrobcu",
    "15": "Pridať správu nehnuteľností",
    "16": "Pridať používateľa",
    "17": "Ďalšie pole",
    "18": "Úprava názvu dodatočného poľa",
    "19": "Komentáre",
    "20": "Dodatočné informácie",
    "21": "Ďalšie produkty",
    "22": "Nahradiť hardvér",
    "23": "Administrátor",
    "24": "Administratívny pohľad",
    "25": "Boli všetky prvky dodané na miesto?",
    "26": "Všetky pozície sú nainštalované",
    "27": "Všetky práva vyhradené.",
    "28": "Ste si istí, že chcete odstrániť tohto výrobcu?",
    "29": "Ste si istí, že chcete odstrániť tohto používateľa?",
    "30": "Nainštalované",
    "31": "Inštalatér",
    "32": "Inštalátori",
    "33": "Montážna firma",
    "34": "Inštalácia dokončená",
    "35": "Dátum inštalácie",
    "36": "Priradenia",
    "37": "Pokyny na starostlivosť",
    "38": "Údaje o certifikáte",
    "39": "Certifikát(y)",
    "40": "Výmena krídla ",
    "41": "Vyčistiť",
    "42": "Zatvorte",
    "43": "Farba",
    "44": "Farba/povrchová úprava",
    "45": "Komisia",
    "46": "Spoločnosť",
    "47": "Podrobnosti o spoločnosti",
    "48": "Informácie o spoločnosti",
    "49": "Smer kompasu",
    "50": "Sťažnosti",
    "51": "Projekt dokončený",
    "52": "Dokončené",
    "53": "Pripojené okno",
    "54": "Čisté stavenisko",
    "55": "Dátum vytvorenia",
    "56": "Koncový používateľ",
    "57": "Číslo zákazníka",
    "58": "Referencia prvku",
    "59": "Dátum",
    "60": "Rozsah dátumu",
    "61": "Predajca",
    "62": "Vymazať spoločnosť",
    "63": "Vymazať výrobcu",
    "64": "Vymazať používateľa",
    "65": "Dátum dodania",
    "66": "Dodané bez poškodenia",
    "67": "Popis",
    "68": "Určené miesto inštalácie",
    "69": "Zobrazenie _MENU_ záznamov na stránku",
    "70": "Distribúcia",
    "71": "Dokumenty",
    "72": "Východ",
    "73": "Upraviť inštalatéra",
    "74": "Upraviť spoločnosť",
    "75": "Upraviť výrobcu",
    "76": "Upraviť správu nehnuteľností",
    "77": "Upraviť používateľa",
    "78": "Šírka x výška prvku",
    "79": "Prvok s týmto id neexistuje",
    "80": "Prvky",
    "81": "Uložené údaje e-mailu.",
    "82": "Zadajte farbu",
    "83": "Zadajte províziu",
    "84": "Zadajte názov spoločnosti",
    "85": "Zadajte e-mail",
    "86": "Zadajte predĺženie",
    "87": "Zadajte výšku",
    "88": "Zadajte IP adresu",
    "89": "Zadajte názov",
    "90": "Zadajte číslo",
    "91": "Zadajte počet pozícií",
    "92": "Zadajte heslo",
    "93": "Zadajte telefón",
    "94": "Zadajte telefónne číslo",
    "95": "Zadajte mesto",
    "96": "Zadajte množstvo",
    "97": "Zadajte ulicu",
    "98": "Zadajte priezvisko",
    "99": "Zadajte užívateľské meno",
    "100": "Zadajte ID účtu",
    "101": "Zadajte webovú stránku",
    "102": "Zadajte šírku",
    "103": "Chyba pri ukladaní údajov na server.",
    "104": "Chyba pri ukladaní údajov e-mailu.",
    "105": "Oprava príslušenstva",
    "106": "Vypršala platnosť na",
    "107": "Platnosť vypršala pri",
    "108": "Rozšírenie",
    "109": "Výrobca",
    "110": "Továreň",
    "111": "Filter",
    "112": "filtrované z _MAX_ celkového počtu záznamov",
    "113": "Prvý",
    "114": "Zrušiť",
    "115": "Informácie o hardvéri",
    "116": "Mucholapky",
    "117": "Plocha pre nohy",
    "118": "Komentáre",
    "119": "Z aplikácie",
    "120": "Z pohľadu riadenia",
    "121": "Čistič skla",
    "122": "Vymeniť sklo",
    "123": "Štruktúra skla",
    "124": "Šírka x výška skla",
    "125": "Údržba hardvéru",
    "126": "Výmena hardvéru",
    "127": "Výška",
    "128": "Ako čistiť",
    "129": "Obrázok",
    "130": "Informácie o prvku",
    "131": "Informácie sú obmedzené",
    "132": "Vstup",
    "134": "Miesto inštalácie",
    "135": "zadajte čiarový kód",
    "136": "IP adresa",
    "137": "je povinná",
    "138": "Jazyk",
    "139": "Posledný",
    "140": "Posledná údržba",
    "141": "Posledná reklamácia",
    "142": "Posledný servis",
    "143": "Odkaz na článok",
    "144": "Načítanie",
    "145": "Všetky prvky podľa špecifikácie?",
    "146": "Odhlásiť sa",
    "147": "E-mail - Prístup k údajom",
    "148": "Údržba",
    "149": "Zmenil",
    "150": "Vyrobené",
    "151": "Návrh hlavného ovládača",
    "152": "Číslo materiálu hlavného ovládača",
    "153": "Sériové číslo hlavného ovládača",
    "154": "Zodpovedajúci dátum",
    "155": "Viditeľnosť ponuky",
    "156": "Automatické potvrdenie čiarového kódu",
    "157": "Viac informácií o REHAU Sustainability",
    "158": "Nové skúšobné obdobie",
    "159": "Ďalšie",
    "160": "Žiadne poškodenie prvku",
    "161": "Žiadne poškodenie skla",
    "162": "Nenašli sa žiadne zodpovedajúce záznamy",
    "163": "Žiadny z prvkov nie je nainštalovaný",
    "164": "Sever",
    "165": "Nie je nainštalovaný",
    "166": "Nič sa nenašlo - ľutujeme",
    "167": "Číslo",
    "168": "Počet prvkov",
    "169": "Prehľad projektu",
    "170": "Počet pozícií",
    "171": "na stránke",
    "172": "Dátum objednávky",
    "173": "Číslo projektu",
    "174": "Priebeh projektu",
    "175": "Výstup",
    "176": "Heslo",
    "177": "Telefón",
    "178": "Mesto",
    "179": "Čistič PVC",
    "180": "Element Doc.",
    "181": "Pozícia Podrobnosti o prvku",
    "182": "Pozícia s týmto id neexistuje",
    "183": "Pozície",
    "184": "Predchádzajúce",
    "185": "Primárne poverenia používateľa",
    "186": "Vytlačiť prihlasovacie údaje",
    "187": "Spracovanie",
    "188": "Fabrikant",
    "189": "Systém profilov",
    "190": "Projekt",
    "191": "Číslo projektu",
    "192": "Správa nehnuteľností",
    "193": "Množstvo",
    "194": "Sťažnosť",
    "195": "Prosím, vyberte stanicu",
    "196": "Recyklovaný obsah",
    "197": "Oprava",
    "198": "Príslušenstvo na opravu",
    "199": "Oprava povrchu",
    "200": "Nahlásené z aplikácie",
    "201": "Nahlásené z pohľadu správy",
    "202": "Obnovenie skúšobného obdobia",
    "203": "Výsledok",
    "204": "Úloha",
    "205": "Uložiť",
    "206": "Úspešne uložené.",
    "207": "Vyčistiť povrch",
    "208": "Vyhľadávanie",
    "209": "Úroveň zabezpečenia",
    "210": "Odoslať údaje do REHAU",
    "211": "Servis",
    "212": "Servis a predaj",
    "213": "Poskytovateľ služieb",
    "214": "Nastavenia",
    "215": "Ak sa má vykonať manuálna celková akceptácia alebo údržba",
    "216": "Zobrazenie _začiatku_ až _konca_ _celkových_ záznamov",
    "217": "Zobrazenie 0 až 0 z 0 záznamov",
    "218": "Žalúzie",
    "219": "Prihlásiť sa",
    "220": "Prihláste sa a začnite reláciu",
    "221": "Informácie o inteligentnom skle",
    "222": "Inteligentné okno",
    "223": "Niektoré prvky sú nainštalované",
    "224": "Niektoré prvky nie sú zosúladené",
    "225": "Niektoré pozície je potrebné nainštalovať",
    "226": "Juh",
    "227": "Štatistika",
    "228": "Ulica",
    "229": "Priezvisko",
    "230": "Udržateľnosť a energia",
    "231": "Telefón",
    "232": "Testovacie obdobie úspešne resetované",
    "233": "testovacie obdobie(-a)",
    "234": "Oprava dokončená",
    "235": "Existujú existujúce sťažnosti",
    "236": "Neexistuje žiadna spoločnosť s týmto identifikátorom",
    "237": "O tomto prvku nie sú žiadne informácie",
    "238": "Táto spoločnosť sa už používa",
    "239": "Názov",
    "240": "na",
    "241": "Celková akceptácia",
    "242": "Celkom",
    "243": "Neznáme",
    "244": "Nahrať logo",
    "245": "Správa používateľa",
    "246": "Používateľské meno",
    "247": "Užívateľské meno je obsadené.",
    "248": "Používatelia",
    "249": "Hodnota U",
    "250": "Hodnota U (rám)",
    "251": "Hodnota U (sklo)",
    "252": "Priradené W.ID",
    "253": "Upozornenie, aby nedošlo k poškodeniu ID okna",
    "254": "Vitajte",
    "255": "West",
    "256": "Šírka",
    "257": "Celková veľkosť prvku (mm)",
    "258": "Plocha okna od",
    "259": "Plocha okna v",
    "260": "Okno Číslo elektronického materiálu",
    "261": "Okno Elektronické sériové číslo",
    "262": "Miesto inštalácie okna",
    "263": "Systém okna",
    "264": "Typ okna",
    "265": "Výmena krídla ",
    "266": "Ste si istý?",
    "267": "Vyčistite filter",
    "268": "Upraviť",
    "269": "Exportovať CSV",
    "270": "Čítačka čiarových kódov",
    "271": "ID skla",
    "272": "Informácie",
    "273": "Odkaz",
    "274": "Prepojení používatelia",
    "275": "E-mail",
    "276": "Nastavenia e-mailu",
    "277": "E-mail - Testovacie obdobie",
    "278": "Názov služby",
    "279": "Spoločnosť poskytujúca služby",
    "280": "Telefón služby",
    "281": "Servisný e-mail",
    "282": "Stav",
    "283": "Nastavenia stanice",
    "284": "Predmet",
    "285": "Tel.",
    "286": "Používateľ",
    "287": "Webová stránka",
    "288": "ID okna",
    "289": "Systém okna",
    "290": "Údaje o koncovom používateľovi",
    "291": "Názov",
    "292": "Štruktúra skla",
    "293": "Údaje o hardvéri",
    "294": "Názov inštalatéra",
    "295": "Inštalačná spoločnosť",
    "296": "Telefón inštalatéra",
    "297": "E-mail inštalatéra",
    "298": "ADRESA URL",
    "299": "Krajina",
    "300": "Prosím, pridajte používateľa pre túto spoločnosť",
    "301": "Ďalšie menu",
    "302": "Prístrojová doska",
    "303": "Aplikácia",
    "304": "Návrh vstupnej stránky",
    "305": "Správa používateľov",
    "306": "Potvrdenie",
    "307": "Telo",
    "308": "Príloha",
    "309": "Id",
    "310": "Pozícia",
    "311": "Upozornenie",
    "312": "W.ID",
    "314": "Obchodníci",
    "315": "Aktivovať",
    "316": "Deaktivovať",
    "317": "spoločnosť",
    "318": "Prepáčte",
    "319": "Správca",
    "320": "používateľ",
    "321": "Poštové smerovacie číslo",
    "322": "Logo",
    "323": "Tapeta",
    "324": "ID účtu",
    "325": "Dokumenty CE",
    "326": "Nastavenia aplikácie",
    "327": "Automatické",
    "328": "Manuálne",
    "329": "Prehľadávať",
    "330": "Nahrávanie",
    "331": "Automatické nahrávanie dokumentov",
    "332": "Zdrojový priečinok",
    "333": "Vyberte cestu k priečinku pre automatické nahrávanie dokumentov.",
    "334": "Vyberte súbor .zip obsahujúci súbor PPS XML",
    "335": "Stanica",
    "336": "Manuálne skenovanie",
    "337": "Stanica Smart Glass",
    "338": "Prenos informácií",
    "339": "Maximálny počet pások na časovej osi",
    "340": "QR-kód z",
    "341": "QR-kód do",
    "342": "Prefix QR-kódu",
    "343": "Dĺžka QR-kódu",
    "344": "Údaje čiarového kódu sú neplatné.",
    "345": "Api kľúč",
    "346": "zadajte qrcode",
    "347": "Názov stanice",
    "348": "Súbor nie je vybraný",
    "349": "Súbor nahraný",
    "350": "Chyba pri nahrávaní súboru",
    "351": "Manuálne skenovanie je povolené",
    "352": "Zadajte qrcode",
    "353": "Čiarový kód je neplatný",
    "354": "Tento čiarový kód je importovaný",
    "356": "Žiadne údaje",
    "357": "Pripojenie zlyhalo",
    "358": "História",
    "359": "Čiarový kód sa používa.",
    "360": "Chyba pri porovnávaní čiarového kódu",
    "361": "Manuálne",
    "362": "Z adresy",
    "363": "Do",
    "364": "Dĺžka prefixu QR-kódu musí byť menšia ako dĺžka QR-kódu",
    "365": "Rozsah čiarového kódu je neplatný",
    "366": "Neplatné čísla",
    "367": "Použité predtým",
    "368": "Použité dnes",
    "369": "Voľný",
    "370": "Použité do dnešného dňa",
    "371": "Použitie čísla QR-kódu pre aktuálny rozsah staníc",
    "372": "Preklady",
    "373": "Upraviť preklad",
    "374": "Kľúč",
    "375": "Angličtina",
    "376": "Hodnota",
    "377": "ID na priradenie",
    "378": "Pozície okien a dverí v projekte",
    "379": "Oprava / doklad o oprave",
    "380": "Údržba a história",
    "381": "Správa o inštalácii",
    "382": "Úprava",
    "383": "Výmena skla",
    "384": "Výmena krídla",
    "386": "Komentáre",
    "387": "Kompletná oprava",
    "388": "Nové prvky",
    "389": "Denník údržby",
    "390": "Údržba hardvéru",
    "391": "Dohodnutie termínu údržby",
    "392": "Úplný zoznam projektov",
    "393": "Informácie o okne",
    "394": "Podrobnosti o inštalácii",
    "395": "Farba",
    "396": "Šírka x výška prvku v mm",
    "397": "Šírka x výška skla v mm",
    "398": "Informácie o montáži",
    "399": "Meno inštalatéra",
    "400": "Dátum/čas montáže",
    "401": "Miesto inštalácie (GPS)",
    "402": "ID zariadenia JustSmart",
    "403": "odoslať",
    "404": "Pozícia filtra",
    "405": "Žiadne",
    "406": "Balkónové dvere",
    "407": "Kuchyňa",
    "408": "Kúpeľňa",
    "409": "Izba",
    "410": "Strecha",
    "411": "Inštalácia",
    "412": "Pozícia",
    "413": "Pripojte obrázok",
    "414": "Podpis",
    "415": "Výkres",
    "416": "Žiadne poškodenie okna",
    "417": "Sklo bez poškodenia",
    "418": "Otvorte fotoaparát",
    "419": "Nahrať stránku",
    "420": "Inštalácia a priradenie okna",
    "421": "Správa o prijatí",
    "422": "Odoslať",
    "423": "Celý stavebný projekt",
    "424": "Typ montáže",
    "425": "Explicitné pre prvok",
    "426": "Celá stavba dokončená",
    "427": "Správa o montáži pre jednotlivé prvky",
    "428": "Existuje problém",
    "429": "Požiadať o pomoc",
    "430": "Správa",
    "431": "Odoslať",
    "432": "Ďalší prvok",
    "433": "Úplný zoznam prvkov",
    "434": "Harmonogram výstavby",
    "435": "prostredníctvom poradového čísla",
    "436": "prostredníctvom skenera QR kódov",
    "437": "zobraziť jeden",
    "438": "alebo",
    "439": "číslo čiarového kódu",
    "440": "Zobraziť informácie",
    "441": "Oprava/reklamácia",
    "442": "Zobraziť kontaktnú osobu",
    "443": "Spätná väzba",
    "444": "Naskenujte ďalší QR kód",
    "445": "Nasledujúca splatná údržba",
    "446": "Dodávateľ okien",
    "447": "Vlastnosti",
    "448": "Hodnota zvukovej izolácie",
    "449": "Uf",
    "450": "Riešenia modernizácie",
    "451": "Kontakt",
    "452": "ID vášho okna",
    "453": "Čo chcete urobiť ?",
    "454": "Pravidelná údržba okien/dverí predlžuje životnosť a komfort",
    "455": "Požiadajte o termín údržby prostredníctvom kontaktného formulára",
    "456": "Vyžiadajte si termín údržby telefonicky",
    "457": "svojmu oknárskemu majstrovi",
    "458": "Týmto potvrdzujem všeobecné obchodné podmienky a nariadenie GDPR",
    "459": "Váš záujem",
    "461": "Kontaktovať prostredníctvom ...",
    "462": "Rozbité sklo",
    "463": "Okno sa nezatvára správne",
    "464": "Okno je zaseknuté",
    "465": "Poškodené okno",
    "466": "Ostatné príslušenstvo",
    "467": "Vaše okná a dvere boli zapnuté",
    "468": "montované touto spoločnosťou",
    "469": "Zhotoviteľ okien",
    "470": "Ohodnoťte teraz svoje okná a remeselnícku firmu",
    "471": "Ako ste spokojní so službami spoločnosti",
    "472": "Sú dodané výrobky v poriadku ?",
    "473": "Ako sa vám páčia vaše okná ?",
    "474": "Vyskytli sa nejaké problémy so spracovaním ?",
    "475": "S čím ste boli spokojní?",
    "476": "Čo by ste zlepšili?",
    "477": "Návod na montáž",
    "478": "Umiestnenie okien a dverí",
    "479": "Otvorte správu o montáži",
    "480": "Kompletný zoznam prvkov projektu",
    "481": "Zoznam prvkov prostredníctvom čísla objednávky / QR",
    "482": "Odoslanie projektového protokolu",
    "483": "Údaje o oknách",
    "484": "Jednotlivé údaje",
    "485": "Číslo stavebného projektu",
    "486": "Okno",
    "487": "Poloha okna",
    "488": "Služba opravy",
    "489": "Údaje o údržbe",
    "490": "Zákazník",
    "491": "Kontaktný formulár",
    "492": "Nájdite si teraz remeselníka REHAU vo vašom okolí",
    "493": "Kategória",
    "494": "Skenovanie QR",
    "495": "Kontaktná osoba",
    "496": "aktualizovať",
    "497": "filtre",
    "498": "Inštalácia dokončená",
    "499": "Inštalácia dokončená?",
    "500": "Dodané bez poškodenia",
    "501": "Uložiť ako PDF",
    "502": "Odfotografujte sa",
    "503": "Nie je platná e-mailová adresa",
    "504": "Inštalácia aplikácie",
    "505": "Umiestnite",
    "506": "Zoznam projektov",
    "507": "Načítať vybranú objednávku ?",
    "508": "Nesprávne číslo objednávky/ čiarový kód",
    "509": "Jedno okno",
    "510": "Zadajte zmeny",
    "511": "Odoslať správu e-mailom",
    "512": "Vyberte 4 možnosti",
    "513": "Teraz naskenujte kód QR a zistite, kde má byť prvok nainštalovaný.",
    "514": "Uložiť súbor",
    "515": "Zobraziť",
    "516": "Výsledky",
    "517": "Stránka",
    "518": "na stránke",
    "519": "Dátum od",
    "520": "Dátum do",
    "521": "otvoriť",
    "522": "Veľkosť",
    "523": "Nainštalované na",
    "524": "Úspech",
    "525": "Informácie",
    "526": "Načítaná nová objednávka",
    "527": "ID inštalátora",
    "528": "Prihlásenie",
    "529": "Týmto potvrdzujem všeobecné obchodné podmienky a nariadenie GDPR",
    "530": "Odhlásenie",
    "531": "skener",
    "532": "Odoslať správu",
    "533": "Má sa na ovládací panel odoslať správa o prijatí všetkých položiek tejto objednávky?",
    "534": "Protokol o oprave",
    "535": "možnosť 5",
    "536": "možnosť 6",
    "537": "možnosť 7",
    "538": "možnosť 8",
    "539": "možnosť 9",
    "540": "možnosť 10",
    "541": "Odoslaný e-mail.",
    "542": "Chyba pri odosielaní e-mailu!",
    "543": "Mazanie zahmlievania",
    "544": "Nastavené",
    "545": "Vyčistené",
    "546": "Zapečatené",
    "547": "Vymenený hardvér",
    "548": "Komentár",
    "549": "Všetky prvky sa dodávajú na stavenisko",
    "550": "Umiestnenie prvkov v rámci stavebného projektu",
    "551": "Chyba pri odosielaní správy!",
    "552": "Uložiť správu",
    "553": "Uložené informácie.",
    "554": "Nepodarilo sa uložiť údaje.",
    "555": "Informácie o inteligentnom okne",
    "556": "Prijatie inteligentného okna",
    "557": "Číslo materiálu elektroniky okna",
    "558": "Sériové číslo elektroniky okna",
    "559": "Naskenujte QR kód (DMC) z hlavného ovládača alebo ho zadajte ručne",
    "560": "Inteligentné okno je plne nakonfigurované a funkčné",
    "561": "Všetky viditeľné prvky bez poškodenia/chyb",
    "562": "Bol pripojený spínací systém 0-10V (0-100 %)?",
    "563": "Miesto inštalácie budova",
    "564": "Spustenie skenovania",
    "565": "Zastaviť skenovanie",
    "566": "Vybraný prvok",
    "567": "Súčasne je možné vybrať maximálne 4 atribúty.",
    "568": "Klient Pos",
    "569": "Dátum výroby",
    "570": "Zmeniť Window.ID",
    "571": "Predchádzajúce Window.ID",
    "572": "Nové Window.ID",
    "573": "Číslo objednávky neexistuje alebo sa už používa nové číslo objednávky.",
    "574": "Window.ID",
    "575": "ID pozície",
    "576": "Typ",
    "577": "ID prvku",
    "578": "Informácie o prvku",
    "579": "Window.ID",
    "580": "Prosím, podpíšte sa na určenom mieste",
    "581": "Dokončené",
    "582": "Podpísať",
    "583": "Nový jazyk",
    "584": "Pôvodný jazyk",
    "585": "Názov jazyka",
    "586": "Vytvoriť nový jazyk",
    "587": "Pridať jazyk",
    "588": "Aktualizácia",
    "589": "prebieha aktualizácia",
    "590": "Deaktivácia spoločnosti",
    "591": "Aktivácia spoločnosti",
    "592": "Ste si istí, že chcete túto spoločnosť deaktivovať?",
    "593": "Ste si istí, že chcete túto spoločnosť aktivovať?",
    "594": "Deaktivácia používateľa",
    "595": "Aktivácia používateľa",
    "596": "Ste si istí, že chcete deaktivovať tohto používateľa?",
    "597": "Ste si istí, že chcete aktivovať tohto používateľa?",
    "598": "ID Pozícia",
    "599": "Všetky",
    "600": "Import/export",
    "601": "Na import vyberte jazykový súbor .csv",
    "602": "Import",
    "603": "Export",
    "604": "Súbor",
    "605": "Prekladač",
    "606": "Súbory čiarových kódov",
    "607": "Vyberte cestu k priečinku pre súbory čiarových kódov.",
    "608": "Window_ID",
    "609": "Systém profilov",
    "610": "Pozícia zákazníka",
    "611": "Farba",
    "612": "Veľkosť prvku",
    "613": "Veľkosť skla",
    "614": "Štruktúra skla",
    "615": "Informácie o hardvéri",
    "616": "Zodpovedajúci dátum",
    "617": " Dátum/čas inštalácie",
    "618": "Údaje GPS o mieste inštalácie",
    "619": "Č:",
    "620": "Inštalácia:",
    "621": "Podlaha:",
    "622": "Poz:",
    "623": "Ignorovať počiatočný znak(y)",
    "624": "Ignorovať koncové znaky",
    "625": "E-mail - aktivácia používateľa",
    "626": "Aktivácia spoločností",
    "627": "Uplynutie skúšobnej lehoty",
    "628": "Opätovné odoslanie aktivačného e-mailu",
    "629": "Obchodník/továreň už existuje",
    "630": "Používateľ je aktivovaný",
    "631": "Prejsť na prihlasovaciu stránku",
    "632": "Podrobnosti o používateľovi",
    "633": "Pridať prepojeného používateľa",
    "634": "Odstrániť prepojenie používateľa",
    "635": "Používateľ je už prepojený",
    "636": "Používateľské meno alebo heslo je prázdne",
    "637": "Používateľ nebol nájdený",
    "638": "Odoslať protokol vedeniu",
    "639": "Vyplnená otázka na zhromaždenie",
    "640": "Možnosť 5",
    "641": "Možnosť 6",
    "642": "Možnosť 7",
    "643": "Možnosť 8",
    "644": "Možnosť 9",
    "645": "Možnosť 10",
    "646": "Assembler",
    "647": "Typ",
    "648": "Dátum",
    "649": "Popis",
    "650": "Údržba hardvéru",
    "651": "Nastavenie",
    "652": "Vyčistiť",
    "653": "Tesnenie",
    "654": "Náhradné príslušenstvo",
    "655": "Komentár",
    "656": "Konektor",
    "657": "okenný systém",
    "658": "kus",
    "659": "Číslo projektu",
    "660": "ID objednávky",
    "661": "výška",
    "662": "šírka",
    "663": "Informácie o hardvéri",
    "664": "Šírka x výška skla",
    "665": "sklenený makeup",
    "666": "Window.ID",
    "667": "Okno Elektrické ID",
    "668": "ID skla",
    "669": "Podmienky",
    "670": "Pridať/odstrániť",
    "671": "A/alebo",
    "672": "Skupina",
    "673": "Stĺpec",
    "674": "Podmienka",
    "675": "Zadajte číslo zákazníka",
    "676": "Prekladateľ",
    "678": "Riadenie verzií aplikácií",
    "679": "Dokončenie aktualizácie môže trvať niekoľko minút",
    "680": "Čakanie na novú verziu",
    "681": "Je k dispozícii nová verzia aplikácie, ste si istí, že chcete aktualizovať?",
    "682": "Aplikácia je aktuálna, chcete obnoviť starú verziu?",
    "683": "Obnoviť starú verziu",
    "684": "Vložte názov stanice",
    "685": "Objednávka číslo char výplň",
    "686": "Prosím, vložte znakovú výplň čísla objednávky",
    "687": "Maximálna dĺžka čísla objednávky",
    "688": "Prosím, vložte číslo objednávky s maximálnou dĺžkou",
    "689": "Cesta k priečinku MDB",
    "690": "Prosím vložte cestu k súboru .mdb (používa importér pri vyhľadávaní súborov .mdb)",
    "691": "Cesta k priečinku XML",
    "692": "Prosím, vložte cestu k súboru .xml (používa importér pri vyhľadávaní súborov .xml)",
    "693": "Ignorovať počiatočný(é) znak(y) QR-kódu",
    "694": "Ignorovať koncové znaky QR-kódu",
    "695": "Označenie",
    "696": "Dĺžka",
    "697": "Dodatočná pozícia",
    "698": "Inteligentné okno bez poškodenia",
    "699": "Inteligentné okno pripojené",
    "700": "Orientácia okna",
    "701": "Číslo polohového prvku",
    "702": "Inteligentné okno nakonfigurované",
    "703": "Prvok bez poškodenia",
    "704": "Číslo materiálu",
    "705": "Sériové číslo",
    "706": "Žiadny obrázok nie je k dispozícii",
    "707": "Čisté stavenisko",
    "708": "Presuňte sem súbory s obrázkami/pdf",
    "709": "Nahrávanie súborov",
    "710": "Typ súboru",
    "711": "Objednávka (všetky pozície)",
    "712": "Odstránené",
    "713": "Poznámky k vydaniu",
    "714": "Výška (mm)",
    "715": "Šírka (mm)",
    "716": "Pre túto pozíciu nie je k dispozícii žiadny panel",
    "717": "Položka",
    "718": "Hromadná stanica",
    "719": "Rozsah hromadného tovaru",
    "720": "Window.ID (posledné)",
    "721": "Sklo bolo vymenené",
    "722": "Príslušenstvo bolo opravené",
    "723": "Krídlo bolo vymenené",
    "724": "Povrch bol opravený",
    "725": "Príslušenstvo bolo opravené",
    "726": "Oprava je dokončená",
    "727": "Potvrdiť heslo",
    "728": "Nesprávne heslo",
    "729": "Heslá sa nezhodujú",
    "730": "Heslo nemôže byť prázdne",
    "731": "Umiestnenie",
    "732": "Časová os",
    "733": "Hodnota U (W/m²K)",
    "734": "Špeciálne sklo",
    "735": "Pre túto položku nie je k dispozícii žiadne pole",
    "736": "Zodpovedajúce prvky",
    "737": "Posledný zhodný prvok",
    "738": "Namontované prvky",
    "739": "Posledný namontovaný prvok",
    "740": "Vymazaná stanica",
    "741": "Ste si istí, že chcete vymazať stanicu",
    "742": "Pridať novú stanicu",
    "743": "Odstrániť objednávku",
    "744": "Vymazanie objednávky",
    "745": "Určite chcete vymazať objednávku?",
    "746": "Pridaný používateľ",
    "747": "Užívateľ upravil",
    "748": "Zabudli ste heslo?",
    "749": "Pole Heslo je povinné",
    "750": "Pole Meno používateľa je povinné",
    "751": "Táto používateľská rola nie je oprávnená na prihlásenie na ovládací panel",
    "752": "Používateľ nie je aktívny",
    "753": "Používateľská spoločnosť nie je aktívna alebo testovacie obdobie skončilo",
    "754": "Používateľské meno alebo heslo je nesprávne",
    "755": "Poštové smerovacie číslo",
    "756": "Pomocou rolovacieho kolieska môžete obrázok zväčšovať a zmenšovať",
    "757": "Obnovenie hesla",
    "758": "Bol odoslaný e-mail na obnovenie hesla",
    "759": "Heslo bolo resetované",
    "760": "E-mail - resetovanie hesla",
    "761": "Číslo prvku",
    "762": "Číslo objednávky od",
    "763": "Vložte číslo objednávky od",
    "764": "Dĺžka čísla objednávky",
    "765": "Prosím, vložte dĺžku čísla objednávky",
    "766": "Rozsah objednávky od",
    "767": "Prosím, vložte rozsah objednávky od",
    "768": "Dĺžka rozsahu objednávky",
    "769": "Vložte prosím dĺžku rozsahu objednávky",
    "770": "Please insert order piece from",
    "772": "Dĺžka objednaného kusu",
    "773": "Prosím, vložte dĺžku objednaného kusu",
    "774": "Číslo pozície od",
    "775": "Prosím, vložte číslo pozície od",
    "776": "Číslo pozície dĺžka",
    "777": "Vložte prosím dĺžku čísla pozície",
    "778": "Pozícia kus od",
    "779": "Prosím, vložte pozíciu kus od",
    "780": "Dĺžka polohového dielu",
    "781": "Prosím, vložte dĺžku polohového kusu",
    "782": "Úroveň zabezpečenia",
    "783": "Znak výplne čiarového kódu",
    "784": "Prosím, vložte znak výplne čiarového kódu",
    "785": "Späť na projekty",
    "786": "(len na čítanie)",
    "787": "obmedzený prístup",
    "788": "prístup len na čítanie",
    "789": "vlastná vstupná stránka",
    "790": "Vložte reťazec pripojenia",
    "791": "Reťazec pripojenia k DB",
    "792": "Reťazec pripojenia k DB STD",
    "793": "Reťazec pripojenia DB BWG",
    "794": "Pripojenie k databáze",
    "795": "Chat",
    "796": "Správy",
    "797": "CE dokument",
    "798": "Objednávkový dokument",
    "799": "Posledná oprava",
    "800": "Ukončená preberacia skúška",
    "801": "K dispozícii je nová aktualizácia, reštartujte aplikáciu",
    "802": "Externý odkaz",
    "803": "Predpona externého odkazu",
    "804": "Odkaz na projekt",
    "805": "Interval úlohy",
    "806": "Používatelia prístrojovej dosky",
    "807": "Používatelia aplikácie",
    "808": "Posledné odoslanie",
    "809": "minút",
    "810": "Verzia porovnávača",
    "811": "Verzia importéra",
    "812": "Odhlásenie používateľa",
    "813": "Používateľ sa úspešne odhlásil",
    "814": "Čiarový kód zmenený",
    "815": "Aktualizované informácie",
    "816": "Pokyny",
    "817": "Nainštalujte APP",
    "818": "Nainštalujte aplikáciu Window.ID APP naskenovaním kódu QR",
    "819": "zodpovedajúce",
    "820": "nezodpovedá",
    "821": "Plánovač",
    "822": "Počet dní",
    "823": "Nastavenia pre pripomienku údržby",
    "824": "days",
    "825": "Nasledujúca údržba",
    "826": "Upraviť obrázok",
    "827": "Bez filtra",
    "828": "Pridať objednávku",
    "829": "Nová objednávka",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
};

export default sk;