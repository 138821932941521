export const INITIALISE_STATE = "INITIALISE_STATE";

//Service worker
export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';
export const SW_UPDATE_FINISHED = 'SW_UPDATE_FINISHED';

//Install app
export const INSTALL_APP = 'INSTALL_APP';
export const SHOW_INSTALL_PROPMPT = 'SHOW_INSTALL_PROPMPT';
export const LOGGED_IN_AS = "LOGGED_IN_AS";

//User
export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';

//Tasks
export const UPDATE_CURRENT_AUFTRAG = "UPDATE_CURRENT_AUFTRAG";
export const UPDATE_CURRENT_BARCODE = "UPDATE_CURRENT_BARCODE";
export const OPEN_FILTERS = "OPEN_FILTERS";
export const FOTO_ADD = "FOTO_ADD";
export const FOTO_REMOVE = "FOTO_REMOVE";
export const UPDATE_PDF = "UPDATE_PDF";
export const UPDATE_MONTAGE_ORT_FILTER = "UPDATE_MONTAGE_ORT_FILTER";
export const UPDATE_SELECTED_POSITION_ID = "UPDATE_SELECTED_POSITION_ID";
export const SAVE_ABNAHME_POSITION_INFO = "SAVE_ABNAHME_POSITION_INFO";
export const UPDATE_SELECTED_ELEMENT_ID = "UPDATE_SELECTED_ELEMENT_ID";
export const SAVE_SIGNATURE_IMAGE = "SAVE_SIGNATURE_IMAGE";
export const DISABLE_PDF = "DISABLE_PDF";
export const REMOVE_FOTOS = "REMOVE_FOTOS";
export const UPDATE_PDF_POSITION = "UPDATE_PDF_POSITION";
export const UPDATE_POSITION_ELEMENT_MONTAGE_DATUM = "UPDATE_POSITION_ELEMENT_MONTAGE_DATUM";
export const UPDATE_REPAIR_PDF = "UPDATE_REPAIR_PDF";
export const UPDATE_REPAIR_PDF_POSITION = "UPDATE_REPAIR_PDF_POSITION";
export const UPDATE_MAINTENANCE_PDF = "UPDATE_MAINTENANCE_PDF";
export const UPDATE_MAINTENANCE_PDF_POSITION = "UPDATE_MAINTENANCE_PDF_POSITION";
export const UPDATE_REPAIR_DATA = "UPDATE_REPAIR_DATA";
export const UPDATE_MAINTENANCE_DATA = "UPDATE_MAINTENANCE_DATA";
export const UPDATE_LAST_SCANNED_BARCODE = "UPDATE_LAST_SCANNED_BARCODE";
export const UPDATE_DETAILS_ORIGIN = "UPDATE_DETAILS_ORIGIN";

//Projectlist filters
export const UPDATE_SELECTED_DATE_FROM = "UPDATE_SELECTED_DATE_FROM";
export const UPDATE_SELECTED_DATE_TO = "UPDATE_SELECTED_DATE_TO";
export const UPDATE_SEARCH_FILTER = "UPDATE_SEARCH_FILTER";
export const UPDATE_OFFEN_FILTER = "UPDATE_OFFEN_FILTER";
export const UPDATE_PAGE_COUNT = "UPDATE_PAGE_COUNT";
export const UPDATE_PAGE_INDEX = "UPDATE_PAGE_INDEX";

//Racklist filters

export const UPDATE_RACK_SEARCH_FILTER = "UPDATE_RACK_SEARCH_FILTER";
export const UPDATE_RACK_OFFEN_FILTER = "UPDATE_RACK_OFFEN_FILTER";
export const UPDATE_RACK_PAGE_COUNT = "UPDATE_RACK_PAGE_COUNT";
export const UPDATE_RACK_PAGE_INDEX = "UPDATE_RACK_PAGE_INDEX";
export const UPDATE_CURRENT_RACK = "UPDATE_CURRENT_RACK";
export const UPDATE_CURRENT_RACK_BARCODE = "UPDATE_CURRENT_RACK_BARCODE";
export const UPDATE_RACK_POSITION_ELEMENTS = "UPDATE_RACK_POSITION_ELEMENTS";

//Geolocation
export const UPDATE_USER_GEOLOCATION = "UPDATE_USER_GEOLOCATION";

//Settings
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LAST_USED_CAMERA_INDEX = "SET_LAST_USED_CAMERA_INDEX";