import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const initialState={};

const middleware = [thunk];

function configureStore(){
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    return createStore(
        persistedReducer,
        initialState, 
        composeWithDevTools(
            applyMiddleware(...middleware)),      
)};

export default configureStore;