import React from 'react';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Text } from '../extensions/LanguageContainer';

const Notifications = (type, message) => {
    switch (type) {
        case 'info':
          NotificationManager.info(<Text tid="525" />, message);
          break;
        case 'success':
          NotificationManager.success(<Text tid="524" />, message);
          break;
        case 'warning':
          NotificationManager.warning(<Text tid="311" />, message);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
        default:
            NotificationManager.info('Info message', message);
            break;

      }    
}
export default Notifications;

