const cs = {
    "1": "Ano",
    "2": "Ne",
    "3": "30denní testovací verze",
    "4": "Prostřednictvím aplikace byla nahlášena stížnost",
    "5": "Přijetí",
    "6": "Datum přijetí",
    "7": "Akce",
    "8": "Aktivní",
    "9": "Činnosti",
    "10": "Přidat",
    "11": "Přidat instalační program",
    "12": "Přidat společnost",
    "13": "Přidat prodejce",
    "14": "Přidat výrobce",
    "15": "Přidat správu nemovitostí",
    "16": "Přidat uživatele",
    "17": "Další pole",
    "18": "Úprava názvu doplňkového pole",
    "19": "Komentáře",
    "20": "Doplňující informace",
    "21": "Další produkty",
    "22": "Výměna hardwaru",
    "23": "Admin",
    "24": "Administrativní zobrazení",
    "25": "Byly všechny prvky dodány na místo?",
    "26": "Všechny pozice jsou nainstalovány",
    "27": "Všechna práva vyhrazena.",
    "28": "Opravdu chcete odstranit tohoto výrobce?",
    "29": "Jste si jisti, že chcete odstranit tohoto uživatele?",
    "30": "Nainstalováno",
    "31": "Instalatér",
    "32": "Instalatéři",
    "33": "Montáž",
    "34": "Instalace dokončena",
    "35": "Datum instalace",
    "36": "Přiřazení",
    "37": "Pokyny pro péči",
    "38": "Údaje o certifikátu",
    "39": "Certifikát(y)",
    "40": "Výměna křídla ",
    "41": "Vyčistěte",
    "42": "Zavřít",
    "43": "Barva",
    "44": "Barva/povrchová úprava",
    "45": "Objednávka",
    "46": "Společnost",
    "47": "Podrobnosti o společnosti",
    "48": "Informace o společnosti",
    "49": "Směr kompasu",
    "50": "Stížnosti",
    "51": "Projekt dokončen",
    "52": "Dokončeno",
    "53": "Připojené okno",
    "54": "Čisté staveniště",
    "55": "Datum vytvoření",
    "56": "Koncový uživatel",
    "57": "Číslo zákazníka",
    "58": "Odkaz na prvek",
    "59": "Datum",
    "60": "Rozsah data",
    "61": "Prodejce",
    "62": "Vymazat společnost",
    "63": "Smazat výrobce",
    "64": "Smazat uživatele",
    "65": "Datum dodání",
    "66": "Dodáno bez poškození",
    "67": "Popis",
    "68": "Určené místo instalace",
    "69": "Zobrazení _MENU_ záznamů na stránku",
    "70": "Distribuce",
    "71": "Dokumenty",
    "72": "Východní",
    "73": "Upravit instalátor",
    "74": "Upravit společnost",
    "75": "Upravit výrobce",
    "76": "Upravit správu nemovitostí",
    "77": "Upravit uživatele",
    "78": "Šířka x výška prvku",
    "79": "Element s tímto id neexistuje",
    "80": "Prvky",
    "81": "Uložená data e-mailu.",
    "82": "Zadejte barvu",
    "83": "Zadejte provizi",
    "84": "Zadejte název společnosti",
    "85": "Zadejte e-mail",
    "86": "Zadejte prodloužení",
    "87": "Zadejte výšku",
    "88": "Zadejte IP adresu",
    "89": "Zadejte jméno",
    "90": "Zadejte číslo",
    "91": "Zadejte počet pozic",
    "92": "Zadejte heslo",
    "93": "Zadejte telefon",
    "94": "Zadejte telefonní číslo",
    "95": "Zadejte město",
    "96": "Zadejte množství",
    "97": "Zadejte ulici",
    "98": "Zadejte příjmení",
    "99": "Zadejte uživatelské jméno",
    "100": "Zadejte ID účtu",
    "101": "Zadejte webovou stránku",
    "102": "Zadejte šířku",
    "103": "Chyba při ukládání dat na server.",
    "104": "Chyba při ukládání dat e-mailu.",
    "105": "Oprava příslušenství",
    "106": "vypršela na",
    "107": "vyprší v",
    "108": "Prodloužení",
    "109": "Fabricator",
    "110": "Továrna",
    "111": "Filtr",
    "112": "filtrováno z _MAX_ celkových záznamů",
    "113": "První",
    "114": "Zrušit",
    "115": "Informace o hardwaru",
    "116": "Obrazovky Fly",
    "117": "Footprint",
    "118": "Komentáře",
    "119": "Z aplikace",
    "120": "Z pohledu správy",
    "121": "Čistič skla",
    "122": "Výměna skla",
    "123": "Struktura skla",
    "124": "Šířka x výška skla",
    "125": "Údržba hardwaru",
    "126": "Výměna kování",
    "127": "Výška",
    "128": "Jak čistit",
    "129": "Obrázek",
    "130": "Informace o prvku",
    "131": "Informace jsou omezené",
    "132": "Vstup",
    "134": "Místo instalace",
    "135": "zadejte čárový kód",
    "136": "IP adresa",
    "137": "je vyžadována",
    "138": "Jazyk",
    "139": "Poslední",
    "140": "Poslední údržba",
    "141": "Poslední reklamace",
    "142": "Poslední servis",
    "143": "Odkaz na článek",
    "144": "Načítání",
    "145": "Všechny prvky podle specifikace?",
    "146": "Odhlásit se",
    "147": "E-mail - Přístup k datům",
    "148": "Údržba",
    "149": "Změněno",
    "150": "Vyrobeno",
    "151": "Konstrukce hlavního regulátoru",
    "152": "Číslo materiálu hlavního regulátoru",
    "153": "Sériové číslo hlavního ovladače",
    "154": "Datum shody",
    "155": "Viditelnost nabídky",
    "156": "Automatické potvrzení čárového kódu",
    "157": "Další informace o udržitelnosti REHAU",
    "158": "Nové zkušební období",
    "159": "Další",
    "160": "Žádné poškození prvku",
    "161": "Žádné poškození skla",
    "162": "Nebyly nalezeny žádné odpovídající záznamy",
    "163": "Žádný z prvků není nainstalován",
    "164": "Severní",
    "165": "Není nainstalován",
    "166": "Nic nenalezeno - omlouváme se",
    "167": "Číslo",
    "168": "Počet prvků",
    "169": "Přehled projektů",
    "170": "Počet pozic",
    "171": "na",
    "172": "Datum objednávky",
    "173": "Číslo projektu",
    "174": "Průběh projektu",
    "175": "Výstup",
    "176": "Heslo",
    "177": "Telefon",
    "178": "Město",
    "179": "Čistič PVC",
    "180": "Element Doc.",
    "181": "Position Podrobnosti o prvku",
    "182": "Pozice s tímto id neexistuje",
    "183": "Pozice",
    "184": "Předchozí",
    "185": "Pověření primárního uživatele",
    "186": "Tisk přihlašovacích údajů",
    "187": "Zpracování",
    "188": "Fabricator",
    "189": "Systém profilů",
    "190": "Projekt",
    "191": "Číslo projektu",
    "192": "Správa nemovitostí",
    "193": "Množství",
    "194": "Stížnost",
    "195": "Vyberte prosím stanici",
    "196": "Recyklovaný obsah",
    "197": "Oprava",
    "198": "Příslušenství pro opravy",
    "199": "Oprava povrchu",
    "200": "Nahlášeno z aplikace",
    "201": "Hlášeno z pohledu správy",
    "202": "Obnovení zkušebního období",
    "203": "Výsledek",
    "204": "Role",
    "205": "Uložit",
    "206": "Úspěšně uloženo.",
    "207": "Čistý povrch",
    "208": "Vyhledávání",
    "209": "Úroveň zabezpečení",
    "210": "Odeslání dat do REHAU",
    "211": "Servis",
    "212": "Servis a prodej",
    "213": "Poskytovatel služeb",
    "214": "Nastavení",
    "215": "Pokud by mělo dojít k ručnímu celkovému převzetí nebo údržbě",
    "216": "Zobrazení _začátku_ až _konce_ _celkových_ záznamů",
    "217": "Zobrazení 0 až 0 z 0 záznamů",
    "218": "Žaluzie",
    "219": "Přihlášení",
    "220": "Přihlaste se pro zahájení relace",
    "221": "Informace o chytrém skle",
    "222": "Chytrá okna",
    "223": "Některé prvky jsou nainstalovány",
    "224": "Některé prvky nejsou sladěny",
    "225": "Některé pozice je třeba nainstalovat",
    "226": "Jižní",
    "227": "Statistika",
    "228": "Ulice",
    "229": "Příjmení",
    "230": "Udržitelnost a energie",
    "231": "Telefon",
    "232": "Testovací období úspěšně resetováno",
    "233": "zkušební období (období)",
    "234": "Oprava dokončena",
    "235": "Existují stávající stížnosti",
    "236": "Neexistuje žádná společnost s tímto ID",
    "237": "O tomto prvku nejsou žádné informace",
    "238": "Tato společnost již byla použita",
    "239": "Název",
    "240": "na",
    "241": "Celková akceptace",
    "242": "Celkem",
    "243": "Neznámý",
    "244": "Nahrát logo",
    "245": "Správa uživatelů",
    "246": "Uživatelské jméno",
    "247": "Uživatelské jméno je již obsazeno.",
    "248": "Uživatelé",
    "249": "Hodnota U",
    "250": "Hodnota U (rámeček)",
    "251": "Hodnota U (sklo)",
    "252": "Přidělené W.ID",
    "253": "Upozornění, aby nedošlo k poškození ID okna",
    "254": "Vítejte",
    "255": "West",
    "256": "Šířka",
    "257": "Celková velikost prvku (mm)",
    "258": "Plocha okna od",
    "259": "Plocha okna v",
    "260": "Okno Číslo elektronického materiálu",
    "261": "Okno Elektronické sériové číslo",
    "262": "Místo instalace okna",
    "263": "Systém oken",
    "264": "Typ okna",
    "265": "Výměna křídla ",
    "266": "Jste si jisti?",
    "267": "Vyčistěte filtr",
    "268": "Upravit",
    "269": "Export CSV",
    "270": "Čtečka čárových kódů",
    "271": "ID skla",
    "272": "Informace",
    "273": "Odkaz",
    "274": "Propojení uživatelé",
    "275": "E-mail",
    "276": "Nastavení e-mailu",
    "277": "E-mail - zkušební období",
    "278": "Název služby",
    "279": "Společnost poskytující službu",
    "280": "Telefon služby",
    "281": "Servisní e-mail",
    "282": "Stav",
    "283": "Nastavení stanice",
    "284": "Předmět",
    "285": "Tel.",
    "286": "Uživatel",
    "287": "Webové stránky",
    "288": "ID okna",
    "289": "Systém oken",
    "290": "Údaje o koncovém uživateli",
    "291": "Jméno",
    "292": "Struktura skla",
    "293": "Údaje o hardwaru",
    "294": "Název montážní firmy",
    "295": "Montážní firma",
    "296": "Telefon instalatéra",
    "297": "E-mail instalatéra",
    "298": "URL",
    "299": "Země",
    "300": "Přidejte prosím uživatele pro tuto společnost",
    "301": "Další nabídka",
    "302": "Přístrojová deska",
    "303": "Aplikace",
    "304": "Návrh vstupní stránky",
    "305": "Správa uživatelů",
    "306": "Potvrzení",
    "307": "Tělo",
    "308": "Příloha",
    "309": "Id",
    "310": "Pozice",
    "311": "Varování",
    "312": "W.ID",
    "314": "Prodejci",
    "315": "Aktivovat",
    "316": "Deaktivovat",
    "317": "společnost",
    "318": "Omlouváme se",
    "319": "Správce",
    "320": "uživatel",
    "321": "Poštovní směrovací číslo",
    "322": "Logo",
    "323": "Tapety",
    "324": "ID účtu",
    "325": "Dokumenty CE",
    "326": "Nastavení aplikace",
    "327": "Automatické",
    "328": "Ruční",
    "329": "Procházet",
    "330": "Nahrát",
    "331": "Automatické nahrávání dokumentů",
    "332": "Zdrojová složka",
    "333": "Vyberte cestu ke složce pro automatické nahrávání dokumentů.",
    "334": "Vyberte prosím soubor .zip obsahující soubor PPS XML",
    "335": "Stanice",
    "336": "Ruční skenování",
    "337": "Stanice Smart Glass",
    "338": "Přenos informací",
    "339": "Maximální počet pásek na časové ose",
    "340": "QR-kód z",
    "341": "QR-kód do",
    "342": "Předpona QR-kódu",
    "343": "Délka QR-kódu",
    "344": "Data čárového kódu jsou neplatná.",
    "345": "Api klíč",
    "346": "zadejte qrcode",
    "347": "Název stanice",
    "348": "Soubor nebyl vybrán",
    "349": "Soubor nahrán",
    "350": "Chyba při nahrávání souboru",
    "351": "Je povoleno ruční skenování",
    "352": "Zadejte prosím qrcode",
    "353": "Čárový kód je neplatný",
    "354": "Tento čárový kód je importován",
    "356": "Žádná data",
    "357": "Spojení se nezdařilo",
    "358": "Historie",
    "359": "Čárový kód se používá.",
    "360": "Chyba při porovnávání čárového kódu",
    "361": "Ručně",
    "362": "Z adresy",
    "363": "Do",
    "364": "Délka prefixu QR-kódu musí být menší než délka QR-kódu",
    "365": "Rozsah čárového kódu je neplatný",
    "366": "Neplatná čísla",
    "367": "Použito dříve",
    "368": "Dnes používané",
    "369": "Zdarma",
    "370": "Použito do dnešního dne",
    "371": "Využití čísel QR kódů pro aktuální rozsah stanic",
    "372": "Překlady",
    "373": "Upravit překlad",
    "374": "Klíč",
    "375": "Angličtina",
    "376": "Hodnota",
    "377": "ID k přiřazení",
    "378": "Pozice oken a dveří v projektu",
    "379": "Servis oprav / doklad o opravě",
    "380": "Údržba a historie",
    "381": "Zpráva o instalaci",
    "382": "Úpravy",
    "383": "Výměna skla",
    "384": "Výměna křídla",
    "386": "Komentáře",
    "387": "Oprava dokončena",
    "388": "Nové prvky",
    "389": "Protokol o údržbě",
    "390": "Údržba hardwaru",
    "391": "Sjednání termínu údržby",
    "392": "Úplný seznam projektů",
    "393": "Informace o oknech",
    "394": "Podrobnosti o instalaci",
    "395": "Barva",
    "396": "Šířka x výška prvku v mm",
    "397": "Šířka x výška skla v mm",
    "398": "Informace o montáži",
    "399": "Jméno montážní firmy",
    "400": "Datum / čas instalace",
    "401": "Místo instalace (GPS)",
    "402": "ID zařízení JustSmart",
    "403": "odeslat",
    "404": "Poloha filtru",
    "405": "Žádné",
    "406": "Balkonové dveře",
    "407": "Kuchyně",
    "408": "Koupelna",
    "409": "Pokoj",
    "410": "Střecha",
    "411": "Instalace",
    "412": "Pozice",
    "413": "Přiložte obrázek",
    "414": "Podpis",
    "415": "Výkres",
    "416": "Okno není poškozeno",
    "417": "Sklo bez poškození",
    "418": "Otevřená kamera",
    "419": "Nahrát",
    "420": "Instalace a přiřazení okna",
    "421": "Protokol o převzetí",
    "422": "Odeslat",
    "423": "Celý stavební projekt",
    "424": "Typ montáže",
    "425": "Explicitní pro prvek",
    "426": "Celá stavba dokončena",
    "427": "Zpráva o montáži pro jednotlivé prvky",
    "428": "Problém existuje",
    "429": "Požádat o pomoc",
    "430": "Zpráva",
    "431": "Odeslat",
    "432": "Další prvek",
    "433": "Úplný seznam prvků",
    "434": "Plán výstavby",
    "435": "prostřednictvím čísla zakázky",
    "436": "prostřednictvím čtečky QR kódů",
    "437": "zobrazit jednotlivě",
    "438": "nebo",
    "439": "Číslo čárového kódu",
    "440": "Zobrazit informace",
    "441": "Oprava/reklamace",
    "442": "Zobrazit kontaktní osobu",
    "443": "Zpětná vazba",
    "444": "Naskenujte další QR kód",
    "445": "Příští termín údržby",
    "446": "Dodavatel oken",
    "447": "Vlastnosti",
    "448": "Hodnota zvukové izolace",
    "449": "Uf",
    "450": "Řešení modernizace",
    "451": "Kontakt",
    "452": "ID vašeho okna",
    "453": "Co chcete udělat ?",
    "454": "Pravidelná údržba oken/dveří prodlužuje životnost a komfort",
    "455": "Požádejte o termín údržby prostřednictvím kontaktního formuláře",
    "456": "Požádejte o termín údržby telefonicky",
    "457": "svému okenářskému mistrovi",
    "458": "Tímto potvrzuji obchodní podmínky a nařízení GDPR",
    "459": "Váš zájem",
    "461": "Kontakt přes ...",
    "462": "Rozbité sklo",
    "463": "Okno se nezavírá správně",
    "464": "Okno se zaseklo",
    "465": "Poškozené okno",
    "466": "Další příslušenství",
    "467": "Vaše okna a dveře byly zapnuté",
    "468": "montovány touto společností",
    "469": "Stavitel oken",
    "470": "Ohodnoťte nyní svá okna a řemeslnickou firmu",
    "471": "Jak jste spokojeni se službami firmy",
    "472": "Jsou dodané výrobky v pořádku ?",
    "473": "Jak se vám líbí vaše okna ?",
    "474": "Vyskytly se nějaké problémy se zpracováním ?",
    "475": "S čím jste byli spokojeni?",
    "476": "Co byste zlepšili ?",
    "477": "Návod k montáži",
    "478": "Umístění oken a dveří",
    "479": "Otevřete protokol o montáži",
    "480": "Kompletní seznam prvků projektu",
    "481": "Seznam prvků prostřednictvím čísla objednávky / QR",
    "482": "Odeslání projektového protokolu",
    "483": "Údaje o oknech",
    "484": "Jednotlivé detaily",
    "485": "Číslo stavebního projektu",
    "486": "Okno",
    "487": "Poloha okna",
    "488": "Služba opravy",
    "489": "Údaje o údržbě",
    "490": "Zákazník",
    "491": "Kontaktní formulář",
    "492": "Najděte nyní řemeslníka REHAU ve svém okolí",
    "493": "Kategorie",
    "494": "QR skenování",
    "495": "Kontaktní osoba",
    "496": "aktualizovat",
    "497": "filtry",
    "498": "Instalace dokončena",
    "499": "Instalace dokončena?",
    "500": "Dodáno bez poškození",
    "501": "Uložit jako PDF",
    "502": "Pořiďte fotografii",
    "503": "Není platná e-mailová adresa",
    "504": "Nainstalovat aplikaci",
    "505": "Umístěte",
    "506": "Seznam projektů",
    "507": "Načíst vybranou zakázku ?",
    "508": "Nesprávné číslo objednávky/čárový kód",
    "509": "Jednotlivé okno",
    "510": "Zadat změny",
    "511": "Odeslat zprávu e-mailem",
    "512": "Vyberte prosím 4 možnosti",
    "513": "Nyní naskenujte QR kód a zjistěte, kde má být prvek nainstalován.",
    "514": "Uložit soubor",
    "515": "Zobrazit",
    "516": "výsledky",
    "517": "stránka",
    "518": "z",
    "519": "Datum od",
    "520": "Datum do",
    "521": "otevřít",
    "522": "Velikost",
    "523": "Nainstalováno na",
    "524": "Úspěch",
    "525": "Informace",
    "526": "Načtena nová objednávka",
    "527": "ID instalačního programu",
    "528": "Přihlášení",
    "529": "Tímto potvrzuji všeobecné obchodní podmínky a nařízení GDPR.",
    "530": "Odhlášení",
    "531": "skener",
    "532": "Odeslání zprávy",
    "533": "Má být na ovládací panel odeslán protokol o přijetí všech položek této objednávky?",
    "534": "Protokol o opravě",
    "535": "možnost 5",
    "536": "možnost 6",
    "537": "možnost 7",
    "538": "možnost 8",
    "539": "možnost 9",
    "540": "možnost 10",
    "541": "Odeslaný e-mail.",
    "542": "Chyba při odesílání e-mailu!",
    "543": "Zamlžování maziva",
    "544": "Upraveno",
    "545": "Vyčištěno",
    "546": "Utěsněno",
    "547": "Vyměněn hardware",
    "548": "Komentář:",
    "549": "Všechny prvky jsou dodány na staveniště",
    "550": "Umístění prvků v rámci stavebního projektu",
    "551": "Chyba při odesílání hlášení!",
    "552": "Uložit hlášení",
    "553": "Informace uloženy.",
    "554": "Nepodařilo se uložit data.",
    "555": "Informace o inteligentním okně",
    "556": "Přijetí inteligentního okna",
    "557": "Číslo materiálu elektroniky okna",
    "558": "Sériové číslo elektroniky okna",
    "559": "Naskenujte QR kód (DMC) z hlavní řídicí jednotky nebo jej zadejte ručně.",
    "560": "Plně nakonfigurované a funkční okno Smart Window",
    "561": "Všechny viditelné prvky bez poškození / závad",
    "562": "Byl připojen spínací systém 0-10V (0-100 %)?",
    "563": "Místo instalace budova",
    "564": "Spuštění skenování",
    "565": "Zastavit skenování",
    "566": "Vybraný prvek",
    "567": "Současně lze vybrat maximálně 4 atributy.",
    "568": "Poloha klienta",
    "569": "Datum výroby",
    "570": "Změna Window.ID",
    "571": "Předchozí Window.ID",
    "572": "Nové okno.ID",
    "573": "Číslo Auftrag neexistuje nebo je nové číslo Auftrag již používáno.",
    "574": "Window.ID",
    "575": "ID pozice",
    "576": "Typ",
    "577": "ID prvku",
    "578": "Informace o prvku",
    "579": "Window.ID",
    "580": "Podepište se prosím na vyhrazeném místě",
    "581": "Dokončeno",
    "582": "Podepsat",
    "583": "Nový jazyk",
    "584": "Původní jazyk",
    "585": "Název jazyka",
    "586": "Vytvořit nový jazyk",
    "587": "Přidat jazyk",
    "588": "Aktualizace",
    "589": "probíhá aktualizace",
    "590": "Deaktivace společnosti",
    "591": "Aktivace společnosti",
    "592": "Opravdu chcete tuto společnost deaktivovat?",
    "593": "Jste si jisti, že chcete tuto společnost aktivovat?",
    "594": "Deaktivace uživatele",
    "595": "Aktivace uživatele",
    "596": "Určitě chcete tohoto uživatele deaktivovat?",
    "597": "Jste si jisti, že chcete tohoto uživatele aktivovat?",
    "598": "ID Pozice",
    "599": "Všechny",
    "600": "Import/export",
    "601": "Pro import vyberte jazykový soubor .csv",
    "602": "Import",
    "603": "Export",
    "604": "Soubor",
    "605": "Překladatel",
    "606": "Soubory čárových kódů",
    "607": "Zvolte cestu ke složce pro soubory čárových kódů.",
    "608": "Window_ID",
    "609": "Systém profilů",
    "610": "Pozice zákazníka",
    "611": "Barva",
    "612": "Velikost prvku",
    "613": "Velikost skla",
    "614": "Struktura skla",
    "615": "Informace o hardwaru",
    "616": "Datum shody",
    "617": " Datum/čas instalace",
    "618": "Údaje GPS o místě instalace",
    "619": "Nr:",
    "620": "Instalace: Číslo instalace",
    "621": "Datum instalace: Podlaha:",
    "622": "Poloha: Instalační číslo: Instalační číslo: Instalační číslo: Poz:",
    "623": "Instalace: Ignorovat počáteční znak(y)",
    "624": "Ignorovat koncové znaky",
    "625": "E-mail - aktivace uživatele",
    "626": "Aktivace společností",
    "627": "Vypršení zkušební doby",
    "628": "Opětovné odeslání aktivačního e-mailu",
    "629": "Dealer/Factory již existuje",
    "630": "Uživatel je aktivován",
    "631": "Přejděte na přihlašovací stránku",
    "632": "Údaje o uživateli",
    "633": "Přidat propojeného uživatele",
    "634": "Smazat propojení uživatele",
    "635": "Uživatel je již propojen",
    "636": "Uživatelské jméno nebo heslo je prázdné",
    "637": "Uživatel nebyl nalezen",
    "638": "Odeslat protokol vedení",
    "639": "Sestava vyplnila otázku",
    "640": "Možnost 5",
    "641": "Možnost 6",
    "642": "Možnost 7",
    "643": "Možnost 8",
    "644": "Možnost 9",
    "645": "Možnost 10",
    "646": "Instalatér",
    "647": "Typ",
    "648": "Datum",
    "649": "Popis",
    "650": "Údržba hardwaru",
    "651": "Nastavení",
    "652": "Vyčistit",
    "653": "Těsnění",
    "654": "Náhradní příslušenství",
    "655": "Komentář:",
    "656": "Konektor",
    "657": "okenní systém",
    "658": "kus",
    "659": "Číslo projektu",
    "660": "ID zakázky",
    "661": "výška",
    "662": "šířka",
    "663": "Informace o hardwaru",
    "664": "Šířka x výška skla",
    "665": "glassmakeup",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "ID skla",
    "669": "Podmínky",
    "670": "Přidat/odebrat",
    "671": "A/nebo",
    "672": "Skupina",
    "673": "Sloupec",
    "674": "Podmínka",
    "675": "Zadejte číslo zákazníka",
    "676": "Překladatel",
    "678": "Kontrola verze aplikace",
    "679": "Dokončení aktualizace může trvat několik minut",
    "680": "Čeká se na novou verzi",
    "681": "Je k dispozici nová verze aplikace, jste si jisti, že chcete provést aktualizaci?",
    "682": "Aplikace je aktuální, chcete obnovit starou verzi?",
    "683": "Obnovit starou verzi",
    "684": "Vložte název stanice",
    "685": "Objednací číslo znakové výplně",
    "686": "Vložte prosím znakovou výplň čísla objednávky",
    "687": "Maximální délka čísla objednávky",
    "688": "Vložte prosím maximální délku čísla objednávky",
    "689": "Cesta ke složce MDB",
    "690": "Vložte prosím cestu k souboru .mdb (používá ji importér při vyhledávání souborů .mdb).",
    "691": "Cesta ke složce XML",
    "692": "Vložte prosím cestu k souboru .xml (používá importér při vyhledávání souborů .xml).",
    "693": "Ignorovat počáteční znak(y) QR-kódu",
    "694": "Ignorovat koncové znaky QR-kódu",
    "695": "Označení",
    "696": "Délka",
    "697": "Další pozice",
    "698": "Chytré okno bez poškození",
    "699": "Chytré okno připojené",
    "700": "Orientace okna",
    "701": "Číslo polohovacího prvku",
    "702": "Inteligentní okno nakonfigurováno",
    "703": "Prvek bez poškození",
    "704": "Číslo materiálu",
    "705": "Sériové číslo",
    "706": "Není k dispozici žádný obrázek",
    "707": "Čistota staveniště",
    "708": "Přetáhněte sem soubory obrázků/pdf",
    "709": "Nahrání souboru",
    "710": "Typ souboru",
    "711": "Objednávka (všechny pozice)",
    "712": "Odstraněno",
    "713": "Poznámky k vydání",
    "714": "Výška (mm)",
    "715": "Šířka (mm)",
    "716": "Pro tuto pozici není k dispozici žádný panel",
    "717": "Položka",
    "718": "Hromadná stanice",
    "719": "Rozsah hromadných dodávek",
    "720": "ID okna Poslední",
    "721": "Bylo vyměněno sklo",
    "722": "Bylo opraveno příslušenství",
    "723": "Bylo vyměněno křídlo",
    "724": "Povrch byl opraven",
    "725": "Bylo opraveno příslušenství",
    "726": "Oprava je dokončena",
    "727": "Potvrzení hesla",
    "728": "Špatné heslo",
    "729": "Hesla se neshodují",
    "730": "Heslo nemůže být prázdné",
    "731": "locatton",
    "732": "Časová osa",
    "733": "UW_Wert",
    "734": "Speciální sklo",
    "735": "Pro tuto pozici není k dispozici žádné pole",
    "736": "Shodné prvky",
    "737": "Poslední odpovídající prvek",
    "738": "Namontované prvky",
    "739": "Poslední namontovaný prvek",
    "740": "Vymazaná stanice",
    "741": "Určitě chcete odstranit stanici",
    "742": "Přidat novou stanici",
    "743": "Smazat objednávku",
    "744": "Odstranění zakázky",
    "745": "Opravdu chcete smazat objednávku?",
    "746": "Uživatel přidal",
    "747": "Uživatel upravil",
    "748": "Zapomněl jste heslo?",
    "749": "Pole Heslo je povinné",
    "750": "Pole Uživatelské jméno je povinné",
    "751": "Tato uživatelská role není oprávněna k přihlášení k ovládacímu panelu",
    "752": "Uživatel není aktivní",
    "753": "Uživatelská společnost není aktivní nebo skončilo testovací období",
    "754": "Uživatelské jméno nebo heslo je nesprávné",
    "755": "Poštovní směrovací číslo",
    "756": "Pomocí rolovacího kolečka můžete obrázek zvětšovat a zmenšovat.",
    "757": "Obnovení hesla",
    "758": "Byl odeslán e-mail pro obnovení hesla",
    "759": "Heslo bylo resetováno",
    "760": "E-mail - Obnovení hesla",
    "761": "Číslo prvku",
    "762": "Číslo objednávky od",
    "763": "Vložte prosím číslo objednávky od",
    "764": "Délka čísla objednávky",
    "765": "Vložte prosím délku čísla objednávky",
    "766": "Rozsah objednávky od",
    "767": "Vložte prosím rozsah objednávky od",
    "768": "Délka rozsahu objednávky",
    "769": "Vložte prosím délku rozsahu objednávky",
    "770": "Please insert order piece from",
    "772": "Délka objednaného kusu",
    "773": "Vložte prosím délku objednaného kusu",
    "774": "Číslo pozice od",
    "775": "Vložte prosím číslo pozice od",
    "776": "Číslo pozice délka",
    "777": "Vložte prosím délku čísla pozice",
    "778": "Umístění kusu z",
    "779": "Prosím, vložte pozici kusu od",
    "780": "Délka polohovacího kusu",
    "781": "Vložte prosím délku polohového kusu",
    "782": "Úroveň zabezpečení",
    "783": "Znak výplně čárového kódu",
    "784": "Vložte prosím znak výplně čárového kódu",
    "785": "Zpět na projekty",
    "786": "(pouze pro čtení)",
    "787": "omezený přístup",
    "788": "přístup pouze pro čtení",
    "789": "vlastní vstupní stránka",
    "790": "Vložte prosím připojovací řetězec",
    "791": "Řetězec připojení k DB",
    "792": "Řetězec připojení k DB STD",
    "793": "Řetězec připojení DB BWG",
    "794": "Připojení k databázi",
    "795": "Chat",
    "796": "Zprávy",
    "797": "CE dokument",
    "798": "Dokument objednávky",
    "799": "Poslední oprava",
    "800": "Akceptace dokončena",
    "801": "New update vailable, please restart application",
    "802": "External link",
    "803": "External link prefix",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder.",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
};

export default cs;