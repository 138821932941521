const bs = {
    "1": "Da",
    "2": "Ne",
    "3": "Testna verzija - 30 dana.",
    "4": "Žalba je poslata putem aplikacije",
    "5": "Prihvaćeno",
    "6": "Dan prihvatanja",
    "7": "Akcija",
    "8": "Aktivan",
    "9": "Aktivnosti",
    "10": "Dodaj",
    "11": "Dodaj montažera",
    "12": "Dodaj kompaniju",
    "13": "Dodaj dilera",
    "14": "Dodaj proizvođača",
    "15": "Dodaj property menadžera",
    "16": "Dodaj korisnika",
    "17": "Dodatno polje",
    "18": "Izmjena polja \"dodatno ime\"",
    "19": "Komentari",
    "20": "Dodatne informacije",
    "21": "Dodatni proizvodi",
    "22": "Zamjena hardvera",
    "23": "Admin",
    "24": "Administrativni pregled",
    "25": "Svi elementi su dostavljeni na odredište?",
    "26": "Sve pozicije su instalirane",
    "27": "Sva prava zadržana",
    "28": "Da li ste sigurni da želite da obrišete ovog proizvođača?",
    "29": "Da li ste sigurni da želite da obrišete ovog korisnika?",
    "30": "Montirano",
    "31": "Montažer",
    "32": "Montažeri",
    "33": "Montaža",
    "34": "Montaža završena",
    "35": "Datum montaže",
    "36": "Zadaci",
    "37": "Uputstva za održavanje",
    "38": "Certificate details",
    "39": "Certifikat(i)",
    "40": "Čišćenje",
    "41": "Obriši",
    "42": "Zatvoriti",
    "43": "Boja",
    "44": "Boja/Dekor",
    "45": "Provizija",
    "46": "Kompanija",
    "47": "Detalji kompanije",
    "48": "Informacije o kompaniji",
    "49": "Smijer kompasa",
    "50": "Žalbe",
    "51": "Projekat završen",
    "52": "Završeno",
    "53": "Povezani prozor",
    "54": "Gradilište čisto",
    "55": "Datum kreiranja",
    "56": "Krajnji korisnik",
    "57": "Broj kupca",
    "58": "Referenca elementa",
    "59": "Datum",
    "60": "Raspon datuma",
    "61": "Diler",
    "62": "Obriši kompaniju",
    "63": "Obriši proizvođača",
    "64": "Obriši korisnika",
    "65": "Datum isporuke",
    "66": "Isporučeno bez oštećenja",
    "67": "Opis",
    "68": "Naznačeno mjesto montaže",
    "69": "Prikaz _MENU_ rezultata po stranici",
    "70": "Distribucija",
    "71": "Dokumenti",
    "72": "Istok",
    "73": "Izmijeni montažera",
    "74": "Izmijeni kompaniju",
    "75": "Izmijeni proizvođača",
    "76": "Izmijeni property menadžera",
    "77": "Izmijeni korisnika",
    "78": "Širina x visina elementa",
    "79": "Element sa ovim id-jem ne postoji",
    "80": "Elementi",
    "81": "Email sačuvan.",
    "82": "Unesi boju",
    "83": "Unesi proviziju",
    "84": "Unesi ime kompanije",
    "85": "Unesi email",
    "86": "Unesi ekstenziju",
    "87": "Unesi visinu",
    "88": "Unesi IP adresu",
    "89": "Unesi ime",
    "90": "Unesi broj",
    "91": "Unesi broj pozicija",
    "92": "Unesi lozinku",
    "93": "Unesi telefon",
    "94": "Unesi broj telefona",
    "95": "Unesi grad",
    "96": "Unesi količinu",
    "97": "Unesi ulicu",
    "98": "Unesi prezime",
    "99": "Unesi korisničko ime",
    "100": "Unesi poreski broj",
    "101": "Unesi website",
    "102": "Unesi širinu",
    "103": "Greška prilikom spašavanja podataka na serveru.",
    "104": "Greška prilikom spašavanja email adrese",
    "105": "Zamjena pribora",
    "106": "isteklo",
    "107": "ističe",
    "108": "Ekstenzija",
    "109": "Proizvođač",
    "110": "Fabrika",
    "111": "Filter",
    "112": "filtrirano od _MAX_ ukupnih zapisa",
    "113": "Prvi",
    "114": "Poništi",
    "115": "Informacije o hardveru",
    "116": "Komarnici",
    "117": "Otisak",
    "118": "Komentari",
    "119": "Iz aplikacije",
    "120": "Menadžement panel",
    "121": "Čistač stakla",
    "122": "Zamena stakla",
    "123": "Struktura stakla",
    "124": "Širina x visina stakla",
    "125": "Održavanje hardvera",
    "126": "Zamijena hardvera",
    "127": "Visina",
    "128": "Kako čistiti?",
    "129": "Slika",
    "130": "Informacije o elementu",
    "131": "Informacije su ograničene",
    "132": "Ulaz",
    "134": "Mjesto instalacije",
    "135": "unesi barkod",
    "136": "IP adresa",
    "137": "je obavezno",
    "138": "Jezik",
    "139": "Posljednji",
    "140": "Posljednje održavanje",
    "141": "Posljednja žalba",
    "142": "Posljednji servis",
    "143": "Link do artikla",
    "144": "Učitavanje",
    "145": "Svi elementi su po specifikaciji?",
    "146": "Odjavi se",
    "147": "Podaci za pristup email-u",
    "148": "Održavanje",
    "149": "Promjenio",
    "150": "Proizveo",
    "151": "Master controller dizajn",
    "152": "Master Controller broj artikla",
    "153": "Master Controller serijski broj",
    "154": "Datum podudaranja",
    "155": "Vidljivost menija",
    "156": "Automatska potvrda barkoda",
    "157": "Više informacija o REHAU održivosti",
    "158": "Novi testni period",
    "159": "Sljedeći",
    "160": "Element nije oštećen",
    "161": "Staklo nije oštećeno",
    "162": "Nije pronađen nijedan odgovarajući zapis",
    "163": "Nijedan element nije instaliran",
    "164": "Sjever",
    "165": "Nije sastavljeno",
    "166": "Žao nam je, nijedan zapis nije pronađen",
    "167": "Broj",
    "168": "Broj elemenata",
    "169": "Pregled projekta",
    "170": "Broj pozicija",
    "171": "uključeno",
    "172": "Datum narudžbe",
    "173": "Broj naloga",
    "174": "Status naloga",
    "175": "Izlaz",
    "176": "Šifra",
    "177": "Telefon",
    "178": "Grad",
    "179": "PVC čistač",
    "180": "Dokumenti pozicije",
    "181": "Detalji pozicije",
    "182": "Pozicija sa ovim id-jem ne postoji",
    "183": "Pozicije",
    "184": "Prethodni",
    "185": "Primarni korisnički akreditivi",
    "186": "Štampaj podatke o prijavi",
    "187": "Obrada",
    "188": "Proizvođač",
    "189": "Sistem profila",
    "190": "Projekat",
    "191": "Broj naloga",
    "192": "Upravljanje vlasništvom",
    "193": "Količina",
    "194": "Žalba",
    "195": "Odaberite stranicu",
    "196": "Obrisani sadržaj",
    "197": "Popravka",
    "198": "Popravka dodataka",
    "199": "Popravka površine",
    "200": "Prijavljeno iz aplikacije",
    "201": "Prijavljeno iz menadžment pregleda",
    "202": "Resetuj testni period",
    "203": "Rezultat",
    "204": "Uloge",
    "205": "Sačuvaj",
    "206": "Uspješno sačuvano",
    "207": "Zatvoriti",
    "208": "Pretraga",
    "209": "Nivo bezbjednosti",
    "210": "Podaci poslati REHAU",
    "211": "Servis",
    "212": "Servis i prodaja",
    "213": "Serviser",
    "214": "Podešavanja",
    "215": "Izvršiti ukupno ručno prihvatanje narudžbe?",
    "216": "Prikazuje se _START_ do _END_ od _TOTAL_ skupova podataka",
    "217": "Prikaz 0 do 0 od 0 zapisa",
    "218": "Roletne",
    "219": "Prijavite se",
    "220": "Prijavite se da biste započeli svoju sesiju",
    "221": "Informacije o Smart staklu",
    "222": "Smart Prozor",
    "223": "Neki elementi su instalirani",
    "224": "Neki elementi nisu odgovarajući",
    "225": "Potrebna instalacija neke pozicije(pozicija)",
    "226": "Jug",
    "227": "Statistika",
    "228": "Ulica",
    "229": "Prezime",
    "230": "Održivost i energija",
    "231": "Telefon",
    "232": "Restartovanje testnog perioda uspješno",
    "233": "test period(i)",
    "234": "Svojstva su zadržana",
    "235": "Postojeće žalbe",
    "236": "Ne postoji kompanija sa ovim id-jem",
    "237": "Ne postoje informacije o ovom elementu",
    "238": "Kompanija već postoji",
    "239": "Naziv",
    "240": "kome",
    "241": "Potpuno prihvatanje",
    "242": "Total",
    "243": "Nepoznato",
    "244": "Dodaj logo",
    "245": "Korisnički podaci",
    "246": "Korisničko ime",
    "247": "Korisničko ime već postoji",
    "248": "Korisnici",
    "249": "U value",
    "250": "Koeficijent toplotne provodljivosti okvira",
    "251": "Koeficijent toplotne provodljivosti stakla",
    "252": "W.ID dodeljen",
    "253": "Upozorenje: ne oštećuj Window ID",
    "254": "Dobrodošli",
    "255": "Zapad",
    "256": "Širina",
    "257": "Ukupne dimenzije elementa (mm)",
    "258": "Površina prozora od",
    "259": "Površina prozora do",
    "260": "Broj elektronike prozora",
    "261": "Serijski broj elektronike prozora",
    "262": "Lokacija za instalaciju prozora",
    "263": "Prozorski sistem",
    "264": "Tip prozora",
    "265": "Zamjena krila",
    "266": "Da li ste sigurni?",
    "267": "Poništi filter",
    "268": "Izmijeni",
    "269": "Izvezi CSV",
    "270": "Čitač barkoda",
    "271": "ID stakla",
    "272": "Informacije",
    "273": "Link",
    "274": "Povezani korisnici",
    "275": "Email",
    "276": "Podešavanja emaila",
    "277": "Pošalji testni period",
    "278": "Naziv servisnog ugovora",
    "279": "Servis kompanija",
    "280": "Telefon servisa",
    "281": "email servisa",
    "282": "Status",
    "283": "Station settings",
    "284": "Naslov",
    "285": "Tel.",
    "286": "Korisnik",
    "287": "Website",
    "288": "ID prozora",
    "289": "Prozorski sistem",
    "290": "Detalji krajnjeg korisnika",
    "291": "Naziv",
    "292": "Struktura stakla",
    "293": "Podaci o hardveru",
    "294": "Naziv montažera",
    "295": "Montažna kompanija",
    "296": "Telefon montažera",
    "297": "Email montažera",
    "298": "URL",
    "299": "Država",
    "300": "Dodaj korisnika za ovu kompaniju",
    "301": "Dodatni meni",
    "302": "Komandna tabla",
    "303": "Aplikacija",
    "304": "Dizajn početne stranice",
    "305": "Podešavanja korisnika",
    "306": "Potvrdi",
    "307": "Opis",
    "308": "Prilog",
    "309": "Id",
    "310": "Pozicija",
    "311": "Upozorenje",
    "312": "W.ID",
    "314": "Dileri",
    "315": "Aktiviraj",
    "316": "Deaktiviraj",
    "317": "Kompanija",
    "318": "Izvinite",
    "319": "Administrator",
    "320": "Korisnik",
    "321": "Poštanski broj",
    "322": "Logo",
    "323": "Pozadina",
    "324": "Broj kupca",
    "325": "CE certifikati",
    "326": "Aplikacijske postavke",
    "327": "Automatski",
    "328": "Uputstvo",
    "329": "Pregledaj",
    "330": "Učitavanje",
    "331": "Automatski upload dokumenata",
    "332": "Izvorni folder",
    "333": "Molimo Vas da odaberete putanju za automatski upload dokumenata.",
    "334": "Odaberite .zip fajl koji sadrži PPS XML fajl",
    "335": "Stanica",
    "336": "Ručno skeniranje",
    "337": "smart Glass stanica",
    "338": "Prenos informacija",
    "339": "Maksimalni broj traka u vremenskom periodu",
    "340": "QR kod od",
    "341": "QR kod do",
    "342": "Prefiks QR koda",
    "343": "Dužina QR koda",
    "344": "Podaci o bar kodu su neispravni.",
    "345": "Api ključ",
    "346": "Ubaci QR kod",
    "347": "Naziv stanice",
    "348": "Fajl nije selektovan",
    "349": "Fajl dodat",
    "350": "Greška prilikom dodavanja fajla",
    "351": "Ručno skeniranje je omogućeno",
    "352": "Molimo Vas da unesete QR kod",
    "353": "Nevažeći barkod",
    "354": "Barkod je dodan",
    "356": "Nema podataka",
    "357": "Veza nije uspostavljena",
    "358": "Istorija",
    "359": "Bar kod je u upotrebi.",
    "360": "Greška prilikom učitavanja bar koda",
    "361": "Uputstvo",
    "362": "Od",
    "363": "kome",
    "364": "Dužina prefiksa QR koda mora biti kraća od dužine QR koda",
    "365": "Opseg bar koda je nevažeći",
    "366": "Nevažeći brojevi",
    "367": "Prethodno korišteno",
    "368": "Korišteno danas",
    "369": "Slobodno",
    "370": "Korišteno do danas",
    "371": "Upotreba QR koda za trenutni opseg stanice.",
    "372": "Prevodi",
    "373": "Doradi prevod",
    "374": "Ključ",
    "375": "Engleski",
    "376": "Vrijednost",
    "377": "ID za dodjeljivanje",
    "378": "Pozicije prozora i vrata u građevinskom objektu",
    "379": "Servis za popravku / dokaz popravke",
    "380": "Održavanje i istorija",
    "381": "Assembly protocol",
    "382": "Modifikacija",
    "383": "Zamijeni staklo",
    "384": "Zamijeni krilo",
    "386": "Komentari",
    "387": "Tehničke osobine su zadrane",
    "388": "Nove osobine",
    "389": "Dnevnik održavanja",
    "390": "Održavanje okova",
    "391": "Zakaži termin za održavanje",
    "392": "Kompletna projektna lista",
    "393": "Informacije o prozoru",
    "394": "Detalji instalacije",
    "395": "Boja",
    "396": "Širina x visina elementa u mm",
    "397": "Širina x visina stakla u mm",
    "398": "Odgovarajuće informacije",
    "399": "Ime instalatera",
    "400": "Datum / Vrijeme instalacije",
    "401": "Lokacija instalacije (GPS)",
    "402": "ID Uređaja JustSmart",
    "403": "pošalji",
    "404": "Pozicija filtera",
    "405": "Nijedan",
    "406": "Balkonska vrata",
    "407": "Kuhinja",
    "408": "Kupatilo",
    "409": "Soba",
    "410": "Krov",
    "411": "Instalacija",
    "412": "Pozicija",
    "413": "Dodaj sliku",
    "414": "Potpis",
    "415": "Crtež",
    "416": "Prozor bez oštećenja",
    "417": "Staklo bez oštećenja",
    "418": "Otvori kameru",
    "419": "Dodaj sliku",
    "420": "Instaliraj i dodijeli prozor",
    "421": "Izveštaj o prijemu",
    "422": "Pošalji",
    "423": "Cijeli građevinski projekat",
    "424": "Vrsta montaže",
    "425": "Eksplicitno za element",
    "426": "Cijeli posao završen",
    "427": "Montažni protokoli pojedinačnih prozora",
    "428": "Postoji problem",
    "429": "Zatraži pomoć",
    "430": "Poruka",
    "431": "Pošalji",
    "432": "Sljedeći element",
    "433": "Lista svih elemenata",
    "434": "Raspored izgradnje",
    "435": "putem broja narudžbe",
    "436": "putem skeniranja QR koda",
    "437": "pokaži pojedinačno",
    "438": "ili",
    "439": "Barkod broj",
    "440": "Prikaži informacije",
    "441": "Popravka/žalba",
    "442": "Prikaži kontakt osobu",
    "443": "Povratne informacije",
    "444": "Skeniraj drugi QR kod",
    "445": "Sljedeće održavanje",
    "446": "Dobavljač prozora",
    "447": "Svojstva",
    "448": "Vrijednost zvučne izolacije",
    "449": "Uf",
    "450": "Opcija proširenja",
    "451": "Kontakt",
    "452": "Tvoj Window ID",
    "453": "Šta želite da radite?",
    "454": "Redovno održavanje prozora produžuje njegov životni vijek i komfor",
    "455": "Zatraži termin za održavanje putem kontakt forme",
    "456": "Zatraži termin za održavanje putem telefona",
    "457": "Vašem monteru prozora",
    "458": "Ovim potvrđujem odredbe i uslove i GDPR uredbu.",
    "459": "Tvoja briga",
    "461": "Kontaktiraj putem…",
    "462": "Slomljeno staklo",
    "463": "Prozor se ne zatvara kako treba",
    "464": "Prozor je zaglavljen",
    "465": "Oštećen prozor",
    "466": "Ostali dodaci",
    "467": "Tvoji prozori i vrata su otvoreni",
    "468": "sastavila ova kompanija",
    "469": "Proizvođač prozora",
    "470": "Ocijeni prozore i kompaniju koja ih je ugradila ",
    "471": "Kako ste zadovoljni sa uslugama kompanije?",
    "472": "Da li su isporučeni proizvodi u redu?",
    "473": "Da li Vam se dopadaju Vaši prozori?",
    "474": "Jesu li postojali su problemi u toku obrade",
    "475": "Sa čime ste zadovoljni?",
    "476": "Šta biste poboljšali?",
    "477": "Uputstvo za montažu",
    "478": "Pozicije prozora i vrata",
    "479": "Otvori izvještaj montaže",
    "480": "Lista svih elemenata projekta",
    "481": "Lista elemenata po broju narudžbe / QR",
    "482": "Pošalji dnevnik projekta",
    "483": "Detalji prozora",
    "484": "Pojedinačni detalji",
    "485": "Broj građevinskog projekta",
    "486": "Prozor",
    "487": "Pozicija prozora",
    "488": "Usluga popravke",
    "489": "Detalji održavanja",
    "490": "Kupac",
    "491": "Kontakt formular",
    "492": "Pronađi REHAU majstora u Vašoj blizini",
    "493": "Kategorija",
    "494": "QR skeniranje",
    "495": "Kontakt osoba",
    "496": "ažuriranje",
    "497": "filteri",
    "498": "Montaža završena",
    "499": "Montaža završena?",
    "500": "Isporuka bez oštećenja",
    "501": "Sačuvaj kao PDF",
    "502": "Uslikaj",
    "503": "Nevažeća email adresa",
    "504": "Instaliraj aplikaciju",
    "505": "Mjesto",
    "506": "Projekt lista",
    "507": "Učitaj selektovani redosljed?",
    "508": "Pogrešan broj narudžbe/barkod",
    "509": "Pojedinačni prozor",
    "510": "Unesi promene",
    "511": "Pošalji izveštaj putem emaila",
    "512": "Molimo Vas da selektujete 4 opcije",
    "513": "Sada skenirajte QR kod na prozoru da saznate na kojoj poziciji u zgradi treba da bude montiran.",
    "514": "Sačuvaj datoteku",
    "515": "Prikaži",
    "516": "rezultati",
    "517": "stranica",
    "518": "od",
    "519": "Datum od",
    "520": "Datum do",
    "521": "otvori",
    "522": "Veličina",
    "523": "Instalirano na",
    "524": "Uspjeh",
    "525": "Informacije",
    "526": "Nova narudžba učitana",
    "527": "ID Montažera",
    "528": "Prijavi se",
    "529": "Ovim potvrđujem odredbe i uslove i GDPR uredbu.",
    "530": "Odjavi se",
    "531": "skener",
    "532": "Pošalji izvještaj",
    "533": "Treba li izvješće o prihvatanju za sve stavke ove narudžbe poslati na Dashboard?",
    "534": "Dnevnik popravke",
    "535": "opcija 5",
    "536": "opcija 6",
    "537": "opcija 7",
    "538": "opcija 8",
    "539": "opcija 9",
    "540": "opcija 10",
    "541": "Email poslat.",
    "542": "Slanje emaila neuspješno.",
    "543": "Održavanje okova",
    "544": "Prilagođeno",
    "545": "Očišćeno",
    "546": "Zapečaćeno",
    "547": "Zamjena pribora",
    "548": "Komentari",
    "549": "Svi elementi su isporučeni na gradilište",
    "550": "Lokacija elemenata u okviru građevinskog projekta",
    "551": "Slanje protokola neuspješno!",
    "552": "Sačuvaj informacije protokola",
    "553": "Informacije su sačuvane.",
    "554": "Neuspješno čuvanje podataka.",
    "555": "Informacije Smart prozora",
    "556": "Prihvatanje Smart prozora",
    "557": "Broj elektronike prozora",
    "558": "Serijski broj elektronike prozora",
    "559": "Skeniraj QR kod (DMC) iz master controllera ili ga unesi ručno",
    "560": "Smart Window u potpunosti konfigurisan i funkcionalan",
    "561": "Svi vidljivi elementu su bez oštećenja/defekta",
    "562": "Da li je sistem za paljenje konektovan na 0-10V (0-100%)?",
    "563": "Lokacija zgrade za instalaciju",
    "564": "Započni skeniranje",
    "565": "Prekini skeniranje",
    "566": "Trenutno selektovana pozicija elementa",
    "567": "Maksimalno 4 atributa mogu biti selektovana u isto vrijeme.",
    "568": "Pos klijenta",
    "569": "Datum proizvodnje",
    "570": "Izmjena Window.ID",
    "571": "Prethodni Window.ID",
    "572": "Novi Window.ID",
    "573": "Redni broj ne postoji ili je već u upotrebi.",
    "574": "Window.ID",
    "575": "ID pozicije",
    "576": "Tip",
    "577": "ID elementa",
    "578": "Informacije o elementu",
    "579": "Window.ID",
    "580": "Molimo da se potpišete na predviđenom mjestu",
    "581": "Završeno",
    "582": "Potpiši",
    "583": "Novi jezik",
    "584": "Izvorni jezik",
    "585": "Ime jezika",
    "586": "Kreiraj novi jezik",
    "587": "Dodaj jezik",
    "588": "Ažuriraj",
    "589": "ažuriranje u toku",
    "590": "Deaktivacija kompanije",
    "591": "Aktivacija kompanije",
    "592": "Da li ste sigurni da želite da deaktivirate ovu kompaniju?",
    "593": "Da li ste sigurni da želite da aktivirate ovu kompaniju?",
    "594": "Deaktivacija korisnika",
    "595": "Aktivacija korisnika",
    "596": "Da li ste sigurni da želite da deaktivirate ovog korisnika?",
    "597": "Da li ste sigurni da želite da aktivirate ovog korisnika?",
    "598": "ID pozicija",
    "599": "Sve",
    "600": "Uvezi/Izvezi",
    "601": "Molimo Vas da izaberete .csv jezik za uvoz fajla",
    "602": "Uvezi",
    "603": "Izvezi",
    "604": "Datoteka",
    "605": "Prevodilac",
    "606": "Barkod fajla",
    "607": "Molimo Vas da izaberete putanju za barkod fajla.",
    "608": "Window_ID",
    "609": "Sistem prozorskih profila",
    "610": "Pozicija kupca",
    "611": "Boja",
    "612": "Veličina elementa",
    "613": "Veličina stakla",
    "614": "Struktura stakla",
    "615": "Informacije o hardveru",
    "616": "Odgovarajući datum",
    "617": "Datum i vrijeme montaže",
    "618": "Lokacija montaže putem GPSa",
    "619": "Br.",
    "620": "Montaža",
    "621": "Sprat",
    "622": "Poz",
    "623": "Zanemarite cifre prije bar koda",
    "624": "Zanemarite cifre posle bar koda",
    "625": "Email - poziv dilera",
    "626": "Poslati pozivni link ",
    "627": "Istek testnog perioda",
    "628": "Ponovo pošalji pozivni mail",
    "629": "Diler/Proizvođač već postoji",
    "630": "Pristup je aktiviran",
    "631": "Idi na prijavu",
    "632": "Podaci korisnika",
    "633": "Dodaj povezanog korisnika",
    "634": "Poništi link povezanog korisnika",
    "635": "Korisnik je već povezan",
    "636": "Korisničko ime ili lozinka je prazna",
    "637": "Korisnik nije pronađen",
    "638": "Pošalji protokol",
    "639": "Montaža završena",
    "640": "Opcija 5",
    "641": "Opcija 6",
    "642": "Opcija 7",
    "643": "Opcija 8",
    "644": "Opcija 9",
    "645": "Opcija 10",
    "646": "Monter",
    "647": "Tip",
    "648": "Datum  ",
    "649": "Opis",
    "650": "Održavanje okova",
    "651": "Podesiti",
    "652": "Čišćenje",
    "653": "Zatvoriti",
    "654": "Zamjena okova",
    "655": "Komentar",
    "656": "Priključak",
    "657": "Prozorski sistem",
    "658": "Broj komada",
    "659": "Broj naloga",
    "660": "ID naloga",
    "661": "Visina",
    "662": "Širina",
    "663": "Podaci o okovima",
    "664": "Širina x visina stakla",
    "665": "Konfiguracija stakla",
    "666": "Window ID",
    "667": "Window Electric ID",
    "668": "ID stakla",
    "669": "Uslovi",
    "670": "Dodaj/ukloni",
    "671": "I/ili",
    "672": "Grupa",
    "673": "Polje",
    "674": "Uslov ",
    "675": "Unesi broj kupca",
    "676": "Prevodilac",
    "678": "Kontrola verzije aplikacije",
    "679": "Ažuriranje će potrajati par minuta",
    "680": "Čekanje nove verzije",
    "681": "Nova verzija aplikacije, želite li ažurirati?",
    "682": "Aplikacija je ažurirana, želite li vratiti staru verziju?",
    "683": "Vrati staru verziju",
    "684": "Unesi naziv stanice",
    "685": "Broj narudžbe char filler",
    "686": "Unesite char broj narudžbe",
    "687": "Maksimalna dužina broja narudžbe",
    "688": "Unesite maksimalnu dužinu broja narudžbe",
    "689": "Putanja MDB foldera",
    "690": "Molimo unesite putanju .mdb datoteke (koju koristi uvoznik prilikom skeniranja za .mdb datoteke)",
    "691": "Putanja XML mape",
    "692": "Unesite putanju .xml datoteke (koju koristi uvoznik prilikom skeniranja za .xml datoteke)",
    "693": "Zanemarite početni karakter(e) QR-koda",
    "694": "Zanemari završni znak(e) QR-koda",
    "695": "Opis",
    "696": "Dužina",
    "697": "Dodatna pozicija",
    "698": "Pametan prozor bez oštećenja ",
    "699": "Pametni prozor povezan",
    "700": "Orijentacija prozora",
    "701": "Broj elementa pozicije",
    "702": "Pametni prozor konfiguriran",
    "703": "Element bez oštećenja",
    "704": "Materijalni broj",
    "705": "Serijski broj",
    "706": "Nije dostupna slika",
    "707": "Građevinsko mjesto čisto",
    "708": "Prevucite i ispustite sliku / pdf datoteke ovdje",
    "709": "Prenos datoteke",
    "710": "Vrsta datoteke",
    "711": "Narudžba (svi položaji)",
    "712": "Izbrisan",
    "713": "Napomene o izdanju",
    "714": "Visina (mm)",
    "715": "Širina (mm)",
    "716": "Za ovu poziciju nema panela",
    "717": "Predmet",
    "718": "Ravnateljica",
    "719": "Raspon rasuti",
    "720": "Prozor.id (zadnji)",
    "721": "Staklo je zamijenjeno",
    "722": "Popravljeni su dodaci",
    "723": "Sash je zamijenjen",
    "724": "Površina je popravljena",
    "725": "Popravljeni su dodaci",
    "726": "Popravak je završen",
    "727": "Potvrdi šifru",
    "728": "Pogrešna lozinka",
    "729": "Lozinke se ne podudaraju",
    "730": "Lozinka ne može biti prazna",
    "731": "Lokacija",
    "732": "Vremenski okvir",
    "733": "U-vrijednost (w / m²k)",
    "734": "Specijalno staklo",
    "735": "Nema položaja",
    "736": "Napredak proizvodnje",
    "737": "Završena proizvodnja",
    "738": "Napredak instalacije",
    "739": "Instalacija završena",
    "740": "Stanica izbrisana",
    "741": "Da li stvarno želite izbrisati ovu stanicu?",
    "742": "Dodajte novu stanicu",
    "743": "Izbriši narudžbu",
    "744": "Narudžba",
    "745": "Jeste li sigurni da želite izbrisati narudžbu?",
    "746": "Korisnik je dodao",
    "747": "Korisnik uredio",
    "748": "Zaboravili ste lozinku?",
    "749": "Polje za lozinku je obavezno",
    "750": "Polje korisnika je potrebno",
    "751": "Ova uloga korisnika nije ovlaštena za prijavu na nadzornoj ploči",
    "752": "Korisnik nije aktivan",
    "753": "Korisnička kompanija nije aktivna ili je probno razdoblje završeno",
    "754": "Korisničko ime ili lozinka su netačni",
    "755": "Poštanski broj",
    "756": "Upotrijebite kotačić za pomicanje da biste povećali i izlazili sliku",
    "757": "Reset lozinke",
    "758": "Poslano je e-poštu za resetiranje lozinke",
    "759": "Lozinka je resetirana",
    "760": "E-pošta - resetirajte lozinku",
    "761": "Element Nr",
    "762": "Broj narudžbe iz",
    "763": "Unesite broj narudžbe iz",
    "764": "Dužina broja narudžbe",
    "765": "Unesite dužinu broja narudžbe",
    "766": "Raspon narudžbi od",
    "767": "Molimo unesite raspon narudžbe iz",
    "768": "Dužina raspona narudžbi",
    "769": "Unesite dužinu raspona narudžbe",
    "770": "Please insert order piece from",
    "772": "Dužina komada narudžbe",
    "773": "Unesite dužinu komada narudžbe",
    "774": "Broj položaja od",
    "775": "Molimo unesite broj položaja iz",
    "776": "Dužina broja položaja",
    "777": "Unesite dužinu broja položaja",
    "778": "Pozicioni komad iz",
    "779": "Molimo vas umetnite položaj položaja iz",
    "780": "Dužina komada položaja",
    "781": "Molimo unesite duljinu položaja",
    "782": "Nivo sigurnosti",
    "783": "Lik punila za barkod",
    "784": "Unesite znak za punjenje barkoda",
    "785": "Povratak na projekte",
    "786": "(samo za čitanje)",
    "787": "Ograničeni pristup",
    "788": "Čitajte samo pristup",
    "789": "Vlastiti slijetanje",
    "790": "Umetnite vezu",
    "791": "DB vezni niz",
    "792": "DB STD vezni niz",
    "793": "DB BWG vezni niz",
    "794": "Veza baze podataka",
    "795": "Chat",
    "796": "Poruke",
    "797": "CE dokument",
    "798": "Naručite dokument",
    "799": "Posljednji popravak",
    "800": "Završeno prihvatanje",
    "801": "New update vailable, please restart application",
    "802": "External link",
    "803": "External link prefix",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder.",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
}

export default bs;