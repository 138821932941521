const keys = {
    109:	"109",
    31:	    "031",
    192:	"192",
    377:	"377",
    378:	"378",
    379:	"379",
    380:	"380",
    148:	"148",
    358:	"358",
    381:	"381",
    205:	"205",
    173:	"173",
    382:	"382",
    383:	"383",
    105:	"105",
    384:	"384",
    199:	"199",
    198:	"198",
    386:	"386",
    387:	"387",
    388:	"388",
    389:	"389",
    390:	"390",
    22:	    "022",
    40:	    "040",
    207:	"207",
    105:	"105",
    391:	"391",
    392:	"392",
    393:	"393",
    394:	"394",
    263:	"263",
    395:	"395",
    396:	"396",
    397:	"397",
    123:	"123",
    398:	"398",
    399:	"399",
    400:	"400",
    401:	"401",
    402:	"402",
    403:	"403",
    111:	"111",
    404:	"404",
    405:	"405",
    406:	"406",
    407:	"407",
    408:	"408",
    409:	"409",
    410:	"410",
    167:	"167",
    411:	"411",
    412:	"412",
    310:	"310",
    413:	"413",
    414:	"414",
    114:	"114",
    415:	"415",
    416:	"416",
    417:	"417",
    418:	"418",
    419:	"419",
    420:	"420",
    421:	"421",
    422:	"422",
    423:	"423",
    5:	    "005",
    71:	    "071",
    424:	"424",
    425:	"425",
    363:	"363",
    284:	"284",
    426:	"426",
    427:	"427",
    428:	"428",
    429:	"429",
    430:	"430",
    431:	"431",
    432:	"432",
    433:	"433",
    434:	"434",
    310:	"310",
    435:	"435",
    436:	"436",
    437:	"437",
    438:	"438",
    439:	"439",
    440:	"440",
    441:	"441",
    442:	"442",
    443:	"443",
    444:	"444",
    35:	    "035",
    445:	"445",
    446:	"446",
    447:	"447",
    448:	"448",
    449:	"449",
    450:	"450",
    451:	"451",
    452:	"452",
    453:	"453",
    454:	"454",
    455:	"455",
    456:	"456",
    457:	"457",
    458:	"458",
    458:	"458",
    461:	"461",
    462:	"462",
    463:	"463",
    464:	"464",
    465:	"465",
    466:	"466",
    467:	"467",
    468:	"468",
    469:	"469",
    470:	"470",
    471:	"471",
    472:	"472",
    473:	"473",
    474:	"474",
    475:	"475",
    476:	"476",
    477:	"477",
    478:	"478",
    479:	"479",
    480:	"480",
    481:	"481",
    482:	"482",
    483:	"483",
    484:	"484",
    485:	"485",
    486:	"486",
    487:	"487",
    488:	"488",
    197:	"197",
    489:	"489",
    490:	"490",
    491:	"491",
    492:	"492",
    493:	"493",
    494:	"494",
    495:	"495",
    496:	"496",
    497:	"497",
    498:	"498",
    499:	"499",
    54:	    "054",
    500:	"500",
    501:	"501",
    502:	"502",
    503:	"503",
    504:	"504",
    505:	"505",
    45:	    "045",
    506:	"506",
    507:	"507",
    306:	"306",
    508:	"508",
    509:	"509",
    510:	"510",
    511:	"511",
    512:	"512",
    513:	"513",
    30:	    "030",
    514:	"514",
    144:	"144",
    515:	"515",
    516:	"516",
    517:	"517",
    518:	"518",
    519:	"519",
    520:	"520",
    208:	"208",
    521:	"521",
    522:	"522",
    523:	"523",
    524:	"524",
    525:	"525",
    311:	"311",
    526:	"526",
    286:	"286",
    176:	"176",
    527:	"527",
    528:	"528",
    529:	"529",
    1:	    "001",
    2:	    "002",
    530:	"530",
    138:	"138",
    531:	"531",
    532:	"532",
    533:	"533",
    534:	"534",
    535:	"535",
    536:	"536",
    537:	"537",
    538:	"538",
    539:	"539",
    540:	"540",
    541:	"541",
    542:	"542",
    543:	"543",
    544:	"544",
    545:	"545",
    546:	"546",
    547:	"547",
    548:	"548",
    59:	    "059",
    549:	"549",
    550:	"550",
    551:	"551",
    552:	"552",
    553:	"553",
    554:	"554",
    19:	    "019",
    555:	"555",
    222:	"222",
    556:	"556",
    264:	"264",
    151:	"151",
    53:	    "053",
    134:	"134",
    49:	    "049",
    557:	"557",
    152:	"152",
    153:	"153",
    558:	"558",
    559:	"559",
    560:	"560",
    561:	"561",
    562:	"562",
    563:	"563",
    228:	"228",
    564:	"564",
    565:	"565",
    566:	"566",
    567:	"567",
    209:	"209",
    568:	"568",
    569:	"569",
    570:	"570",
    571:	"571",
    572:	"572",
    573:	"573",
    67:	    "067",
    574:	"574",
    575:	"575",
    576:	"576",
    577:	"577",
    578:	"578",
    579:	"579",
    580:	"580",
    41:	    "041",
    581:	"581",
    582:	"582",
    291:    "291",
    321:    "321"
};

export default keys;