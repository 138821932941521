const ua = {
    "1": "Так",
    "2": "Ні",
    "3": "30-денна пробна версія",
    "4": "Рекламацію надіслано через додаток",
    "5": "Прийняття",
    "6": "Дата прийняття",
    "7": "Дія",
    "8": "Активний",
    "9": "Діяльність",
    "10": "Додати",
    "11": "Додати монтажника",
    "12": "Додати компанію",
    "13": "Додати дилера",
    "14": "Додати виробника",
    "15": "Додати керуючого нерухомістю",
    "16": "Додати користувача",
    "17": "Додаткове поле",
    "18": "Редагувату назву додаткового поля",
    "19": "Коментарі",
    "20": "Додаткова інформація",
    "21": "Додаткові продукти",
    "22": "Налаштування",
    "23": "Адміністратор",
    "24": "Адміністрація проектів",
    "25": "Всі елементи доставлені на об'єкт?",
    "26": "Всі позиції встановлені",
    "27": "Всі права захищені",
    "28": "Ви впевнені, що хочете видалити цього виробника?",
    "29": "Ви впевнені, що хочете видалити цього користувача?",
    "30": "Встановлено",
    "31": "Монтажник",
    "32": "Монтажники",
    "33": "Монтаж",
    "34": "Монтаж завершено",
    "35": "Дата монтажу",
    "36": "Призначення",
    "37": "Інструкції по догляду",
    "38": "Інформація про сертифікат",
    "39": "Сертифікат(и)",
    "40": "Заміна стулки",
    "41": "Очистити",
    "42": "Закрити",
    "43": "Колір",
    "44": "Колір/покриття",
    "45": "Комісія",
    "46": "Компанія",
    "47": "Інформація про компанію",
    "48": "Інформація про компанію",
    "49": "Напрямок за компасом",
    "50": "Рекламації",
    "51": "Проект завершено",
    "52": "Завершено",
    "53": "Приєднане вікно",
    "54": "Будівельний майданчик чистий",
    "55": "Дата створення",
    "56": "Кінцевий користувач",
    "57": "Номер клієнта",
    "58": "Посилання на елемент",
    "59": "Дата",
    "60": "Проміжок часу",
    "61": "Дилер",
    "62": "Видалити компанію",
    "63": "Видалити виробника",
    "64": "Видалити користувача",
    "65": "Дата доставки",
    "66": "Доставка без пошкоджень",
    "67": "Опис",
    "68": "Зазначення місця установки",
    "69": "Відображати _MENU_ записів на сторінку",
    "70": "Диситрибуція",
    "71": "Документи",
    "72": "Схід",
    "73": "Редагувати монтажника",
    "74": "Редагувати компанію",
    "75": "Редагувати виробника",
    "76": "Редагувати керуючого нерухомістю",
    "77": "Редагувати користувача",
    "78": "Ширина х висота елемента в мм.",
    "79": "Елемента з таким ID не існує",
    "80": "Елементи",
    "81": "Дані електронної пошти збережено",
    "82": "Вкажіть колір",
    "83": "Вкажіть комісію",
    "84": "Вкажіть назву компанії",
    "85": "Вкажіть e-mail",
    "86": "Вкажіть розширення",
    "87": "Встановіть висоту",
    "88": "Вкажіть IP-адресу",
    "89": "Вкажіть ім'я",
    "90": "Вкажіть номер",
    "91": "Вкажіть кількість позицій",
    "92": "Встановіть пароль",
    "93": "Вкажіть телефон",
    "94": "Вкажіть номер телефону",
    "95": "Вкажіть місто",
    "96": "Вкажіть кількість",
    "97": "Вкажіть вулицю",
    "98": "Вкажіть ім'я",
    "99": "Вкажіть ім'я користувача",
    "100": "Вкажіть ID акаунту",
    "101": "Вкажіть сайт",
    "102": "Встановiть ширину",
    "103": "Помилка під час збереження даних на сервері.",
    "104": "Помилка під час збереження даних електронної пошти.",
    "105": "Замінити фурнітуру",
    "106": "закінчився о",
    "107": "закінчується о",
    "108": "Розширення",
    "109": "Виробники",
    "110": "Фабрика",
    "111": "Фільтр",
    "112": "відфільтровано з _MAX_ загальних записів",
    "113": "Перший",
    "114": "Відміна",
    "115": "Дані фурнітури",
    "116": "Москітна сітка",
    "117": "Відбиток",
    "118": "Коментарі",
    "119": "Із програми",
    "120": "З режиму адміністрування",
    "121": "Очищувач скла",
    "122": "Заміна скла",
    "123": "Структура скла",
    "124": "Ширина x Висота скла у мм.",
    "125": "Обслуговування фурнітури",
    "126": "Заміна фурнітури",
    "127": "Висота",
    "128": "Інструкція з очищення",
    "129": "Зображення",
    "130": "Інформація про елемент",
    "131": "Інформація обмежена",
    "132": "Позиційні документи",
    "134": "Місце установки",
    "135": "введіть штрих-код",
    "136": "IP-адреса",
    "137": "необхідно",
    "138": "Мова",
    "139": "Останній",
    "140": "Останнє технічне обслуговування",
    "141": "Остання рекламація",
    "142": "Останній сервіс",
    "143": "Посилання на артикул",
    "144": "Завантаження",
    "145": "Чи всі елементи відповідають специфікації?",
    "146": "Вихід",
    "147": "Дані доступу до електронної пошти",
    "148": "Технічне обслуговування",
    "149": "Змінено",
    "150": "Виготовлено",
    "151": "Master Controller дизайн",
    "152": "Master Controller номер матеріалу",
    "153": "Master Controller серійний номер",
    "154": "Дата виробництва",
    "155": "Видимість меню",
    "156": "Автоматичне підтвердження штрих-коду",
    "157": "Документи цільвої сторінки",
    "158": "Новий тестовий період",
    "159": "Наступний",
    "160": "Елемент без пошкоджень",
    "161": "Скло без пошкоджень",
    "162": "Відповідних записів не знайдено",
    "163": "Жоден з елементів не встановлений",
    "164": "Північ",
    "165": "Не змонтований",
    "166": "Нічого не знайдено - вибачте",
    "167": "Номер",
    "168": "Кількість елементів",
    "169": "Огляд проекту",
    "170": "Кількість позицій",
    "171": "на",
    "172": "Дата замовлення",
    "173": "Номер замовлення",
    "174": "Виконання замовлення",
    "175": "Вихід",
    "176": "Пароль",
    "177": "Телефон",
    "178": "Місто",
    "179": "Засіб для чищення пластику",
    "180": "Документи по позиціям",
    "181": "Деталі елемента позиції",
    "182": "Позиції з таким ID не існує",
    "183": "Позиції",
    "184": "Попередній",
    "185": "Основні облікові дані користувача",
    "186": "Роздрукувати дані для входу",
    "187": "Обробка",
    "188": "Виробник",
    "189": "Профільна система",
    "190": "Проект",
    "191": "Номер проекту",
    "192": "Управління нерухомістю",
    "193": "Кількість",
    "194": "Рекламація",
    "195": "Будь ласка, оберіть станцію",
    "196": "Перероблений вміст",
    "197": "Ремонт",
    "198": "Ремонт аксесуарів",
    "199": "Ремонт поверхні",
    "200": "Звітовано з додатку",
    "201": "Звітовано з режиму адміністрування",
    "202": "Скинути тестовий період",
    "203": "Результат",
    "204": "Роль",
    "205": "Зберегти",
    "206": "Успішно збережено.",
    "207": "Чистка поверхні",
    "208": "Пошук",
    "209": "Рівень безпеки",
    "210": "Надіслати дані в REHAU",
    "211": "Сервіс",
    "212": "Сервіс і продаж",
    "213": "Сервісна компанія",
    "214": "Налаштування",
    "215": "Чи потрібно проводити загальне приймання або технічне обслуговування вручну",
    "216": "Показати від _ПОЧАТКУ_ до _КІНЦЯ_ з _ВСЬОГО_ записів",
    "217": "Показано від 0 до 0 з 0 записів",
    "218": "Жалюзі",
    "219": "Увійдіть в систему",
    "220": "Увійдіть, щоб розпочати сесію",
    "221": "Інформація про Smart Glass",
    "222": "Smart Window",
    "223": "Деякі елементи встановлені",
    "224": "Деякі елементи не збігаються",
    "225": "Деякі позиції потребують встановлення",
    "226": "Південь",
    "227": "Статистика",
    "228": "Вулиця",
    "229": "Прізвище",
    "230": "Сталий розвиток та Енергія",
    "231": "Телефон",
    "232": "Тестовий період успішно скинуто",
    "233": "Тестовий період (и)",
    "234": "Властивості збережені",
    "235": "Є рекламації",
    "236": "Немає компанії з таким ID",
    "237": "Відомостей про цей елемент немає",
    "238": "Ця компанія вже використовується",
    "239": "Назва",
    "240": "до",
    "241": "Повне прийняття",
    "242": "Усього",
    "243": "Невідомий",
    "244": "Завантажити логотип",
    "245": "Адміністрування користувачів",
    "246": "Ім'я користувача",
    "247": "Користувач вже заданий",
    "248": "Користувачі",
    "249": "Теплопровідність (коефіцієнт теплопередачі)",
    "250": "Коефіцієнт теплопередачі рами",
    "251": "Коефіцієнт теплопередачі скла",
    "252": "W.ID призначено",
    "253": "Увага, не пошкодіть ідентифікатор вікна",
    "254": "Ласкаво просимо",
    "255": "Захід",
    "256": "Ширина",
    "257": "Ширина x Висота в мм",
    "258": "Площа вікон від",
    "259": "Площа вікна в",
    "260": "Window Electronic номер матеріалу",
    "261": "Window Electronic серійний номер",
    "262": "Місце монтажу",
    "263": "Віконна система",
    "264": "Тип вікна",
    "265": "Заміна стулки",
    "266": "Ти впевнений?",
    "267": "Очистити фільтр",
    "268": "Редагувати",
    "269": "Експорт CSV",
    "270": "Зчитувач штрих-коду",
    "271": "Ідентифікатор скла",
    "272": "Інформація",
    "273": "Посилання",
    "274": "Пов'язані користувачі",
    "275": "Електронна пошта",
    "276": "Налаштування електронної пошти",
    "277": "Електронна пошта - тестовий період",
    "278": "Назва послуги",
    "279": "Сервісна компанія",
    "280": "Телефон служби підтримки",
    "281": "Електронна пошта служби підтримки",
    "282": "Статус",
    "283": "Налаштування станції",
    "284": "Тема",
    "285": "Телефон",
    "286": "Користувач",
    "287": "Веб-сайт",
    "288": "ID вікна",
    "289": "Віконна система",
    "290": "Дані клієнта",
    "291": "Ім'я",
    "292": "Структура скла",
    "293": "Деталі фурнітури",
    "294": "Назва монтажу",
    "295": "Компанія з монтажу",
    "296": "Телефон монтажника",
    "297": "Електронна пошта компанії монтажу",
    "298": "URL",
    "299": "Країна",
    "300": "Будь ласка, додайте користувача для цієї компанії",
    "301": "Додаткове меню",
    "302": "Огляд",
    "303": "Додаток",
    "304": "Дизайн цільової сторінки",
    "305": "Керування користувачами",
    "306": "Підтвердити",
    "307": "Основна частина",
    "308": "Додаток",
    "309": "ID",
    "310": "Позиція",
    "311": "Увага ",
    "312": "W.ID",
    "314": "Дилери",
    "315": "Активувати",
    "316": "Деактивувати",
    "317": "компанія",
    "318": "Вибачте",
    "319": "Адміністратор",
    "320": "користувач",
    "321": "Поштовий індекс",
    "322": "Логотип",
    "323": "Шпалери",
    "324": "ІD облікового запису",
    "325": "Документи CE",
    "326": "Налаштування програми",
    "327": "Автоматично",
    "328": "Вручну",
    "329": "Переглянути",
    "330": "Завантажити",
    "331": "Автоматичне завантаження документів",
    "332": "Вихідна папка",
    "333": "Будь ласка, оберіть шлях до папки для автоматичного завантаження документів.",
    "334": "Будь ласка, виберіть .zip файл, що містить файл PPS XML",
    "335": "Станція",
    "336": "Ручне сканування",
    "337": "Станція зі смарт-склом",
    "338": "Передача інформації",
    "339": "Відображати позиції на часовій шкалі",
    "340": "QR-код від",
    "341": "QR-код до",
    "342": "Префікс QR-коду",
    "343": "Довжина QR-коду",
    "344": "Дані штрих-коду є недійсними.",
    "345": "Ключ Api",
    "346": "введіть QR-код",
    "347": "Назва станції",
    "348": "Файл не вибрано",
    "349": "Файл завантажено",
    "350": "Помилка завантаження файлу",
    "351": "Увімкнено ручне сканування",
    "352": "Будь ласка, введіть qr-код",
    "353": "Штрих-код недійсний",
    "354": "Цей штрих-код імпортовано",
    "356": "Немає даних",
    "357": "Не вдалося встановити з'єднання",
    "358": "Історія",
    "359": "Штрих-код використовується.",
    "360": "Помилка при зіставленні штрих-коду",
    "361": "Вручну",
    "362": "Від",
    "363": "До",
    "364": "Довжина префікса QR-коду повинна бути меншою за довжину QR-коду",
    "365": "Діапазон штрих-коду невірний",
    "366": "Невірні номери",
    "367": "Використано раніше",
    "368": "Використовується сьогодні",
    "369": "Безкоштовно",
    "370": "Використано до сьогодні",
    "371": "Використання номера QR-коду для поточного діапазону станцій",
    "372": "Переклади",
    "373": "Редагувати переклад",
    "374": "Ключ",
    "375": "англійська",
    "376": "Значення",
    "377": "ID для призначення",
    "378": "Позиції вікон та дверей у проекті",
    "379": "Сервісне обслуговування / підтвердження ремонту",
    "380": "Обслуговування та історія",
    "381": "Звіт про встановлення",
    "382": "Модифікація",
    "383": "Заміна скла",
    "384": "Заміна стулки",
    "386": "Коментарі",
    "387": "Ремонт завершено",
    "388": "Нові функції",
    "389": "Журнал технічного обслуговування",
    "390": "Обслуговування фурнітури",
    "391": "Записатися на технічне обслуговування",
    "392": "Повний список проектів",
    "393": "Інформація про вікно",
    "394": "Інформація про встановлення",
    "395": "Колір",
    "396": "Ширина елемента х висота в мм",
    "397": "Ширина скла х висота в мм",
    "398": "Інформація про монтаж",
    "399": "Ім'я монтажника",
    "400": "Дата / час встановлення",
    "401": "Місце встановлення (GPS)",
    "402": "ID пристрою JustSmart",
    "403": "відправити",
    "404": "Положення фільтру",
    "405": "Немає",
    "406": "Балконні двері",
    "407": "Кухня",
    "408": "Ванна кімната",
    "409": "Кімната",
    "410": "Дах",
    "411": "Установка",
    "412": "Позиція",
    "413": "Прикріпити фото",
    "414": "Підпис",
    "415": "Креслення",
    "416": "Вікно не пошкоджено",
    "417": "Скло без пошкоджень",
    "418": "Відкрита камера",
    "419": "Завантажити",
    "420": "Встановити та призначити вікно",
    "421": "Акт прийому-передачі",
    "422": "Відправити",
    "423": "Весь будівельний проект",
    "424": "Тип кріплення",
    "425": "Явний для елемента",
    "426": "Вся робота виконана",
    "427": "Звіт про встановлення для окремих елементів",
    "428": "Існує проблема",
    "429": "Попросити про допомогу",
    "430": "Повідомлення",
    "431": "Надіслати",
    "432": "Наступний елемент",
    "433": "Повний список елементів",
    "434": "Графік будівництва",
    "435": "через номер замовлення",
    "436": "через сканер QR-коду",
    "437": "показати один",
    "438": "або",
    "439": "Номер штрих-коду",
    "440": "Показати інформацію",
    "441": "Ремонт/рекламація",
    "442": "Показати контактну особу",
    "443": "Зворотній зв'язок",
    "444": "Відскануйте інший QR-код",
    "445": "Наступне технічне обслуговування",
    "446": "Постачальник вікон",
    "447": "Властивості",
    "448": "Значення звукоізоляції",
    "449": "Uf",
    "450": "Рішення для модернізації",
    "451": "Зв'яжіться з нами",
    "452": "ІD вашого вікна",
    "453": "Що ви хочете зробити?",
    "454": "Регулярне обслуговування вікон/дверей подовжує термін служби та підвищує комфорт",
    "455": "Запишіться на сервісне обслуговування через контактну форму",
    "456": "Запишіться на технічне обслуговування по телефону",
    "457": "до свого віконного майстра",
    "458": "Цим я підтверджую умови та положення щодо захисту даних",
    "459": "Ваше занепокоєння",
    "461": "Зверніться через ...",
    "462": "Розбите скло",
    "463": "Вікно не закривається належним чином",
    "464": "Вікно заклинило",
    "465": "Вікно пошкоджено",
    "466": "Інші аксесуари",
    "467": "Ваші вікна та двері були ввімкнені",
    "468": "змонтовані цією компанією",
    "469": "Виробник вікон",
    "470": "Оцініть свої вікна та майстра зараз",
    "471": "Наскільки ви задоволені сервісом компанії",
    "472": "Чи влаштовує Вас доставлена продукція?",
    "473": "Як вам подобаються ваші вікна?",
    "474": "Чи були проблеми з обробкою?",
    "475": "Чим Ви були задоволені?",
    "476": "Що б ви хотіли покращити?",
    "477": "Інструкція по монтажу",
    "478": "Положення вікон та дверей",
    "479": "Відкрити звіт про монтаж",
    "480": "Повний список елементів проекту",
    "481": "Список елементів за номером замовлення / QR",
    "482": "Надіслати журнал проекту",
    "483": "Деталі вікна",
    "484": "Окремі дані",
    "485": "Номер будівельного проекту",
    "486": "Вікно",
    "487": "Розташування вікна",
    "488": "Послуга з ремонту",
    "489": "Деталі обслуговування",
    "490": "Клієнт",
    "491": "Контактна форма",
    "492": "Знайдіть майстра REHAU поруч з вами зараз",
    "493": "Категорія",
    "494": "QR-сканування",
    "495": "Контактна особа",
    "496": "оновити",
    "497": "фільтри",
    "498": "Монтаж завершено",
    "499": "Монтаж завершено?",
    "500": "Доставлено без пошкоджень",
    "501": "Зберегти у форматі PDF",
    "502": "Зробити фото",
    "503": "Невірна адреса електронної пошти",
    "504": "Встановити додаток",
    "505": "Розмістити",
    "506": "Список проектів",
    "507": "Завантажити вибране замовлення?",
    "508": "Неправильний номер замовлення/штрих-код",
    "509": "Єдине вікно",
    "510": "Внести зміни",
    "511": "Надіслати звіт на електронну пошту",
    "512": "Будь ласка, оберіть 4 варіанти",
    "513": "Тепер відскануйте QR-код, щоб дізнатися, де слід встановити елемент.",
    "514": "Зберегти файл",
    "515": "Показати",
    "516": "результати",
    "517": "сторінка",
    "518": "з",
    "519": "Дата від",
    "520": "Дата до",
    "521": "відкрито",
    "522": "Розмір",
    "523": "Встановлено на",
    "524": "Успішно",
    "525": "Інформація",
    "526": "Завантажено нове замовлення",
    "527": "Ідентифікатор інсталятора",
    "528": "Логін",
    "529": "Цим я підтверджую загальні умови та положення щодо захисту даних",
    "530": "Вийти з системи",
    "531": "сканер",
    "532": "Надіслати звіт",
    "533": "Чи потрібно надсилати на інформаційну панель звіт про приймання всіх позицій цього замовлення?",
    "534": "Журнал ремонту",
    "535": "варіант 5",
    "536": "варіант 6",
    "537": "варіант 7",
    "538": "варіант 8",
    "539": "варіант 9",
    "540": "варіант 10",
    "541": "Лист відправлено.",
    "542": "Помилка при відправці листа!",
    "543": "Змащення фітингів",
    "544": "Відрегульовано",
    "545": "Очищено",
    "546": "Запечатано",
    "547": "Замінено обладнання",
    "548": "Коментар",
    "549": "Всі елементи доставлені на будівельний майданчик",
    "550": "Розташування елементів на будівельному майданчику",
    "551": "Помилка при відправці звіту!",
    "552": "Зберегти звіт",
    "553": "Інформація збережена.",
    "554": "Не вдалося зберегти дані.",
    "555": "Інформація Smart window",
    "556": "Прийняття Smart window",
    "557": "Номер матеріалу Window electronics",
    "558": "Серійний номер Window electronics",
    "559": "Відскануйте QR-код  з головного контролера або введіть його вручну",
    "560": "Smart window повністю налаштоване і функціонує",
    "561": "Всі видимі елементи без пошкоджень / дефектів",
    "562": "Чи підключена система комутації до 0-10В (0-100%)?",
    "563": "Місце монтажу в будівлі",
    "564": "Почати сканування",
    "565": "Зупинити сканування",
    "566": "Вибраний елемент",
    "567": "Одночасно можна вибрати максимум 4 атрибути.",
    "568": "Позиція клієнта",
    "569": "Дата виробництва",
    "570": "Змінити Window.ID",
    "571": "Попередній Window.ID",
    "572": "Новий Window.ID",
    "573": "Цього номера заказу не існує, або новий номер заказу вже використовується.",
    "574": "Window.ID",
    "575": "ІD позиції",
    "576": "Тип",
    "577": "ІD елемента",
    "578": "Інформація про елемент",
    "579": "Window.ID",
    "580": "Будь ласка, підпишіть у відведеному місці",
    "581": "Готово",
    "582": "Підписати",
    "583": "Нова мова",
    "584": "Мова оригіналу",
    "585": "Назва мови",
    "586": "Створити нову мову",
    "587": "Додати мову",
    "588": "Оновити",
    "589": "йде оновлення",
    "590": "Деактивація компанії",
    "591": "Активація компанії",
    "592": "Ви впевнені, що хочете деактивувати цю компанію?",
    "593": "Ви впевнені, що хочете активувати цю компанію?",
    "594": "Деактивація користувача",
    "595": "Активація користувача",
    "596": "Ви впевнені, що хочете деактивувати цього користувача?",
    "597": "Ви впевнені, що хочете активувати цього користувача?",
    "598": "ID Позиції",
    "599": "Всі",
    "600": "Імпорт/Експорт",
    "601": "Будь ласка, виберіть для імпорту мовний файл .csv",
    "602": "Імпорт",
    "603": "Експорт",
    "604": "Файл",
    "605": "Перекладач",
    "606": "Файли штрих-кодів",
    "607": "Будь ласка, виберіть шлях до папки для файлів штрих-кодів.",
    "608": "Window_ID",
    "609": "Профільна система",
    "610": "Позиція клієнта",
    "611": "Колір",
    "612": "Розміри елемента",
    "613": "Розміри скла",
    "614": "Структура скла",
    "615": "Інформація про фурнітуру",
    "616": "Дата співпадіння (matching)",
    "617": "Дата/Час монтажу",
    "618": "GPS координати",
    "619": "№:",
    "620": "Монтаж:",
    "621": "Поверх:",
    "622": "Позиція:",
    "623": "Ігнорувати початковий(і) символ(и)",
    "624": "Ігнорувати кінцевий(і) символ(и)",
    "625": "Електронна пошта - активація користувача",
    "626": "Активувати компанії",
    "627": "Закінчився термін дії пробного періоду",
    "628": "Повторно надіслати лист для активації",
    "629": "Дилер/Завод вже існує",
    "630": "Користувач активований",
    "631": "Перейдіть на сторінку входу",
    "632": "Дані користувача",
    "633": "Додати прив'язаного користувача",
    "634": "Видалити посилання користувача",
    "635": "Користувач вже прив'язаний",
    "636": "Ім'я користувача або пароль порожні",
    "637": "Користувача не знайдено",
    "638": "Надіслати протокол керівництву",
    "639": "Монтаж завершено",
    "640": "Варіант 5",
    "641": "Варіант 6",
    "642": "Варіант 7",
    "643": "Варіант 8",
    "644": "Варіант 9",
    "645": "Варіант 10",
    "646": "Монтажник",
    "647": "Тип",
    "648": "Дата",
    "649": "Опис",
    "650": "Обслуговування фурнітури",
    "651": "Набір",
    "652": "Чистота",
    "653": "Ущільнення",
    "654": "Заміна аксесуарів",
    "655": "Коментар",
    "656": "З'єднувач",
    "657": "Віконна система",
    "658": "Номер частки",
    "659": "Номер проекту",
    "660": "ID замовлення",
    "661": "висота",
    "662": "ширина",
    "663": "Інформація про фурнітуру",
    "664": "Ширина x висота скла",
    "665": "склопакет",
    "666": "Window.ID",
    "667": "ІD Window Electric ",
    "668": "ІD скла",
    "669": "Умови",
    "670": "Додати/видалити",
    "671": "І/або",
    "672": "Група",
    "673": "Колонка",
    "674": "Умова",
    "675": "Введіть номер клієнта",
    "676": "Перекладач",
    "678": "Контроль версій програми",
    "679": "Оновлення може зайняти кілька хвилин",
    "680": "Очікую на нову версію",
    "681": "Вийшла нова версія програми, ви впевнені, що хочете оновити?",
    "682": "Додаток актуальний, ви хочете відновити стару версію?",
    "683": "Відновити стару версію",
    "684": "Вставте назву станції",
    "685": "Символ-заповнювач для номера замовлення",
    "686": "Будь ласка, введіть номер замовлення як символ-заповнювач",
    "687": "Номер замовлення максимальна довжина",
    "688": "Будь ласка, вставте номер замовлення максимальна довжина",
    "689": "Шлях до папки з файлами .mdb",
    "690": "Будь ласка, введіть шлях до файлу .mdb (використовується імпортером при скануванні файлів .mdb)",
    "691": "Шлях до папки з файлами .xml",
    "692": "Будь ласка, введіть шлях до файлу .xml (використовується імпортером при скануванні файлів .xml)",
    "693": "Ігнорувати початковий(і) символ(и) QR-коду",
    "694": "Ігнорувати кінцевий символ(и) QR-коду",
    "695": "Позначення",
    "696": "Довжина",
    "697": "Додаткова позиція",
    "698": "Smart window без пошкоджень",
    "699": "Smart window підключено",
    "700": "Орієнтація вікна",
    "701": "Номер позиції елементу",
    "702": "Smart window налаштовано",
    "703": "Елемент без пошкоджень",
    "704": "Номер матеріалу",
    "705": "Серійний номер",
    "706": "Зображення відсутнє",
    "707": "Будівельний майданчик чистий",
    "708": "Перетягніть зображення/файли pdf сюди",
    "709": "Завантажити файл",
    "710": "Тип файлу",
    "711": "Замовлення (всі позиції)",
    "712": "Видалено",
    "713": "Примітки до випуску",
    "714": "Висота (мм)",
    "715": "Ширина (мм)",
    "716": "Для цієї позиції немає панелі",
    "717": "Позиція",
    "718": "Bulk станція",
    "719": "Bulk асортимент",
    "720": "Oстанній Window.ID ",
    "721": "Скло замінено ",
    "722": "Фурнітуру відремонтовано",
    "723": "Стулку замінено",
    "724": "Поверхню відремонтовано ",
    "725": "Фурнітуру відремонтовано",
    "726": "Ремонт завершено",
    "727": "Підтвердити пароль",
    "728": "Пароль невірний",
    "729": "Паролі не збігаються",
    "730": "Пароль не може бути порожнім",
    "731": "Розташування",
    "732": "Хронологія",
    "733": "U-коефіцієнт теплопередачі",
    "734": "Спеціальне скло",
    "735": "Відсутня позиція",
    "736": "Хід виробництва",
    "737": "Виробництво завершено",
    "738": "Прогрес монтажу",
    "739": "Монтаж завершено",
    "740": "Станцію видалено",
    "741": "Ви дійсно хочете видалити цю станцію?",
    "742": "Додати нову станцію",
    "743": "Видалити замовлення",
    "744": "Видалення замовлення",
    "745": "Ви впевнені, що хочете видалити замовлення?",
    "746": "Користувача додано",
    "747": "Користувача відредаговано",
    "748": "Забули пароль?",
    "749": "Поле \"Пароль\" обов'язкове для заповнення",
    "750": "Поле \"Ім'я користувача\" обов'язкове для заповнення",
    "751": "Цей користувач не має прав для входу в панель управління",
    "752": "Користувач не активний",
    "753": "Компанія користувача не активна або тестовий період закінчився",
    "754": "Ім'я користувача або пароль невірні",
    "755": "Поштовий індекс",
    "756": "Використовуйте колесо прокрутки, щоб збільшити або зменшити зображення",
    "757": "Скинути пароль",
    "758": "Електронний лист для скидання пароля відправлено",
    "759": "Пароль скинуто",
    "760": "Електронний лист - скинути пароль",
    "761": "Номер елементу",
    "762": "Номер замовлення від",
    "763": "Будь ласка, введіть номер замовлення від",
    "764": "Довжина номера замовлення",
    "765": "Будь ласка, введіть довжину номера замовлення",
    "766": "Діапазон замовлення від",
    "767": "Будь ласка, введіть діапазон замовлення від",
    "768": "Довжина діапазону замовлення",
    "769": "Будь ласка, введіть довжину діапазону замовлення",
    "770": "Please insert order piece from",
    "772": "Введіть довжину одиниці товару",
    "773": "Будь ласка, введіть довжину одиниці товару",
    "774": "Номер позиції з",
    "775": "Будь ласка, введіть номер позиції з",
    "776": "Довжина номера позиції",
    "777": "Будь ласка, введіть довжину номера позиції",
    "778": "Позиція з",
    "779": "Будь ласка, введіть позицію з",
    "780": "Довжина позиції",
    "781": "Будь ласка, введіть довжину позиції",
    "782": "Рівень безпеки",
    "783": "Символ заповнення штрих-коду",
    "784": "Будь ласка, введіть символ заповнення штрих-коду",
    "785": "Назад до проектів",
    "786": "(тільки для читання)",
    "787": "обмежений доступ",
    "788": "доступ тільки для читання",
    "789": "власний лендінг",
    "790": "Будь ласка, вставте рядок для підключення",
    "791": "DB Рядок для підключення",
    "792": "DB STD Рядок для підключення",
    "793": "DB BWG Рядок для підключення",
    "794": "Підключення до бази даних",
    "795": "Чат",
    "796": "Повідомлення",
    "797": "Сертифікати відповідності",
    "798": "Документ замовлення",
    "799": "Останній ремонт",
    "800": "Приймання завершено",
    "801": "Доступно нове оновлення, будь ласка, перезапустіть програму",
    "802": "Зовнішнє посилання",
    "803": "Префікс зовнішнього посилання",
    "804": "Посилання на проект",
    "805": "Інтервал завдань",
    "806": "Користувачі панелі управління",
    "807": "Користувачі додатку",
    "808": "Останнє завантаження",
    "809": "хвилини",
    "810": "Версія відповідника",
    "811": "Версія для імпортера",
    "812": "Вийти з системи",
    "813": "Користувач успішно вийшов з системи",
    "814": "Змінено штрих-код",
    "815": "Оновлена інформація",
    "816": "Інструкція",
    "817": "Встановити додаток",
    "818": "Встановіть додаток Window.ID, відсканувавши QR-код",
    "819": "збіглося",
    "820": "не знайдено",
    "821": "Планувальник",
    "822": "Кількість днів",
    "823": "Налаштування нагадування про технічне обслуговування.",
    "824": "дні",
    "825": "Наступне технічне обслуговування",
    "826": "Редагувати зображення",
    "827": "Без фільтра",
    "828": "Додати замовлення",
    "829": "Нове замовлення",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Назва стійки",
    "857": "Порожній",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Переключити камеру",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
};

export default ua;