import {INITIALISE_STATE,UPDATE_RACK_SEARCH_FILTER, UPDATE_RACK_OFFEN_FILTER, UPDATE_RACK_PAGE_INDEX,
    UPDATE_CURRENT_RACK, UPDATE_CURRENT_RACK_BARCODE, UPDATE_RACK_POSITION_ELEMENTS} from '../actions/types';

const initialState = {
    currentRack: {},
    currentBarcode: "",
    searchFilter: "",
    offenFilter: true,
    pageIndex:0,
    rackPositionElements: [],
}

export default function rack(state = initialState, action){
    switch (action.type) {
        case INITIALISE_STATE:
            if (!action.payload) return state;
            return Object.assign({}, state, action.payload)
        case UPDATE_CURRENT_RACK:
            return { ...state, currentRack: action.payload };
        case UPDATE_CURRENT_RACK_BARCODE:
            return { ...state, currentBarcode: action.payload };
        case UPDATE_RACK_SEARCH_FILTER:
            return {...state, searchFilter: action.payload};
        case UPDATE_RACK_OFFEN_FILTER:
            return {...state, offenFilter: action.payload};
        case UPDATE_RACK_PAGE_INDEX:
            return {...state, pageIndex: action.payload};
        case UPDATE_RACK_POSITION_ELEMENTS:
            return {...state, rackPositionElements: action.payload};

        default:
            return state;
    }
}