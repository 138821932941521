const ru = {
    "1": "Да",
    "2": "Нет",
    "3": "Тестовая версия на 30 дней",
    "4": "Получено сообщение об ошибке в приложении",
    "5": "Получение",
    "6": "Дата получения",
    "7": "Действия",
    "8": "Активный",
    "9": "Деятельность",
    "10": "Добавить",
    "11": "Добавить монтажника",
    "12": "Добавить компанию",
    "13": "Добавить дилера",
    "14": "Добавить производителя",
    "15": "Добавить управляющего недвижимостью",
    "16": "Добавить пользователя",
    "17": "Свободное поле ввода",
    "18": "Редактировать имя дополнительного поля",
    "19": "Дополнительная информация",
    "20": "Дополнительная информация",
    "21": "Дополнительные продукты",
    "22": "Настройка",
    "23": "Администратор",
    "24": "Администрирование проектов",
    "25": "Все элементы доставлены на строительную площадку",
    "26": "Все позиции установлены",
    "27": "Все права защищены",
    "28": "Вы действительно хотите удалить этого производителя?",
    "29": "Вы действительно хотите удалить пользователя?",
    "30": "Смонтировано",
    "31": "Монтажник",
    "32": "Монтажники",
    "33": "Монтаж",
    "34": "Монтаж завершен",
    "35": "Дата монтажа",
    "36": "Проекты",
    "37": "Инструкция по уходу",
    "38": "Детали сертификата",
    "39": "Сертификат(ы)",
    "40": "Очистка",
    "41": "Очистка фильтра",
    "42": "Закрыть",
    "43": "Цвет / Декор",
    "44": "Цвет / Отделка",
    "45": "Комиссия",
    "46": "Компания",
    "47": "Информация о компании",
    "48": "Информация о компании",
    "49": "Указатель компаса",
    "50": "Рекламации",
    "51": "Завершить заказ",
    "52": "Завершено",
    "53": "Окно, связанное с Smart Glass",
    "54": "Уборка строительной площадки",
    "55": "Дата создания",
    "56": "Клиент",
    "57": "Номер клиента",
    "58": "Ссылка на элемент",
    "59": "Дата",
    "60": "Интервал дат",
    "61": "Дилер",
    "62": "Удалить компанию",
    "63": "Удалить производителя",
    "64": "Удалить пользователя",
    "65": "Дата поставки",
    "66": "Доставка без повреждений",
    "67": "Описание",
    "68": "Обозначение места установки",
    "69": "Показать _MENU_ записей на странице",
    "70": "Дистрибуция",
    "71": "Документы",
    "72": "Восток",
    "73": "Редактировать монтажника",
    "74": "Редактировать компанию",
    "75": "Редактировать производителя",
    "76": "Редактировать управляющего недвижимостью",
    "77": "Редактировать пользователя",
    "78": "Ширина x Высота элемента в мм.",
    "79": "Элемент с таким ID  не существует",
    "80": "Элементы",
    "81": "Данные электронной почты сохранены",
    "82": "Укажите цвет / декор",
    "83": "Укажите комиссию",
    "84": "Укажите название компании",
    "85": "Укажите e-mail",
    "86": "Укажите расширение",
    "87": "Задайте высоту",
    "88": "Укажите ip-адрес",
    "89": "Задайте имя",
    "90": "Задайте номер",
    "91": "Укажите число позиций",
    "92": "Задайте пароль",
    "93": "Укажите телефон",
    "94": "Укажите номер телефона",
    "95": "Укажить город",
    "96": "Укажите количество",
    "97": "Укажите улицу",
    "98": "Укажите имя",
    "99": "Укажите имя пользователя",
    "100": "Укажите номер USt",
    "101": "Укажите сайт",
    "102": "Задайте ширину",
    "103": "Ошибка при сохранении данных на сервере",
    "104": "Ошибка при сохранении данных электронной почты",
    "105": "Заменить фурнитуру",
    "106": "истек ",
    "107": "истекает",
    "108": "Расширение",
    "109": "Производители",
    "110": "Завод",
    "111": "Фильтр",
    "112": "отфильтровано из _MAX_ количества записей",
    "113": "Первый",
    "114": "Отменить",
    "115": "Данные фурнитуры",
    "116": "Москитная сетка",
    "117": "Отпечаток",
    "118": "Комментарий",
    "119": "Из приложения",
    "120": "Из режима администрирования",
    "121": "Очиститель стекла",
    "122": "Замена стекла",
    "123": "Структура стекла",
    "124": "Ширина x Высота стекла в мм.",
    "125": "Обслуживание фурнитуры",
    "126": "Замена фурнитуры",
    "127": "Высота",
    "128": "Инструкция по очистке",
    "129": "Изображение",
    "130": "Информация об элементе",
    "131": "Информация ограничена",
    "132": "Вход",
    "134": "Обозначение места установки",
    "135": "Задать или сканировать штрих-код",
    "136": "IP-адрес",
    "137": "Требуется",
    "138": "Язык",
    "139": "Последний",
    "140": "Последнее техническое обслуживание",
    "141": "Последняя рекламация",
    "142": "Последний сервис",
    "143": "Ссылка на артикул",
    "144": "Загрузка",
    "145": "Все элементы соответствуют спецификации?",
    "146": "Выход",
    "147": "Данные доступа к почте",
    "148": "Техническое обслуживание",
    "149": "ручное изменение через",
    "150": "Произведено",
    "151": "Master Controller дизайн",
    "152": "Master Controller  номер материала",
    "153": "Master Controller серийный номер",
    "154": "Дата производства",
    "155": "Видимость меню",
    "156": "Автоматически подтверждать штрих-код",
    "157": "Больше информации о Rehau Sustainability",
    "158": "Новый тестовый период",
    "159": "Следующий",
    "160": "Элемент без повреждений",
    "161": "Стекло без повреждений",
    "162": "Соответствующих записей не найдено",
    "163": "Нет установленных элементов",
    "164": "Север",
    "165": "Не смонтирован",
    "166": "Ничего не найдено - извините",
    "167": "Номер",
    "168": "Количество элементов",
    "169": "Обзор проекта",
    "170": "Количество позиций",
    "171": "на",
    "172": "Дата заказа",
    "173": "Номер заказа",
    "174": "Выполнение заказа",
    "175": "Выход",
    "176": "Пароль",
    "177": "Телефон",
    "178": "Город",
    "179": "Очиститель пластика",
    "180": "Документы по позициям",
    "181": "Детали элемента позиции",
    "182": "Позиции с таким ID не существует",
    "183": "Позиции",
    "184": "Предыдущий",
    "185": "Регистрационные данные",
    "186": "Печать данных для входа",
    "187": "Редактируется",
    "188": "Производитель",
    "189": "Профильная система",
    "190": "Проект",
    "191": "Номер проекта",
    "192": "Управление домохозяйством",
    "193": "Количество",
    "194": "Рекламация",
    "195": "Выберите станцию",
    "196": "Уровень переработки отходов",
    "197": "Ремонт",
    "198": "Ремонт аксессуаров",
    "199": "Ремонт поверхности",
    "200": "Получено из приложения",
    "201": "Сообщено через администратора",
    "202": "Возобновить тестовый период",
    "203": "Результат",
    "204": "Роль",
    "205": "Сохранить",
    "206": "Успешно сохранено",
    "207": "Замена уплотнителя",
    "208": "Поиск",
    "209": "Уровень безопасности",
    "210": "Отправить данные в REHAU",
    "211": "Сервис",
    "212": "Сервис и продажи",
    "213": "Сервис ",
    "214": "Настройки",
    "215": "Нужно ли сейчас вводить ручную общую приемку (или техническое обслуживание, ремонт)?",
    "216": "Показано от _START_ до _END_ из _TOTAL_ записей",
    "217": "Показано от 0 до 0 из 0 записей",
    "218": "Жалюзи",
    "219": "Вход",
    "220": "Войдите в систему чтобы начать",
    "221": "Информация о Smart Glass",
    "222": "Smart окно",
    "223": "Некоторые элементы смонтированы",
    "224": "Некоторые элементы не соответствуют",
    "225": "Некоторые позиции требуют сборки",
    "226": "Юг",
    "227": "Статистика",
    "228": "Улица",
    "229": "Фамилия",
    "230": "Устойчивость и энергия ",
    "231": "Телефон",
    "232": "Тестовый период успешно сброшен",
    "233": "Тестовый период (ы)",
    "234": "Свойства были сохранены",
    "235": "Есть рекламации",
    "236": "Не существует компании с таким ID",
    "237": "Нет информации об этом элементе",
    "238": "Эта компания уже используется",
    "239": "Заголовок",
    "240": "до",
    "241": "Принять полностью",
    "242": "Всего",
    "243": "Неизвестен",
    "244": "Загрузить логотип",
    "245": "Управление пользователями",
    "246": "Имя пользователя",
    "247": "Пользователь уже задан",
    "248": "Пользователи",
    "249": "Теплопроводность (коэффициент теплопередачи)",
    "250": "Коэффициент теплопередачи рамы",
    "251": "Коэффициент теплопередачи стекла",
    "252": "W.ID присвоен",
    "253": "Внимание, не повредите идентификатор окна",
    "254": "Добро пожаловать",
    "255": "Запад",
    "256": "Ширина",
    "257": "Ширина х Высота в мм",
    "258": "Площадь окна от",
    "259": "Площадь окна в",
    "260": "Window Electronic номер материала",
    "261": "Window Electronic серийный номер",
    "262": "Место установки окна",
    "263": "Оконная система",
    "264": "Тип окна",
    "265": "Замена створки",
    "266": "Вы уверены?",
    "267": "Очистить фильтр",
    "268": "Редактировать",
    "269": "Экспорт CSV",
    "270": "Считыватель штрих-кода",
    "271": "ID стекла",
    "272": "Информация",
    "273": "Ссылка",
    "274": "Связанные пользователи",
    "275": "Электронная почта",
    "276": "Настройки электронной почты",
    "277": "Тестовый период электронной почты",
    "278": "Название заказа на обслуживание",
    "279": "Сервисная компания",
    "280": "Номер служебного телефона",
    "281": "Служебная электронная почта",
    "282": "Статус",
    "283": "Настройки станции",
    "284": "Тема",
    "285": "Телефон",
    "286": "Пользователь",
    "287": "Веб-сайт",
    "288": "ID окна",
    "289": "Оконная система",
    "290": "Информация о клиенте",
    "291": "Имя",
    "292": "Структура стекла",
    "293": "Детали оборудования",
    "294": "Название монтажника",
    "295": "Компания-монтажник",
    "296": "Телефон монтажника",
    "297": "Электронная почта монтажника",
    "298": "URL",
    "299": "Страна",
    "300": "Пожалуйста, добавьте пользователя для этой компании",
    "301": "Дополнительное меню",
    "302": "Обзор",
    "303": "Приложение",
    "304": "Дизайн посадочной страницы",
    "305": "Управление пользователями",
    "306": "Подтвердить",
    "307": "Тело письма",
    "308": "Приложение",
    "309": "ID",
    "310": "Местонахождение",
    "311": "Предупреждение",
    "312": "W.ID",
    "314": "Дилеры",
    "315": "Активировать",
    "316": "Деактивировать",
    "317": "Компания",
    "318": "Извините",
    "319": "Администратор",
    "320": "Пользователь",
    "321": "Почтовый индекс",
    "322": "Логотип",
    "323": "Обои",
    "324": "Регистрационный номер плательщика НДС",
    "325": "Сертификаты соотвествия",
    "326": "Установки приложения",
    "327": "Автоматически",
    "328": "Ручной",
    "329": "Просмотр",
    "330": "Загрузить",
    "331": "Автоматическая загрузка документов",
    "332": "Исходная папка",
    "333": "Выберите путь к папке для автоматической загрузки документов.",
    "334": "Пожалуйста укажите zip файл, содержащий PPS XML файл, XML-файл с данными покупателя и изображения позиций",
    "335": "Выбранная станция",
    "336": "Сканировать вручную",
    "337": "Станция smart стекла",
    "338": "Передать информацию",
    "339": "Максимальное число позиций во временном диапазоне",
    "340": "QR-код от",
    "341": "QR-код к",
    "342": "Префикс QR-кода",
    "343": "Длина QR-кода",
    "344": "Данные штрих-кода недействительны.",
    "345": "Ключ Api",
    "346": "введите qrcode",
    "347": "Имя станции",
    "348": "Файл не выбран",
    "349": "Файл загружен",
    "350": "Ошибка загрузки файла",
    "351": "Включено ручное сканирование",
    "352": "Пожалуйста, введите qrcode",
    "353": "Штрих-код недействителен",
    "354": "Штрих-код импортирован",
    "356": "Нет данных",
    "357": "Не удалось установить соединение",
    "358": "История",
    "359": "Штрих-код используется.",
    "360": "Ошибка при сопоставлении штрихкода",
    "361": "Вручную",
    "362": "С сайта",
    "363": "До",
    "364": "Длина префикса QR-кода должна быть меньше длины QR-кода",
    "365": "Диапазон штрих-кода недействителен",
    "366": "Недействительные номера",
    "367": "Использовалось ранее",
    "368": "Используется сегодня",
    "369": "Бесплатно",
    "370": "Использовался до сегодняшнего дня",
    "371": "Использование номеров QR-кодов для текущего диапазона станций",
    "372": "Переводы",
    "373": "Редактировать перевод",
    "374": "Ключ",
    "375": "Английский",
    "376": "Значение",
    "377": "Назначить W.ID",
    "378": "Расположение окон и дверей в проекте",
    "379": "Услуги по ремонту / подтверждение ремонта",
    "380": "Техническое обслуживание и история",
    "381": "Отчет об установке",
    "382": "Модификация",
    "383": "Заменить стекло",
    "384": "Заменить створку",
    "386": "Комментарии",
    "387": "Ремонт завершен",
    "388": "Новые функции",
    "389": "Журнал технического обслуживания",
    "390": "Обслуживание оборудования",
    "391": "Назначьте время обслуживания",
    "392": "Полный список проектов",
    "393": "Информация об окнах",
    "394": "Детали установки",
    "395": "Цвет",
    "396": "Ширина элемента х высота в мм",
    "397": "Ширина х высота стекла в мм",
    "398": "Информация о монтаже",
    "399": "Имя установщика",
    "400": "Дата / время установки",
    "401": "Место установки (GPS)",
    "402": "Идентификатор устройства",
    "403": "отправить",
    "404": "Положение фильтра",
    "405": "Нет",
    "406": "Балконные двери",
    "407": "Кухня",
    "408": "Ванная комната",
    "409": "Комната",
    "410": "Крыша",
    "411": "Установка",
    "412": "Положение",
    "413": "Приложить фотографию",
    "414": "Подпись",
    "415": "Чертеж",
    "416": "Элемент без повреждений",
    "417": "Стеклопакет без подтверждений",
    "418": "Открыть камеру",
    "419": "Загрузить сушествующее изображение",
    "420": "Установить и назначить окно",
    "421": "Протокол приемки",
    "422": "Отправить",
    "423": "Общие строительный проект",
    "424": "Тип монтажа",
    "425": "Применимо для элемента",
    "426": "Завершить заказ",
    "427": "Протокол приемки монтажа одного окна",
    "428": "Проблема",
    "429": "Помощь",
    "430": "Сообщение",
    "431": "Отправить",
    "432": "Следующий элемент",
    "433": "Список всех элементов",
    "434": "Планирование изготовления",
    "435": "через номер позиции",
    "436": "с QR кодом",
    "437": "Показать один",
    "438": "или",
    "439": "Номер штрих-кода",
    "440": "Показать информацию",
    "441": "Ремонт/Рекламация",
    "442": "Показать контактное лицо",
    "443": "Отзыв",
    "444": "Сканировать другой QR-код",
    "445": "Следующее обслуживание",
    "446": "Поставщик окна",
    "447": "Свойства",
    "448": "Значения звукоизоляции",
    "449": "Уф",
    "450": "Возможность развития",
    "451": "Контакт",
    "452": "Ваш ID окна",
    "453": "Что вы хотите сделать?",
    "454": "Регулярное обслуживание окон продлевает срок службы и повышает комфорт",
    "455": "Запрос на техническое обслуживание через контактную форму",
    "456": "Запрос на техническое обслуживание по телефону",
    "457": "вашему монтажнику окна",
    "458": "Настоящим я подтверждаю общие положения и условия и положения по Персональным Данным",
    "459": "Ваша проблема",
    "461": "Контакт через…",
    "462": "Дефект стекла",
    "463": "Окно не закрывается верно",
    "464": "Окно заклинило",
    "465": "Окно повреждено",
    "466": "Другие принадлежности",
    "467": "Ваши окна и двери будут на",
    "468": "смонтировано этой фирмой",
    "469": "Производитель окна",
    "470": "Оцените сейчас ваше окно и монтаж",
    "471": "Насколько вы удовлетворены сервисом фирмы?",
    "472": "Поставленные продукты в порядке?",
    "473": "Как вам понравились ваши окна?",
    "474": "Были ли проблемы при обработке? ",
    "475": "Положительные стороны",
    "476": "Чтобы вы улучшили?",
    "477": "Руководство по монтажу",
    "478": "Позиции окон и дверей",
    "479": "Открыть протокол монтажа",
    "480": "Список всех элементов в заказе",
    "481": "Список элементов по номер / QR",
    "482": "Отправить протокол проекта",
    "483": "Детали окна",
    "484": "Одна деталь",
    "485": "Номер строительного проекта",
    "486": "Окно",
    "487": "Позиция окна",
    "488": "Сервис ремонта",
    "489": "Детали обслуживания",
    "490": "Конечный покупатель",
    "491": "Контактная форма",
    "492": "Найти специалиста Рехау рядом с вами",
    "493": "Категория",
    "494": "Сканирование QR-кода",
    "495": "Контактное лицо",
    "496": "актуализировать",
    "497": "Фильтр",
    "498": "Завершить монтаж",
    "499": "Монтаж завершен?",
    "500": "Поставка без повреждений",
    "501": "Сохранить как PDF",
    "502": "Сделать фото",
    "503": "Неверный электронный почтовый адрес",
    "504": "Установить приложение",
    "505": "Место",
    "506": "Список проектов",
    "507": "Загрузить выбранный заказ?",
    "508": "Неверный номер заказа / штрих-код",
    "509": "Одно окно",
    "510": "Применить изменения",
    "511": "Отправить отчет по электронной почте",
    "512": "Пожалуйста укажите четыре опции",
    "513": "Теперь отсканируйте QR-код, чтобы узнать, в каком месте здания должен быть установлен этот элемент.",
    "514": "Сохранить данные",
    "515": "Указатель",
    "516": "Результат",
    "517": "Страницу",
    "518": "от",
    "519": "Дата с",
    "520": "Дата по",
    "521": "Открыть",
    "522": "Размер",
    "523": "Смонтировано ",
    "524": "Успешно",
    "525": "Информация",
    "526": "Загрузить новый заказ",
    "527": "ID монтажника",
    "528": "Войти",
    "529": "Настоящим я подтверждаю общие положения и условия и положения по Персональным Данным",
    "530": "Выйти",
    "531": "Сканнер",
    "532": "Отправить протокол",
    "533": "Отправить протокол приемки?",
    "534": "Протокол ремонта",
    "535": "Опция 5",
    "536": "Опция 6",
    "537": "Опция 7",
    "538": "Опция 8",
    "539": "Опция 9",
    "540": "Опция 10",
    "541": "Почта отправлена",
    "542": "Ошибка отправления почты",
    "543": "Смажьте фурнитуру",
    "544": "Скорректированный",
    "545": "Очищенный",
    "546": "Уплотненный",
    "547": "Замена фурнитуры",
    "548": "Комментарий",
    "549": "Успешная отправка всех элементов на строительную площадку",
    "550": "Расположение элементов по внутри строительного проекта",
    "551": "Ошибка отправки протокола",
    "552": "Сохранить данные протокола",
    "553": "Информация сохранена",
    "554": "Ошибка сохранения данных",
    "555": "Информация о Smart окне",
    "556": "Принять Smart окно",
    "557": "Электронный номер материала окна",
    "558": "Серийный номер материала окна",
    "559": "Сканируйте QR-код (DMC) с мастер контроллера или введите его вручную",
    "560": "Smart окно полностью настроено и функционирует",
    "561": "Все видимые элементы без повреждений",
    "562": "Была ли подключена система управления 0-10В (0-100%)?",
    "563": "Местоположение здания",
    "564": "Начать сканирование",
    "565": "Остановить сканирование",
    "566": "Выбранные элемент",
    "567": "Одновременно могут быть выбраны только 4 аттрибута",
    "568": "Позиция клиента",
    "569": "Дата производства",
    "570": "Window.ID изменять",
    "571": "Предыдущее Window.ID",
    "572": "Новое Window.ID",
    "573": "Номер заказа не существует, или новый номер заказа уже используется.",
    "574": "Window.ID",
    "575": "Идентификатор позиции",
    "576": "Тип",
    "577": "Идентификатор элемента",
    "578": "Информация об элементе",
    "579": "Window.ID",
    "580": "Пожалуйста, подпишите в отведенном месте",
    "581": "Законченный",
    "582": "Подпишитесь",
    "583": "Новый язык",
    "584": "Язык оригинала",
    "585": "Название языка",
    "586": "Создать новый язык",
    "587": "Добавить язык",
    "588": "Обновить",
    "589": "обновление в процессе",
    "590": "Деактивация компании",
    "591": "Активация компании",
    "592": "Вы уверены, что хотите деактивировать эту компанию?",
    "593": "Вы уверены, что хотите активировать эту компанию?",
    "594": "Деактивация пользователя",
    "595": "Активация пользователя",
    "596": "Вы уверены, что хотите деактивировать этого пользователя?",
    "597": "Вы уверены, что хотите активировать этого пользователя?",
    "598": "ID Позиция",
    "599": "Все",
    "600": "Импорт/экспорт",
    "601": "Пожалуйста, выберите языковой файл .csv для импорта",
    "602": "Импорт",
    "603": "Экспорт",
    "604": "Файл",
    "605": "Переводчик",
    "606": "Файлы штрихкодов",
    "607": "Выберите путь к папке для файлов штрихкодов.",
    "608": "Идентификатор_окна",
    "609": "Система профилей",
    "610": "Положение клиента",
    "611": "Цвет",
    "612": "Размер элемента",
    "613": "Размер стекла",
    "614": "Структура стекла",
    "615": "Информация об оборудовании",
    "616": "Дата сопоставления",
    "617": "Дата/время установки",
    "618": "GPS-данные о месте установки",
    "619": "Номер:",
    "620": "Установка:",
    "621": "Этаж:",
    "622": "Позиция:",
    "623": "Игнорировать начальный символ(ы)",
    "624": "Игнорировать конечный символ(ы)",
    "625": "Электронная почта - активация пользователя",
    "626": "Активировать компании",
    "627": "Срок действия пробной версии истек",
    "628": "Повторно отправить письмо с активацией",
    "629": "Дилер/фабрика уже существует",
    "630": "Пользователь активирован",
    "631": "Перейдите на страницу входа в систему",
    "632": "Информация о пользователе",
    "633": "Добавить связанного пользователя",
    "634": "Удалить ссылку на пользователя",
    "635": "Пользователь уже связан",
    "636": "Имя пользователя или пароль пустые",
    "637": "Пользователь не найден",
    "638": "Отправить протокол",
    "639": "Сборка завершена",
    "640": "Вариант 5",
    "641": "Вариант 6",
    "642": "Вариант 7",
    "643": "Вариант 8",
    "644": "Вариант 9",
    "645": "Вариант 10",
    "646": "Ассемблер",
    "647": "Тип",
    "648": "Дата",
    "649": "Описание",
    "650": "Обслуживание оборудования",
    "651": "Установить",
    "652": "Очистить",
    "653": "Уплотнение",
    "654": "Сменные аксессуары",
    "655": "Комментарий",
    "656": "Соединитель",
    "657": "Оконная система",
    "658": "Деталь",
    "659": "Номер проекта",
    "660": "Идентификатор заказа",
    "661": "Высота",
    "662": "Ширина",
    "663": "Информация о фурнитуре",
    "664": "Ширина стекла х высота",
    "665": "Структура стекла",
    "666": "ID окна",
    "667": "ID электрической части окна",
    "668": "ID стекла",
    "669": "Условия",
    "670": "Добавить/удалить",
    "671": "И/Или",
    "672": "Группа",
    "673": "Колонка",
    "674": "Условие",
    "675": "Введите номер клиента",
    "676": "Переводчик",
    "678": "Контроль версий приложения",
    "679": "Обновление может занять несколько минут",
    "680": "Ожидание новой версии",
    "681": "Вышла новая версия приложения, вы уверены, что хотите обновить ее?",
    "682": "Приложение обновлено, хотите ли вы восстановить старую версию?",
    "683": "Восстановить старую версию",
    "684": "Вставьте название станции",
    "685": "Номер заказа char filler",
    "686": "Пожалуйста, введите номер заказа",
    "687": "Максимальная длина номера заказа",
    "688": "Пожалуйста, вставьте номер заказа максимальной длины",
    "689": "Путь к папке MDB",
    "690": "Укажите путь к файлу .mdb (используется импортером при сканировании на наличие файлов .mdb)",
    "691": "Путь к папке XML",
    "692": "Укажите путь к файлу .xml (используется импортером при сканировании файлов .xml)",
    "693": "Игнорировать начальный символ(ы) QR-кода",
    "694": "Игнорировать завершающий символ (символы) QR-кода",
    "695": "Назначение",
    "696": "Продолжительность",
    "697": "Дополнительное положение",
    "698": "Умное окно без повреждений",
    "699": "Подключенное смарт-окно",
    "700": "Ориентация окна",
    "701": "Номер элемента позиции",
    "702": "Умное окно настроено",
    "703": "Элемент без повреждений",
    "704": "Номер материала",
    "705": "Серийный номер",
    "706": "Нет изображения",
    "707": "Чистая строительная площадка",
    "708": "Перетащите сюда изображения/файлы pdf",
    "709": "Загрузка файла",
    "710": "Тип файла",
    "711": "Заказ (все позиции)",
    "712": "Удалено",
    "713": "Примечания к выпуску",
    "714": "Высота (мм)",
    "715": "Ширина (мм)",
    "716": "Для этой позиции нет панели",
    "717": "Артикул",
    "718": "Станция подачи сыпучих материалов",
    "719": "Диапазон сыпучих материалов",
    "720": "Окно.ID (последнее)",
    "721": "Стекло было заменено",
    "722": "Фурнитура была отремонтирована",
    "723": "Створка была заменена",
    "724": "Поверхность была отремонтирована",
    "725": "Отремонтированы аксессуары",
    "726": "Ремонт завершен",
    "727": "Подтвердите пароль",
    "728": "Неверный пароль",
    "729": "Пароли не совпадают",
    "730": "Пароль не может быть пустым",
    "731": "Местоположение",
    "732": "Время",
    "733": "U-значение (Вт/м²K)",
    "734": "Специальное стекло",
    "735": "Нет позиции",
    "736": "Ход производства",
    "737": "Производство завершено",
    "738": "Ход монтажа",
    "739": "Установка завершена",
    "740": "Станция удалена",
    "741": "Вы действительно хотите удалить эту станцию?",
    "742": "Добавить новую станцию",
    "743": "Удалить заказ",
    "744": "Удаление заказа",
    "745": "Вы уверены, что хотите удалить заказ?",
    "746": "Пользователь добавил",
    "747": "Пользователь отредактирован",
    "748": "Забыли пароль?",
    "749": "Поле Пароль обязательно для заполнения",
    "750": "Поле Имя пользователя является обязательным",
    "751": "Этот пользователь не авторизован для входа в приборную панель",
    "752": "Пользователь не активен",
    "753": "Компания пользователя не активна или закончился тестовый период",
    "754": "Имя пользователя или пароль неверны",
    "755": "Почтовый индекс",
    "756": "Используйте колесо прокрутки для увеличения или уменьшения изображения",
    "757": "Сбросить пароль",
    "758": "Отправлено электронное письмо для сброса пароля",
    "759": "Пароль был сброшен",
    "760": "Электронная почта - Сброс пароля",
    "761": "Номер элемента",
    "762": "Номер заказа от",
    "763": "Пожалуйста, введите номер заказа от",
    "764": "Длина номера заказа",
    "765": "Пожалуйста, укажите длину номера заказа",
    "766": "Диапазон заказа от",
    "767": "Пожалуйста, укажите диапазон заказа от",
    "768": "Длина диапазона заказов",
    "769": "Пожалуйста, укажите длину диапазона заказа",
    "770": "Please insert order piece from",
    "772": "Длина заказанной детали",
    "773": "Пожалуйста, укажите длину заказанной детали",
    "774": "Номер позиции из",
    "775": "Пожалуйста, введите номер позиции от",
    "776": "Длина номера позиции",
    "777": "Пожалуйста, вставьте длину номера позиции",
    "778": "Артикул позиции от",
    "779": "Пожалуйста, вставьте номер позиции из",
    "780": "Длина фрагмента позиции",
    "781": "Пожалуйста, вставьте длину позиционного элемента",
    "782": "Уровень безопасности",
    "783": "Символ-заполнитель штрихкода",
    "784": "Пожалуйста, вставьте символ заполнения штрихкода",
    "785": "Назад к проектам",
    "786": "(только чтение)",
    "787": "ограниченный доступ",
    "788": "доступ только для чтения",
    "789": "собственная целевая страница",
    "790": "Пожалуйста, вставьте строку подключения",
    "791": "Строка подключения к БД",
    "792": "Строка подключения к БД STD",
    "793": "Строка подключения к БД BWG",
    "794": "Соединение с базой данных",
    "795": "Чат",
    "796": "Сообщения",
    "797": "Сертификаты соотвествия",
    "798": "Документ заказа",
    "799": "Последний ремонт",
    "800": "Приемка завершена",
    "801": "Доступно новое обновление, пожалуйста, перезапустите приложение",
    "802": "Внешняя ссылка",
    "803": "Префикс внешней ссылки",
    "804": "Ссылка на проект",
    "805": "Интервал между задачами",
    "806": "Пользователи приборной панели",
    "807": "Пользователи приложения",
    "808": "Последняя загрузка",
    "809": "минуты",
    "810": "Версия метчера",
    "811": "Версия импортера",
    "812": "Выход пользователя из системы",
    "813": "Пользователь успешно вышел из системы",
    "814": "Штрих-код изменен",
    "815": "Обновленная информация",
    "816": "Направления",
    "817": "Установить приложение",
    "818": "Установите приложение Window.ID APP, отсканировав QR-код",
    "819": "совпадает",
    "820": "не соответствует",
    "821": "Планировщик",
    "822": "Количество дней",
    "823": "Настройки для напоминания об обслуживании",
    "824": "дней",
    "825": "Следующее обслуживание",
    "826": "Редактировать изображение",
    "827": "Без фильтра",
    "828": "Добавить заказ",
    "829": "Новый заказ",
    "830": "Ордер уже вышел",
    "831": "Позиция уже существует",
    "832": "Добавить новый элемент",
    "833": "Добавить новое поле",
    "834": "Удалить позицию",
    "835": "Редактировать позицию",
    "836": "Добавить новую позицию",
    "837": "Вы уверены, что хотите удалить эту позицию?",
    "838": "Язык по умолчанию",
    "839": "Описание обязательно",
    "840": "Номер обязателен",
    "841": "Порядок редактирования",
    "842": "Window.ID является обязательным",
    "843": "Window.ID уже существует",
    "844": "Для этой позиции нет элемента",
    "845": "Нет поля позиции",
    "846": "Добавить/удалить элементы",
    "847": "Добавить/удалить поля",
    "848": "Поле с таким идентификатором не существует",
    "849": "Тип поля",
    "850": "Цвет поля",
    "851": "Стекло",
    "852": "Поиск по идентификатору окна",
    "853": "Перейти к элементу позиции",
    "854": "Скопировать из элемента по умолчанию",
    "855": "Rack.ID",
    "856": "Имя стойки",
    "857": "Пустой",
    "858": "Управление стойкой",
    "859": "Доставка завершена без повреждений.",
    "860": "Доставка не завершена.",
    "861": "Поставка завершена, но некоторые элементы повреждены.",
    "862": "Проверено наличие окон в этой стойке",
    "863": "Добавить новую стойку",
    "864": "Редактировать стойку",
    "865": "Удалить стойку",
    "866": "Вы уверены, что хотите удалить стойку?",
    "867": "Имя стойки",
    "868": "Rack.ID",
    "869": "Ссылка на стойку",
    "870": "Стойка используется (закрыта)",
    "871": "Стойка открыта",
    "872": "Стойка свободна",
    "873": "Состояние по умолчанию",
    "874": "Элементы стойки",
    "875": "Станция стойки",
    "876": "Стойка",
    "877": "с идентификатором ",
    "878": "закрыта",
    "879": "Вы проверили",
    "880": "окна",
    "881": "открывается станцией",
    "882": "Не удалось открыть стеллаж",
    "883": "Пожалуйста, закройте стеллаж",
    "884": "Сканировать окно.ID",
    "885": "Сканировать Rack.ID",
    "886": "Сканирование Rack.ID для запуска",
    "887": "Стойка недоступна",
    "888": "Ошибка",
    "890": "Окно.ID не сканируется",
    "891": "Этот элемент уже отсканирован",
    "892": "Этот элемент отсканирован на стойке ",
    "893": "Вы уверены, что хотите загрузить файл?",
    "894": "Опустошить стойку?",
    "895": "Переключить камеру",
    "896": "Сканирование предыдущего",
    "897": "Сканирование нового",
    "898": "Требуется дилер",
    "899": "Добавить изображение"
};

export default ru;