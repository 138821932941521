
export const getUrl = () => {
  if(window.location.href.indexOf("localhost") != -1) return "https://localhost:44383";
  if(window.location.href.indexOf("/windowid.test.grogot.com") != -1) return "https://windowidapi.test.grogot.com";
  if(window.location.href.indexOf("//windowid.rehau.com") != -1) return "https://windowid.api.rehau.com";
  if(window.location.href.indexOf("//windowid.demo.rehau.com") != -1) return "https://windowid.api.demo.rehau.com";
  if(window.location.href.indexOf(".demo") != -1) return "https://windowid.api.demo.rehau.com";
  return "https://windowid.api.rehau.com";
}

export const getDashboardUrl = () => {
  if(window.location.href.indexOf("localhost") != -1) return "https://localhost:5001/info/element/";
  if(window.location.href.indexOf("/windowid.test.grogot.com") != -1) return "https://windowid.dashboard.test.grogot.com/info/element/";
  if(window.location.href.indexOf("//windowid.rehau.com") != -1) return "https://windowid.dashboard.rehau.com/info/element/";
  if(window.location.href.indexOf("//windowid.demo.rehau.com") != -1) return "https://windowid.dashboard.demo.rehau.com/info/element/";
  if(window.location.href.indexOf(".demo") != -1) return "https://windowid.dashboard.demo.rehau.com/info/element/";
  return "https://windowid.dashboard.rehau.com/info/element/";
}

//EDIS TEST DEPLOYMENT

const getRequestOption = (method, data, useContentType) => {
    let jwtToken = this.getJwtToken();
    const requestOption = {
      "crossDomain": true,
      "method": method,
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwtToken
      },
      "credintials": "include",
      // "body": JSON.stringify({ ...data })
    }
    if (useContentType) requestOption.headers["content-type"] = "application/json; charset=utf-8";
    if (method === "POST") requestOption.body = JSON.stringify({ ...data });
    return requestOption;
  };

  export const getDataFromDb = async (url, data, settings) => {

    let apiUrl = getUrl();
    let response = await fetch(`${apiUrl}${url}`, getRequestOption("POST", data, true))
    if (response.ok) {
      let res = await response.json();
      //nije potrebno odraditi update lokalne baze za svaki request. Ukoliko postoji property skipUpdate, preskoči update, vrati rezultat request-a.
      //if (!data.skipDbUpdate) await this.updateLocalDb(res);
      return res;
    }
}
