import React, { lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { SW_INIT, SW_UPDATE } from './actions/types';
import { Provider } from 'react-redux';
import ErrorHandling from '../src/components/Reusable/ErrorHandling';
import { NotificationContainer } from 'react-notifications';
import { LanguageProvider } from './components/extensions/LanguageContainer';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const App = lazy(() => import('./App'));

const store = configureStore();
const persistor = persistStore(store);

const loader = () => {
  return <div className="AppLoader">
     <h1>Window.ID APP</h1>
     <div className="snippet" data-title=".dot-flashing">
          <div className="stage">
            <div className="dot-flashing"></div>
          </div>
        </div>

  </div>
}
ReactDOM.render(<Provider store={store}>
  <ErrorHandling>
    <LanguageProvider>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={loader()}>
          <App />
        </Suspense>
      </PersistGate>
    </LanguageProvider>
  </ErrorHandling>
  <NotificationContainer />
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: registration =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
  onWaiting: waiting =>
    store.dispatch({ type: SW_UPDATE, payload: waiting })
});
