var signatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    Qk:"image/png",
    PHN2Z: "image/svg+xml",
    "/9j/": "image/jpg",
    UEsDBBQ: "text/csv",
  };

export const PrepareB64 =(b64) => {
		let mimetype = "";
		for (var s in signatures) {
			if (b64.indexOf(s) === 0) {
				mimetype = signatures[s];
			}
		}

		if (mimetype === "") return "../../../img/no-image.png";
		else return `data:${mimetype};base64,${b64}`;
}

export const getMimeType = (b64) => {
  let mimetype = "";
  for (var s in signatures) {
    if (b64.startsWith(s)) {
      mimetype = signatures[s];
      return mimetype.replace("/","_");
    }
  }
  
}