import { combineReducers } from 'redux';

import appReducer from './AppReducer';
import authReducer from './AuthReducer';
import taskReducer from './TaskReducer';
import rackReducer from './RackReducer';

export default combineReducers({
    app: appReducer,
    authentication: authReducer,
    task: taskReducer,
    rack: rackReducer
});