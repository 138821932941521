const en = {
    "1": "Yes",
    "2": "No",
    "3": "30-Days test version",
    "4": "A complaint was reported via the app",
    "5": "Acceptance",
    "6": "Acceptance date",
    "7": "Action",
    "8": "Active",
    "9": "Activities",
    "10": "Add",
    "11": "Add installer",
    "12": "Add company",
    "13": "Add dealer/installer",
    "14": "Add fabricator",
    "15": "Add property management",
    "16": "Add User",
    "17": "Additional field",
    "18": "Additional field name edit",
    "19": "Comments",
    "20": "Additional Information",
    "21": "Additional products",
    "22": "Replace hardware",
    "23": "Admin",
    "24": "Administrative view",
    "25": "All elements have been delivered to site?",
    "26": "All positions are installed",
    "27": "All rights reserved.",
    "28": "Are you sure you want to delete this fabricator?",
    "29": "Are you sure you want to delete this user?",
    "30": "Installed",
    "31": "Installer",
    "32": "Installers",
    "33": "Assembly",
    "34": "Installation completed",
    "35": "Installation date",
    "36": "Assignments",
    "37": "Care instructions",
    "38": "Certificate details",
    "39": "Certificate(s)",
    "40": "Replace sash ",
    "41": "Clear",
    "42": "Close",
    "43": "Colour",
    "44": "Colour/Finish",
    "45": "Commission",
    "46": "Company",
    "47": "Company details",
    "48": "Company info",
    "49": "Compass direction",
    "50": "Complaints",
    "51": "Project complete",
    "52": "Completed",
    "53": "Connected Window",
    "54": "Construction site clean",
    "55": "Creation date",
    "56": "End user",
    "57": "Customer number",
    "58": "Element reference",
    "59": "Date",
    "60": "Date range",
    "61": "Dealer/Installer",
    "62": "Delete company",
    "63": "Delete Fabricator",
    "64": "Delete User",
    "65": "Delivery date",
    "66": "Delivered without damage",
    "67": "Description",
    "68": "Designated place of installation",
    "69": "Display _MENU_ records per page",
    "70": "Distribution",
    "71": "Documents",
    "72": "East",
    "73": "Edit installer",
    "74": "Edit company",
    "75": "Edit dealer",
    "76": "Edit property management",
    "77": "Edit User",
    "78": "Element width x height",
    "79": "Element with this id does not exist",
    "80": "Elements",
    "81": "Email data saved.",
    "82": "Enter colour",
    "83": "Enter commission",
    "84": "Enter company name",
    "85": "Enter email",
    "86": "Enter extension",
    "87": "Enter height",
    "88": "Enter IP Address",
    "89": "Enter name",
    "90": "Enter number",
    "91": "Enter number of positions",
    "92": "Enter password",
    "93": "Enter Phone",
    "94": "Enter phone number",
    "95": "Enter Town",
    "96": "Enter quantity",
    "97": "Enter Street",
    "98": "Enter surname",
    "99": "Enter username",
    "100": "Enter Account ID",
    "101": "Enter Website",
    "102": "Enter width",
    "103": "Error while saving data to server.",
    "104": "Error while saving email data.",
    "105": "Repair accessories",
    "106": "expired at",
    "107": "expires at",
    "108": "Extension",
    "109": "Fabricator",
    "110": "Factory",
    "111": "Search",
    "112": "filtered from _MAX_ total records",
    "113": "First",
    "114": "Cancel",
    "115": "Hardware information",
    "116": "Fly screens",
    "117": "Footprint",
    "118": "Comments",
    "119": "From App",
    "120": "From administration view",
    "121": "Glass cleaner",
    "122": "Replace glass",
    "123": "Glass structure",
    "124": "Glass width x height",
    "125": "Hardware maintenance",
    "126": "Replace hardware",
    "127": "Height",
    "128": "How to clean",
    "129": "Image",
    "130": "Information about element",
    "131": "Information is restricted",
    "132": "Position documents",
    "134": "Installation location",
    "135": "enter barcode",
    "136": "IP Address",
    "137": "is required",
    "138": "Language",
    "139": "Last",
    "140": "Last maintenance",
    "141": "Last complaint",
    "142": "Last service",
    "143": "Link to article",
    "144": "Loading",
    "145": "All elements as per specification?",
    "146": "Log Out",
    "147": "Email - Access data",
    "148": "Maintenance",
    "149": "Changed by",
    "150": "Manufactured",
    "151": "Master controller design",
    "152": "Master Controller material number",
    "153": "Master Controller serial number",
    "154": "Matched Date",
    "155": "Menu visibility",
    "156": "Auto confirm barcode",
    "157": "Landing page documents ",
    "158": "New test period",
    "159": "Next",
    "160": "No damage to the element",
    "161": "No damage to the glass",
    "162": "No matching records found",
    "163": "None of the elements are installed",
    "164": "North",
    "165": "Not installed",
    "166": "Nothing found - sorry",
    "167": "Number",
    "168": "Number of elements",
    "169": "Project overview",
    "170": "Number of positions",
    "171": "on",
    "172": "Order creation date",
    "173": "Project number",
    "174": "Project progress",
    "175": "Output",
    "176": "Password",
    "177": "Phone",
    "178": "Town",
    "179": "PVC cleaner",
    "180": "Element Doc.",
    "181": "Position element details",
    "182": "Position with this id does not exist",
    "183": "Positions",
    "184": "Previous",
    "185": "Primary user credentials",
    "186": "Print login data",
    "187": "Processing",
    "188": "Fabricator",
    "189": "Profile system",
    "190": "Project",
    "191": "Project number",
    "192": "Property management",
    "193": "Quantity",
    "194": "Complaint",
    "195": "Please select station",
    "196": "Recycled content",
    "197": "Repair",
    "198": "Repair accessories",
    "199": "Repair surface",
    "200": "Reported from App",
    "201": "Reported from management view",
    "202": "Reset test period",
    "203": "Result",
    "204": "Role",
    "205": "Save",
    "206": "Saved successfully.",
    "207": "Clean surface",
    "208": "Search",
    "209": "Security level",
    "210": "Send data to REHAU",
    "211": "Service",
    "212": "Service & sales",
    "213": "Service provider",
    "214": "Settings",
    "215": "Should a manual overall acceptance or maintenance",
    "216": "Showing _START_ to _END_ of _TOTAL_ records",
    "217": "Showing 0 to 0 of 0 records",
    "218": "Shutters",
    "219": "Sign In",
    "220": "Sign in to start your session",
    "221": "Smart Glass Information",
    "222": "Smart Window",
    "223": "Some elements are installed",
    "224": "Some elements are not matched",
    "225": "Some position (s) need installing",
    "226": "South",
    "227": "Statistics",
    "228": "Street",
    "229": "Surname",
    "230": "Sustainability & Energy",
    "231": "Telephone",
    "232": "Test period reset successfully",
    "233": "test period(s)",
    "234": "Repair completed",
    "235": "There are existing complaints",
    "236": "There is no company with this id",
    "237": "There is no information about this element",
    "238": "This company is already existing",
    "239": "Title",
    "240": "to",
    "241": "Overall acceptance",
    "242": "Total",
    "243": "Unknown",
    "244": "Upload logo",
    "245": "User administration",
    "246": "Username",
    "247": "Username already taken.",
    "248": "Users",
    "249": "U value",
    "250": "U-value (Frame)",
    "251": "U-value (Glass)",
    "252": "W.ID assigned",
    "253": "Warning not to damage Window.ID",
    "254": "Welcome",
    "255": "West",
    "256": "Width",
    "257": "Overall element size (mm)",
    "258": "Window area from",
    "259": "Window area in",
    "260": "Window Electronic material number",
    "261": "Window Electronic serial number",
    "262": "Window installation location",
    "263": "Window system",
    "264": "Window type",
    "265": "Replace sash ",
    "266": "Are you sure?",
    "267": "Clear filter",
    "268": "Edit",
    "269": "Export CSV",
    "270": "Barcode reader",
    "271": "Glass ID",
    "272": "Information",
    "273": "Link",
    "274": "Linked Users",
    "275": "Email",
    "276": "Email settings",
    "277": "Email - Test period",
    "278": "Service title",
    "279": "Service company",
    "280": "Service phone",
    "281": "Service email",
    "282": "Status",
    "283": "Station settings",
    "284": "Subject",
    "285": "Tel.",
    "286": "User",
    "287": "Website",
    "288": "Window.ID",
    "289": "Window System",
    "290": "End user details",
    "291": "Name",
    "292": "Glass structure",
    "293": "Hardware details",
    "294": "Installer title",
    "295": "Installer company",
    "296": "Installer phone",
    "297": "Installer email",
    "298": "URL",
    "299": "Country",
    "300": "Please add user for this company",
    "301": "Additional menu",
    "302": "Dashboard",
    "303": "App",
    "304": "Landingpage Design",
    "305": "User management",
    "306": "Confirm",
    "307": "Body",
    "308": "Attachment",
    "309": "Id",
    "310": "Position",
    "311": "Warning",
    "312": "W.ID",
    "314": "Dealers",
    "315": "Activate",
    "316": "Deactivate",
    "317": "company",
    "318": "Sorry",
    "319": "Administrator",
    "320": "user",
    "321": "Postcode",
    "322": "Logo",
    "323": "Wallpaper",
    "324": "Account ID",
    "325": "UKCA Documents",
    "326": "Application settings",
    "327": "Automatic",
    "328": "Manual",
    "329": "Browse",
    "330": "Upload",
    "331": "Documents auto upload",
    "332": "Source folder",
    "333": "Please choose folder path for auto uploading documents.",
    "334": "Please choose .zip file containing PPS XML file",
    "335": "Station",
    "336": "Manual scan",
    "337": "Smart Glass Station",
    "338": "Information transfer",
    "339": "Max number of ribbons in timeline",
    "340": "QR-Code from",
    "341": "QR-Code to",
    "342": "QR-Code prefix",
    "343": "QR-Code length",
    "344": "Barcode data is invalid.",
    "345": "Api key",
    "346": "enter qrcode",
    "347": "Station name",
    "348": "File not selected",
    "349": "File uploaded",
    "350": "File upload error",
    "351": "Manual scan is enabled",
    "352": "Please enter qrcode",
    "353": "Barcode is invalid",
    "354": "Barcode imported",
    "356": "No data",
    "357": "Connection failed",
    "358": "History",
    "359": "Barcode is in use.",
    "360": "Error while matching barcode",
    "361": "Manual",
    "362": "From",
    "363": "To",
    "364": "QR-Code Prefix length must be lower than QR-Code length",
    "365": "Barcode range is invalid",
    "366": "Invalid numbers",
    "367": "Used previously",
    "368": "Used today",
    "369": "Free",
    "370": "Used until today",
    "371": "QR-Code number usage for current station range",
    "372": "Translations",
    "373": "Edit translation",
    "374": "Key",
    "375": "English",
    "376": "Value",
    "377": "Assign W.ID",
    "378": "Positions of the windows & doors in the project",
    "379": "Repair service / proof of repair",
    "380": "Maintenance & History",
    "381": "Installation report",
    "382": "Modification",
    "383": "Replace glass",
    "384": "Replace sash",
    "386": "Comments",
    "387": "Repair complete",
    "388": "New features",
    "389": "Maintenance log",
    "390": "Hardware maintenance",
    "391": "Arrange a maintenance appointment",
    "392": "Full project list",
    "393": "Window info",
    "394": "Installation details",
    "395": "Colour",
    "396": "Element width x height in mm",
    "397": "Glass width x height in mm",
    "398": "Fitting info",
    "399": "Name of installer",
    "400": "Date / time of installation",
    "401": "Installation site (GPS)",
    "402": "Device ID",
    "403": "send",
    "404": "Filter position",
    "405": "None",
    "406": "Balcony doors",
    "407": "Kitchen",
    "408": "Bathroom",
    "409": "Room",
    "410": "Roof",
    "411": "Installation",
    "412": "Position",
    "413": "Attach picture",
    "414": "Signature",
    "415": "Drawing",
    "416": "No damage to Window",
    "417": "No damage to the glass",
    "418": "Open camera",
    "419": "Upload",
    "420": "Install & assign window",
    "421": "Acceptance report",
    "422": "Send",
    "423": "Entire construction project",
    "424": "Mounting type",
    "425": "Explicit for element",
    "426": "Entire job completed",
    "427": "Installation report for individual elements",
    "428": "Problem exists",
    "429": "Request help",
    "430": "Message",
    "431": "Send",
    "432": "Next Element",
    "433": "Full element list",
    "434": "Construction schedule",
    "435": "via order number",
    "436": "via QR code scanner",
    "437": "individual display",
    "438": "or",
    "439": "Barcode number",
    "440": "Show information",
    "441": "Repair/complaint",
    "442": "Show contact person",
    "443": "Feedback",
    "444": "Scan another QR code",
    "445": "Next maintenance due",
    "446": "Window supplier",
    "447": "Properties",
    "448": "Sound insulation value",
    "449": "Uf",
    "450": "Retrofit solutions",
    "451": "Contact",
    "452": "Your window ID",
    "453": "What would you like to do?",
    "454": "Regular maintenance of the windows/doors extends the service life and comfort",
    "455": "Request maintenance appointment through contact form",
    "456": "Request maintenance appointment by phone",
    "457": "to your window installer",
    "458": "I hereby confirm the terms and conditions and the GDPR regulation",
    "459": "Your concern",
    "461": "Contact via ...",
    "462": "Broken glass",
    "463": "Window does not close properly",
    "464": "Window is stuck",
    "465": "Window damaged",
    "466": "Other Accessories",
    "467": "Your windows and doors were",
    "468": "assembled by this company",
    "469": "Window fabricator",
    "470": "Rate your windows and installation now",
    "471": "How satisfied are you with the company's service",
    "472": "Are the delivered products OK ?",
    "473": "How do you like your windows ?",
    "474": "Were there any problems with processing?",
    "475": "What were you happy with?",
    "476": "What would you improve ?",
    "477": "Assembly instructions",
    "478": "Positions of the windows & doors",
    "479": "Open the installation report",
    "480": "Complete project element list",
    "481": "Element list via order number / QR",
    "482": "Send project log",
    "483": "Window details",
    "484": "Details",
    "485": "Construction project number",
    "486": "Window",
    "487": "Window position",
    "488": "Repair service",
    "489": "Maintenance details",
    "490": "Customer",
    "491": "Contact form",
    "492": "Find a REHAU Installer near you ",
    "493": "Category",
    "494": "QR Scan",
    "495": "Contact person",
    "496": "update",
    "497": "filters",
    "498": "Installation is completed",
    "499": "Installation completed?",
    "500": "Delivered without damage",
    "501": "Save as PDF",
    "502": "Take photo",
    "503": "Not a valid email address",
    "504": "Install App",
    "505": "Place",
    "506": "Project list",
    "507": "Load selected order ?",
    "508": "Incorrect order number/barcode",
    "509": "Single window",
    "510": "Enter changes",
    "511": "Send report via email",
    "512": "Please choose 4 options",
    "513": "Now scan the QR code to find out where the element should be installed.",
    "514": "Save file",
    "515": "Show",
    "516": "results",
    "517": "page",
    "518": "of",
    "519": "Date from",
    "520": "Date to",
    "521": "open",
    "522": "Size",
    "523": "Installed on",
    "524": "Success",
    "525": "Info",
    "526": "New order loaded",
    "527": "Installer ID",
    "528": "Login",
    "529": "I hereby confirm the general terms and conditions and the GDPR regulation",
    "530": "Logout",
    "531": "scanner",
    "532": "Send the report",
    "533": "Should the acceptance report for all items of this order be sent to the dashboard?",
    "534": "Repair log",
    "535": "option 5",
    "536": "option 6",
    "537": "option 7",
    "538": "option 8",
    "539": "option 9",
    "540": "option 10",
    "541": "Email sent.",
    "542": "Error while sending email!",
    "543": "Hardware maintenance",
    "544": "Adjusted",
    "545": "Cleaned",
    "546": "Sealed",
    "547": "Hardware replaced",
    "548": "Comment",
    "549": "All elements are delivered to the construction site",
    "550": "Location of the elements within the construction project",
    "551": "Error while sending report!",
    "552": "Save report",
    "553": "Info saved.",
    "554": "Failed to save data.",
    "555": "Smart window information",
    "556": "Smart window acceptance",
    "557": "Window electronics material number",
    "558": "Window electronics serial number",
    "559": "Scan the QR code (DMC) from the master controller or enter it manually",
    "560": "Smart Window fully configured and functional",
    "561": "All visible elements without damage / defects",
    "562": "Has a switching system been connected to 0-10V (0-100%)?",
    "563": "Installation location",
    "564": "Start scan",
    "565": "Stop scan",
    "566": "Selected Element",
    "567": "Max 4 attributes can be selected at the same time.",
    "568": "Customer Position",
    "569": "Production date",
    "570": "Change Window.ID",
    "571": "Previous Window.ID",
    "572": "New Window.ID",
    "573": "Order Number doesn't exist, or new order number is already in use.",
    "574": "Window.ID",
    "575": "Position ID",
    "576": "Type",
    "577": "Element ID",
    "578": "Element information",
    "579": "Window.ID",
    "580": "Please sign in the space provided",
    "581": "Finished",
    "582": "Sign",
    "583": "New language",
    "584": "Original language",
    "585": "Language name",
    "586": "Create new language",
    "587": "Add language",
    "588": "Update",
    "589": "update in progress",
    "590": "Company deactivation",
    "591": "Company activation",
    "592": "Are you sure you want to deactivate this company?",
    "593": "Are you sure you want to activate this company?",
    "594": "User deactivation",
    "595": "User activation",
    "596": "Are you sure you want to deactivate this user?",
    "597": "Are you sure you want to activate this user?",
    "598": "ID Position",
    "599": "All",
    "600": "Import/Export",
    "601": "Please choose .csv language file for import",
    "602": "Import",
    "603": "Export",
    "604": "File",
    "605": "Translator",
    "606": "Barcode files",
    "607": "Please choose folder path for barcode files.",
    "608": "Window_ID",
    "609": "Profile system",
    "610": "Customer position",
    "611": "Color",
    "612": "Element size",
    "613": "Glass size",
    "614": "Glass structure",
    "615": "Hardware info",
    "616": "Matching Date",
    "617": "Installation Date/Time",
    "618": "GPS data installation location",
    "619": "Nr:",
    "620": "Installation:",
    "621": "Floor:",
    "622": "Pos:",
    "623": "Ignore starting character(s)",
    "624": "Ignore ending character(s)",
    "625": "Email - User activation",
    "626": "Activate companies",
    "627": "Trial expired",
    "628": "Resend activation email",
    "629": "Dealer/Factory already exists",
    "630": "User is activated",
    "631": "Go to login page",
    "632": "User details",
    "633": "Add linked user",
    "634": "Delete user link",
    "635": "User is already linked",
    "636": "Username or password is empty",
    "637": "User not found",
    "638": "Send protocol",
    "639": "Assembly completed question",
    "640": "Option 5",
    "641": "Option 6",
    "642": "Option 7",
    "643": "Option 8",
    "644": "Option 9",
    "645": "Option 10",
    "646": "Assembler",
    "647": "Type",
    "648": "Date",
    "649": "Description",
    "650": "Hardware maintenance",
    "651": "Set",
    "652": "Clean",
    "653": "Seal",
    "654": "Replacement accessories",
    "655": "Comment",
    "656": "Connector",
    "657": "Window system",
    "658": "Piece",
    "659": "Project number",
    "660": "Order ID",
    "661": "Height",
    "662": "Width",
    "663": "Hardware information",
    "664": "Glass width x height",
    "665": "Glass structure",
    "666": "Window.ID",
    "667": "Window Electric ID",
    "668": "Glass ID",
    "669": "Conditions",
    "670": "Add/Remove",
    "671": "And/Or",
    "672": "Group",
    "673": "Column",
    "674": "Condition",
    "675": "Enter Customer number",
    "676": "Translator",
    "678": "Application version control",
    "679": "Update could take a few minutes to finish",
    "680": "Awaiting new version",
    "681": "There is new application version, are you sure you want to update?",
    "682": "Application is up to date, do you want to restore old version?",
    "683": "Restore old version",
    "684": "Insert station name",
    "685": "Order number char filler",
    "686": "Please insert order number char filler",
    "687": "Order number max length",
    "688": "Please insert order number max length",
    "689": "MDB folder path",
    "690": "Please insert .mdb file path (used by importer when scanning for .mdb files)",
    "691": "XML folder path",
    "692": "Please insert .xml file path (used by importer when scanning for .xml files)",
    "693": "Ignore starting QR-Code character(s)",
    "694": "Ignore ending QR-Code character(s)",
    "695": "Designation",
    "696": "Length",
    "697": "Additional position",
    "698": "Smart window without damage",
    "699": "Smart window connected",
    "700": "Window orientation",
    "701": "Position element number",
    "702": "Smart window configured",
    "703": "Element without damage",
    "704": "Material number",
    "705": "Serial number",
    "706": "No image available",
    "707": "Construction site clean",
    "708": "Drag and drop image/pdf files here",
    "709": "File upload",
    "710": "File type",
    "711": "Order (all positions)",
    "712": "Deleted",
    "713": "Release notes",
    "714": "Height (mm)",
    "715": "Width (mm)",
    "716": "There is no panel for this position",
    "717": "Item",
    "718": "Bulk station",
    "719": "Bulk range",
    "720": "Window.ID (last)",
    "721": "Glass was replaced",
    "722": "Accessories were repaired",
    "723": "Sash was replaced",
    "724": "Surface was repaired",
    "725": "Accessories were repaired",
    "726": "Repair is completed",
    "727": "Confirm password",
    "728": "Wrong password",
    "729": "Passwords do not match",
    "730": "Password can not be empty",
    "731": "Location",
    "732": "Timeline",
    "733": "U-value (W/m²K)",
    "734": "Special glass",
    "735": "No position",
    "736": "Production progress",
    "737": "Production completed",
    "738": "Installation progress",
    "739": "Installation completed",
    "740": "Station deleted",
    "741": "Do you really want to delete this station?",
    "742": "Add a new station",
    "743": "Delete order",
    "744": "Order deletion",
    "745": "Are you sure you want to delete order?",
    "746": "User added",
    "747": "User edited",
    "748": "Forgot password?",
    "749": "The Password field is required",
    "750": "The User name field is required",
    "751": "This user role is not authorized for dashboard login",
    "752": "User is not active",
    "753": "User company is not active or test period is over",
    "754": "Username or password is incorrect",
    "755": "Postcode",
    "756": "Use scroll wheel to zoom picture in and out",
    "757": "Reset password",
    "758": "Email for password reset has been sent",
    "759": "Password has been reset",
    "760": "Email - Reset password",
    "761": "Element Nr",
    "762": "Order number from",
    "763": "Please insert order number from",
    "764": "Order number length",
    "765": "Please insert order number length",
    "766": "Order range from",
    "767": "Please insert order range from",
    "768": "Order range length",
    "769": "Please insert order range length",
    "770": "Please insert order piece from",
    "772": "Order piece length",
    "773": "Please insert order piece length",
    "774": "Position number from",
    "775": "Please insert position number from",
    "776": "Position number length",
    "777": "Please insert position number length",
    "778": "Position piece from",
    "779": "Please insert position piece from",
    "780": "Position piece length",
    "781": "Please insert position piece length",
    "782": "Security level",
    "783": "Barcode filler character",
    "784": "Please insert barcode filler character",
    "785": "Back to projects",
    "786": "(read only)",
    "787": "limited access",
    "788": "read only access",
    "789": "own landingpage",
    "790": "Please insert connection string",
    "791": "DB Connection string",
    "792": "DB STD Connection string",
    "793": "DB  BWG Connection string",
    "794": "Database connection",
    "795": "Chat",
    "796": "Messages",
    "797": "CE Document",
    "798": "Order Document",
    "799": "Last repair",
    "800": "Acceptance completed",
    "801": "New update available, please restart application",
    "802": "External link",
    "803": "External link prefix",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search Window.ID",
    "853": "search Window.ID",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image"
};

export default en;

