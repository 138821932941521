//React
import React, { useState, createContext, useContext } from 'react'

//Language
import { languageOptions, dictionaryList } from '../languages/index'

const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
const savedLanguages = JSON.parse(localStorage.getItem("langs"));

//Create the language context with default selected language
export const LanguageContext = createContext({
  language: selectedLang || languageOptions[0],
  dictionary: dictionaryList[selectedLang ? selectedLang.id : languageOptions[0].id],
  langs: savedLanguages != null ? savedLanguages[selectedLang ? selectedLang.id : languageOptions[0].id] : null,
});

//It provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext)
  const [language, setLanguage] = useState(languageContext.language)
  const [dictionary, setDictionary] = useState(languageContext.langs !== null ? languageContext.langs : languageContext.dictionary)
  const [langs, setLangs] = useState(languageContext.langs)

  const provider = {
    language,
    dictionary,
    langs,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage)
      setDictionary(dictionaryList[selectedLanguage.id])
    }
  }

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  )
}

//Get text according to id & current language
export function Text(props) {
  const languageContext = useContext(LanguageContext)
  if(languageContext.dictionary == undefined)//if language is undefined, after adding dynamic translations
  {
    if(languageContext.langs !== null){
      if(languageContext.langs[props.tid] != undefined){
        return "" + languageContext.langs[props.tid];
      }else{
        return props.tid;
      }
    }else{
      return props.tid;
    }
    
  } 
  else if(languageContext.dictionary[props.tid] != undefined)//if translation is present, return translation
  {
    return languageContext.dictionary[props.tid]
  }
  else//if translation is not present, return key
  {
    return props.tid;
  }
}

//Get translation according to id & current language
export function GetTranslation(tid) {
  const languageContext = useContext(LanguageContext)
  if(languageContext.dictionary == undefined)//if language is undefined, after adding dynamic translations
  {
    if(languageContext.langs !== null){
      if(languageContext.langs[tid] != undefined){
        return "" + languageContext.langs[tid];
      }else{
        return tid;
      }
    }else{
      return tid;
    }
    
  } 
  else if(languageContext.dictionary[tid] != undefined)//if translation is present, return translation
  {
    return languageContext.dictionary[tid]
  }
  else//if translation is not present, return key
  {
    return tid;
  }
}
