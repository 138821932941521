import { INITIALISE_STATE, SW_INIT, SW_UPDATE, INSTALL_APP, SHOW_INSTALL_PROPMPT, SAVE_SIGNATURE_IMAGE, SW_UPDATE_FINISHED, SET_LAST_USED_CAMERA_INDEX } from '../actions/types';

const initialState = {
    updateAvailable: true,
    installApp: false,
    alreadyInstalled: false,
    installPromptShowedTimes: 0,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    version: "0.1.4.5",
    signImage:"",
    lastUsedCameraIndex: 0,
    //currentAuftrag: null
}

export default function app (state = initialState, action) {
    switch (action.type) {
        case INITIALISE_STATE:
            return state;
        case SW_INIT:
            return {
                ...state,
                serviceWorkerInitialized: !state.serviceWorkerInitialized,
            };
        case SW_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: !state.serviceWorkerUpdated,
                serviceWorkerRegistration: action.payload,
            };
        case INSTALL_APP:
            return {
                ...state,
                alreadyInstalled: action.payload
            }
        case SHOW_INSTALL_PROPMPT:
            return {
                ...state,
                installApp: action.payload
            }
        case SAVE_SIGNATURE_IMAGE:
                return{
                ...state,
                signImage: action.payload
                }
        case SW_UPDATE_FINISHED:
            return {
                ...state,
                serviceWorkerUpdated: false
            }
        case SET_LAST_USED_CAMERA_INDEX:
            return {
                ...state,
                lastUsedCameraIndex: action.payload
            }
        default:
            return state;
    }
}