import Dexie from "dexie";
import { Text } from "../components/extensions/LanguageContainer";
import Notifications from "../components/Reusable/Notifications";
import React from 'react';
import { getUrl } from './api';
import {updateDictList} from './../components/languages/index';
import { getMimeType} from './../components/extensions/PrepareBase64';


export default class DbTasks {
  
  constructor() {
    this.db = new Dexie("windowid");

    this.db.version(1).stores({ auftrag: '++id' });

    this.db.version(2).stores({ auftrag: '++id', fotos: '++id, positionID' });

    this.db.version(3).stores({ auftrag: '++id', fotos: '++id, positionID', positionInfo: '++id' });

    this.db.version(4).stores({ auftrag: '++id', fotos: '++id, positionID', positionInfo: '++id', auftragPositionInfo: 'positionID' });

    this.db.version(5).stores({ auftrag: '++id', fotos: '++id, positionID', positionInfo: '++id', auftragPositionInfo: 'positionID', elementInfo:"++id" });
  }

  async getAllData() {
    let auftrag = await this.db.table("auftrag").toArray();
    let foto = await this.db.table("fotos").toArray();
    let posInfo = await this.db.table("positionInfo").toArray();
    let abnahmePositionInfo = await this.db.table("auftragPositionInfo").toArray();
    let eleInfo = await this.db.table("elementInfo").toArray();

    return {
      currentAuftrag: auftrag[0] || {},
      fotos: foto,
      currentlySelectedPositionId: posInfo[0] ? posInfo[0].currentlySelectedId : null,
      abnahmePositionInfo: abnahmePositionInfo,
      currentlySelectedElementId: eleInfo[0] ? eleInfo[0].currentlySelectedElementId : null,
    }
  }

  getRequestOption = (method, data, useContentType) => {
   

    let jwtToken = this.getJwtToken();
    const requestOption = {
      "crossDomain": true,
      "method": method,
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwtToken
      },
      "credintials": "include",
      // "body": JSON.stringify({ ...data })
    }
    if (useContentType) requestOption.headers["content-type"] = "application/json; charset=utf-8";
    if (method === "POST") requestOption.body = JSON.stringify({ ...data });
    return requestOption;
  };



  login = async (username, password, settings) => {
    let apiUrl = getUrl();
    //novi http://s784550697.onlinehome.us/Token
    return fetch(apiUrl +'/User/authenticate', this.getRequestOption("POST", { username: username, password: password, origin: "Mobile" }, true))
      //return fetch('https://localhost:44383/User/authenticate', requestOptions)
      .then(this.handleResponse)
      .then(async user => {
        if(user.message == "User is already logged in") {
          alert (user.message);
          return;
        }
        if(user.message == "This user role is not authorized for mobile login"){
          alert (user.message);
          return;
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user.token);
        localStorage.setItem('refreshToken', user.refreshToken);
        let parsedToken = await this.parseJwt(user.token);
        //console.log("PARSEDTOKEN:", parsedToken);

        let ret = {
          UserId: parsedToken.nameid,
          //Username: parsedToken.unique_name,
          // Password: password,
          // Token: user.access_token,
          // LanguageId: parsedToken.LanguageId,
          // TokenValidTime: moment(new Date()).add(10, 'm').format('YYYY-MM-DD HH:mm:ss'),
          // KeepLoggedIn: true
        }
        localStorage.setItem('user', JSON.stringify(ret));
        return user;
      })
      .catch(function (err) {
        let retVal = { error: "SERVICE_FAILED" }
        return retVal;
      });
  }

  logout = (userId) => {
    let apiUrl = getUrl();
    console.log('UserLogout: ' + userId);
    fetch(apiUrl + '/User/Logout', this.getRequestOption("POST", {id: parseInt(userId), value: "" }, true))
      .then(res => {
        //console.log(res);
        if (res.status === 401) {
          // auto logout if 401 response returned from api
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          window.location.href = '/';
          return true;
        }
        else if(res.ok){
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          window.location.href = '/';
          return true;
        }
        return false;
      })
    
  }

  getJwtToken = () => {
    return localStorage.getItem('token');
  }

  getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
  }

  saveJwtToken(token) {
    localStorage.setItem('token', token);
  }

  saveRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  refresh = async (jwtToken, refreshToken) => {
    let apiUrl = getUrl();
    return fetch(apiUrl + '/User/Refresh', {
      method: 'POST',
      crossDomain: true,
      //body: `token=${encodeURIComponent(jwtToken)}&refreshToken=${encodeURIComponent(this.getRefreshToken())}`,
      body: JSON.stringify({ token: jwtToken, refreshToken: this.getRefreshToken() }),
      mode: "cors",
      headers: {
        "content-type": "application/json; charset=utf-8",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
      },
      "credintials": "include",
    });
  }

  parseJwt = async (token) => {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  handleResponse = async response => {
   

    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          let user = JSON.parse(localStorage.getItem('user'));;
          this.logout(user.UserId);
          //location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }

  tryToGetNewToken = async (response, getDataAgain) => {
    let jwtToken = this.getJwtToken();
    if (response.status === 401 && response.headers.has('Token-Expired')) {

      //flag = true;

      var refreshToken = this.getRefreshToken();

      var refreshResponse = await this.refresh(jwtToken, refreshToken);
      //console.log(refreshResponse);
      if (!refreshResponse.ok) {
        return response; //failed to refresh so return original 401 response
      }
      var jsonRefreshResponse = await refreshResponse.json(); //read the json with the new tokens

      this.saveJwtToken(jsonRefreshResponse.token);
      this.saveRefreshToken(jsonRefreshResponse.refreshToken);
      return await getDataAgain(); //repeat the original request
    }
    // else if (response.status === 401 && flag === false) {
    //   //console.log("Flag :");
    //   localStorage.removeItem("token");
    //   window.location.reload();
    // }
    else { //status is not 401 and/or there's no Token-Expired header
      localStorage.removeItem("token");
      window.location.reload();
      //return response; //return the original 401 response
    }
  }

  getDataFromDb = async (url, data, settings) => {
    let apiUrl = getUrl();
    let response = await fetch(`${apiUrl}${url}`, this.getRequestOption("POST", data, true))
    if (response.ok) {
      let res = await response.json();
      //nije potrebno odraditi update lokalne baze za svaki request. Ukoliko postoji property skipUpdate, preskoči update, vrati rezultat request-a.
      //if (!data.skipDbUpdate) await this.updateLocalDb(res);
      return res;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    }
    else {
      return this.tryToGetNewToken(response, () => this.getDataFromDb(url, data));
    }
  }

  updatePosition = async (url, data, settings) => {
    let apiUrl = getUrl();
    let response = await fetch(`${apiUrl}${url}`, this.getRequestOption("POST", data, true))
    if (response.ok) {
      let res = await response.json();
      return res;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    }
    else {
      return this.tryToGetNewToken(response, () => this.updatePosition(url, data));
    }
  }

  async updatePositionDb(data) {
    this.db.table("positionInfo").clear()
      .then(() => this.db.table("positionInfo").bulkPut([data]));
    return data;
  }

  async updatePositionElementDb(data) {
    this.db.table("elementInfo").clear()
      .then(() => this.db.table("elementInfo").bulkPut([data]));
    return data;
  }

  async updateLocalDb(data) {
    await this.db.table("auftragPositionInfo").clear().then(() => console.log("auftrag position info cleared"));
    await this.db.table("auftrag").clear().then(e => console.log("Data deleted !", e)).then(async() =>
    await this.db.table("auftrag").put(data).then(() => console.log("Auftrag data added to the table")));
    // await this.db.table("auftrag").put(data).then(() => console.log("Data added to the table"));

    this.db.transaction('rw', this.db.auftrag, async ()=>{
      //await this.db.table("auftrag").put(data).then(() => console.log("Auftrag data added to the table"));
      //
      // Transaction Scope
      //

  }).then(() => {

      //
      // Transaction Complete
      //

      console.log("Transaction committed");

  }).catch(err => {

      //
      // Transaction Failed
      //

      console.error(err.stack);
  });
    //await this.db.table("auftrag").last((e) => console.log("Exist check res : ", e));
    // .then(() => console.log("Auftrag data deleted."))
    // .then(() => {
    //   this.db.table("auftrag").put(data).then(() => console.log("Data added to the table"))
    // })
  }

  async FotoAdd(app, data) {
    let id = await this.db.table("fotos").put({
      positionID: app,
      data: data
    });
    return { id, data }

  }

  async SavePositionAbnahmeInfo(data, positionElementInfo) {
    let user = JSON.parse(localStorage.getItem('user'));
    let apiUrl = getUrl();
    let params = {
      deliverySuccess: positionElementInfo.deliverySuccess || false,
      elementLocation: positionElementInfo.elementLocation || false,
      windowDamage: positionElementInfo.windowDamage || false,
      glassDamage: positionElementInfo.glassDamage || false,
      assemblyCompleted: positionElementInfo.assemblyCompleted || false,
      constructionSiteClean: positionElementInfo.constructionSiteClean || false,
      deliveryWithoutDamage: positionElementInfo.deliveryWithoutDamage || false,
      comment: positionElementInfo.comment || "",
      positionId: positionElementInfo.positionID,
      positionElementId: positionElementInfo.positionElementId,

      smartWindowConfigured: positionElementInfo.smartWindowKonfiguriert,
      smartWindowWithoutDamage: positionElementInfo.smartWindowWithoutDamage,
      smartWindowConnected: positionElementInfo.smartWindowConnected,

      smartWindowInstallationLocation: positionElementInfo.installationLocation, // installation location
      smartWindowOrientation: positionElementInfo.windowOrientation ? parseInt(positionElementInfo.windowOrientation) : null,
      smartWindowSerieNumber: positionElementInfo.seriennummer,
      smartWindowMaterialNumber: positionElementInfo.materialnummer,
      modifiedBy:parseInt(user.UserId)
    };


    let response = await fetch(`${apiUrl}${data.url}`, this.getRequestOption("POST", params, true))
    if (response.ok) {
      let res = await response.json();
      if (res) {
        let retVal = this.db.table("auftragPositionInfo").put(data, data.positionID)
          .then(() => this.db.table("auftragPositionInfo").toArray());
          // Notifications("success", <Text tid="553" />)
        return retVal;
      }
      else if(response.error === 'User permissions have changed.'){
        this.logout();
      }
      else
      {
        Notifications("warning", <Text tid="554" />);
      }
    }
    else {
      return this.tryToGetNewToken(response, () => this.SavePositionAbnahmeInfo(data, positionElementInfo));
    }
  }

  async getAllPhotos() {
    let ret = [];

    ret = await this.db.table("fotos").toArray();
    return ret;
  }

  async FotoRemove(id) {
    let allFotos = await this.getAllPhotos();
    let _foto = await allFotos.find(f => f.data.item1 === id);
    //console.log("FOTO FOR DELETE:", _foto);
    await this.db.table("fotos").delete(_foto.id);
    return _foto.id;
  }

  _base64ToArrayBuffer(base64) {
    let _b64 = base64;
    // if (_b64.startsWith("data:application/pdf;base64,"))
    //   _b64 = _b64.substring(28);
    // if (_b64.startsWith("data:image/png;base64,"))
    //   _b64 = _b64.substring(22);
    let index = _b64.indexOf(";base64,");
    if(index > 0)
      _b64 = _b64.substring(index + 8);
    //console.log("base64:", _b64);
    let raw = window.atob(_b64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Blob([array]);
  }

  async sendEmail(data) {
    let apiUrl = getUrl();
    let formData = new FormData();

    let base64 = this._base64ToArrayBuffer(data.Attachments);

    formData.append("Attachments", base64, 'Rehau_report.pdf');
    formData.append("EmailTo", data.email);
    formData.append("EmailSubject", data.subject);
    formData.append("EmailBody", data.message);
    formData.append("PositionId", data.positionId);
    formData.append("EmailType", 0);

    let jwtToken = this.getJwtToken();
    const requestOptions = {
      "crossDomain": true,
      "method": "POST",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwtToken
      },
      "credintials": "include",
      "body": formData
    };
    //+ "&grant_type=password"
    let response = await fetch(apiUrl +`/Mail/send`, requestOptions);
    if (response.ok) {
      //ako je email uspješno poslan, izbriši slike koje su korištene za kreiranje pdf dokumenta
      //await this.db.table("fotos").clear(); //davor zakomentarisano, jer se slike brisu na drugom mjestu 
      return true;
    } else if(response.error === 'User permissions have changed.'){
      this.logout();
    } else return false;

  }

  async sendAbnahmeProtokoll(data) {
    let apiUrl = getUrl();
    let formData = new FormData();

    let base64 = this._base64ToArrayBuffer(data.Attachments);

    formData.append("Attachments", base64, 'AbnahmeGesamt.pdf');
    // formData.append("ToEmail", data.email);
    formData.append("Subject", data.Subject);
    formData.append("AuftragId", data.AuftragId);
    // formData.append("PositionId", data.positionId);

    let jwtToken = this.getJwtToken();
    const requestOptions = {
      "crossDomain": true,
      "method": "POST",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwtToken
      },
      "credintials": "include",
      "body": formData
    };
    //+ "&grant_type=password"
    let response = await fetch(apiUrl +`/api/monteur/sendProtokoll`, requestOptions);
    if (response.ok) {
      //ako je email uspješno poslan, izbriši slike koje su korištene za kreiranje pdf dokumenta
      await this.db.table("fotos").clear();
      return true;
    } else if(response.error === 'User permissions have changed.'){
      this.logout();
    } else return false;
    // return true;

  }

  async getDropdownValues(searchedParam, urlParam) {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl +`/produzent/${urlParam}?param=${searchedParam}`, this.getRequestOption("GET", null, false));
    if (response.ok) {
      let retVal = response.json();

      return retVal;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    } 
    else {
      return this.tryToGetNewToken(response, () => this.getDropdownValues(searchedParam, urlParam));
    }
  }

  getProjektliste = async (data) => {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl +`/endkunde/projektliste`, this.getRequestOption("POST", data, true));
    if (response.ok) {
      return response;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    } 
    else {
      return this.tryToGetNewToken(response, () => this.getProjektliste(data));
    }
  }

  async getPositionDocument(positionId) {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl + `/endkunde/PosDocument?id=${positionId}`, this.getRequestOption("GET", null, false));
    if (response.ok) {
      //let retVal = response.json();

      return response;
    } else if (response.status === 404)  {
      return null; //Fajl ne postoji.
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    }
    else {
      return this.tryToGetNewToken(response, () => this.getPositionDocument(positionId));
    }
  }

  getMontageHistory = async (data) => {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl + `/endkunde/MontageHistory`, this.getRequestOption("POST", data, true));
    if (response.ok) {
      return response;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    }
    else {
      return this.tryToGetNewToken(response, () => this.getMontageHistory(data));
    }
  }

  getMaintenanceHistory = async (data) => {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl + `/produzent/getMaintenanceHistory`, this.getRequestOption("POST", data, true));
    if (response.ok) {
      return response;
    } 
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    } 
    else {
      return this.tryToGetNewToken(response, () => this.getMaintenanceHistory(data));
    }
  }

  SaveImageToDatabase = async (app, data, ext) => {
    let apiUrl = getUrl();

    let formData = new FormData();
    let mimetype = getMimeType(data);
    let base64 = this._base64ToArrayBuffer(data);
    
    formData.append("File", base64, mimetype);
    formData.append("Name", app);
    formData.append("Extension", ext)

    let jwtToken = this.getJwtToken();
    const requestOptions = {
      "crossDomain": true,
      "method": "POST",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwtToken
      },
      "credintials": "include",
      "body": formData
    };
    //+ "&grant_type=password"
    let response = await fetch(apiUrl +`/api/monteur/sendPositionImage`, requestOptions);
    if (response.ok) {
      return response.text();
    } else if(response.error === 'User permissions have changed.'){
      this.logout();
    } else return false;

  }

  updateTranslations = async()=>{
    let apiUrl = getUrl();
    let allTranslations = {};
    await fetch(apiUrl + '/Data/GetAllTranslationsMobile')
          .then(response => response.json())
          .then(data => {
            let languages = [];
            for(let i = 0; i<data.data.length;i++)
               {
                if(data.data[i].te !== "EN-US")
                  languages.push(data.data[i].te.toLowerCase());
               }
               let uLang = [...new Set(languages)];
              //console.log("LANGUAGES:", uLang);

              uLang.map(item => {
                let jscode = `allTranslations['${item}'] = {}`;
                eval(jscode);
              })

              return data;
          })
          .then(data => {
               
              for(let i = 0; i<data.data.length;i++)
              {
                
                //if(data.data[i].lu.substring(0,2) != "lv" && data.data[i].lu.substring(0,2) != "it" && data.data[i].lu.substring(0,2) != "uk" && data.data[i].lu.substring(0,2) != "bs")
                if(data.data[i].te !== "EN-US"){
                  let key = parseInt(data.data[i].t.key); 
                  let test1 = data.data[i].te.toLowerCase();
                  allTranslations[test1][key] = data.data[i].t.value;
                }
                
              }
          });
          // let lang = allTranslations;
          // console.log(lang);  //ovo je za update statickih prevoda // updatetranslation
          localStorage.setItem("langs", JSON.stringify(allTranslations));
          await updateDictList(allTranslations);

  }

  clearFotosTable = async() => {
    await this.db.table("fotos").clear();
    return true;
  }

  DeleteFotoFromApi = async(idToRemove) => {
    let apiUrl = getUrl();
    let res = await fetch(apiUrl + `/api/monteur/deletePositionImage`, this.getRequestOption("POST", {positionId: idToRemove}, true));
    if(res.error === 'User permissions have changed.'){
      this.logout();
    }
    return res.ok;
  }

  SaveDocumentToApi = async(app,data) => {
    let apiUrl = getUrl();

    let formData = new FormData();
    let mimetype = getMimeType(data);
    let base64 = this._base64ToArrayBuffer(data);
    
    formData.append("File", base64, mimetype);
    formData.append("Name", app);

    let jwtToken = this.getJwtToken();
    const requestOptions = {
      "crossDomain": true,
      "method": "POST",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwtToken
      },
      "credintials": "include",
      "body": formData
    };
    //+ "&grant_type=password"
    let response = await fetch(apiUrl +`/api/monteur/sendPositionDocumentSigned`, requestOptions);
    if (response.ok) {
      return true;
    } else if(response.error === 'User permissions have changed.'){
      this.logout();
    } else return false;
  }

  async AddAbnahmeInfoFromAuftrag(data){
    // console.log("data", data);
    let abnahmeList = [];

    let findObjectIndex = (pos) => {
      return abnahmeList.findIndex(x => x.positionID === pos);
    }

    let addElementData = (el, elIndex) => {
      let params = {
        deliverySuccess: el.deliverySuccess || false,
        elementLocation: el.elementLocation || false,
        windowDamage: el.windowDamage || false,
        glassDamage: el.glassDamage || false,
        assemblyCompleted: el.assemblyCompleted || false,
        constructionSiteClean: el.constructionSiteClean || false,
        deliveryWithoutDamage: el.deliveryWithoutDamage || false,
        comment: el.comment || "",
        positionId: el.positionId,
        positionElementId: el.positionElementId,
  
        smartWindowConfigured: el.smartWindowKonfiguriert,
        smartWindowWithoutDamage: el.smartWindowWithoutDamage,
        smartWindowConnected: el.smartWindowConnected,
  
        smartWindowInstallationLocation: el.installationLocation, // installation location
        smartWindowOrientation: el.windowOrientation ? parseInt(el.windowOrientation) : null,
        smartWindowSerieNumber: el.seriennummer,
        smartWindowMaterialNumber: el.materialnummer,
      };

      abnahmeList[elIndex].positionElements.push(params);
    }

    this.db.table("auftragPositionInfo").clear()
      .then(() => {
      
      data.abnahmePositionInfos.forEach(el=> {
        let elIndex = findObjectIndex(el.positionId); 
        if(elIndex > -1)
        {
          addElementData(el, elIndex);
        }
        else if (elIndex === -1){
          let containerParams = {
            positionElements:[],
            positionID: el.positionId,
            url:"/api/Monteur/abnahmePositionInfo"
          }
          abnahmeList.push(containerParams);
          let newElIndex = abnahmeList.length - 1;
          addElementData(el, newElIndex);
        }
         
      });
      //console.log("OBRADJENO:",abnahmeList);
      abnahmeList.forEach(li => {
        this.db.table("auftragPositionInfo").put(li, li.positionID)
      });
    });

    return abnahmeList;
  }

  async GetPositionAbnahmeInfo () {
    return await this.db.table("auftragPositionInfo").toArray();
  }

  userPasswordRecovery = (email) => {
    console.log("EMAIL", email);
    let apiUrl = getUrl();
    return fetch(apiUrl +'/User/ResetPassword', this.getRequestOption("POST", { email:email }, true))
      .then(res => {
        if(res.ok){
          Notifications("success", "E-mail sent.")
        }
      })
    
  }

  getLatestMobileVersion = async() => {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl + '/Data/GetMobileVersion');
    let res = await response.json();
    return res;
  }  

  getRackList = async (data) => {
    let apiUrl = getUrl();
    let response = await fetch(apiUrl +`/endkunde/GetRackList`, this.getRequestOption("POST", data, true));
    if (response.ok) {
      return response;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    } 
    else {
      return this.tryToGetNewToken(response, () => this.getRackList(data));
    }
  }

  completeOrder = async(auftrag) => {
    let apiUrl = getUrl();
    let user = JSON.parse(localStorage.getItem('user'));
    let data = {
      auftragId: auftrag,
      userId: parseInt(user.UserId)
    }
    let response = await fetch(apiUrl + `/api/monteur/completeOrderMobile`, this.getRequestOption("POST", data, true));
    if (response.ok) {
      return response;
    }
    else if(response.error === 'User permissions have changed.'){
      this.logout();
    } 
    else {
      return this.tryToGetNewToken(response, () => this.completeOrder(auftrag));
    }
  }

  completeOrderAbnahmeInfo = async(positions) => {
    console.log(positions);
    let abnahmeList = [];

    let findObjectIndex = (pos) => {
      return abnahmeList.findIndex(x => x.positionID === pos);
    }

    let addElementData = (el, elIndex) => {
      let params = {
        deliverySuccess: true,
        elementLocation: true,
        windowDamage: true,
        glassDamage: true,
        assemblyCompleted: true,
        constructionSiteClean: true,
        deliveryWithoutDamage: true,
        comment: "",
        positionId: el.positionId,
        positionElementId: el.positionElementId,
      }
      abnahmeList[elIndex].positionElements.push(params);
    }

    this.db.table("auftragPositionInfo").clear()
      .then(() => {
        positions.forEach(pos => {
          pos.positionElements.forEach(el => {
            let elIndex = findObjectIndex(el.positionId);
            if(elIndex > -1)
            {
              addElementData(el, elIndex);
            }
            else if (elIndex === -1)
            {
              let containerParams = {
                positionElements:[],
                positionID: el.positionId,
                url:"/api/Monteur/abnahmePositionInfo"
              }
              abnahmeList.push(containerParams);
              let newElIndex = abnahmeList.length - 1;
              addElementData(el, newElIndex);
            }
          })
          
        })
      }).then(() => {
        abnahmeList.forEach(li => {
          this.db.table("auftragPositionInfo").put(li, li.positionID);
        })
      });
    
      return abnahmeList;
  }

}
